import React, { useEffect, useState } from 'react';
import api from '../../../lib/api';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Plus } from 'lucide-react';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { useToast } from '../../../components/ui/use-toast';

export default function ManageOrg() {
  const [users, setUsers] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const { toast } = useToast();

  const init_user = {
    username: '',
    email: '',
    password: '',
  };

  const [user, setUser] = useState(init_user);


  useEffect(() => {
    
    fetchData();
  }, []);

  const fetchData=()=>{
    api.get(`/superadmin/getAllOrganizations`).then((res) => {
      setUsers(res);
    });
  }

  const [organization, setOrganization] = useState({
    name: '',
    subcategory: '',
    vat_num: '',
    reg_num: '',
    brand: '',
    status: 'unactive', // Default to 'unactive'
    category: '', // Default to ''
    termscondition: false, // Default to false
    phonenumber: '', // Default to ''
    address: '', // Default to ''
    email: '',
    password:'' ,  // Default to '' 
    username:''
  });

  const [open, setOpen] = useState(false);

  const handleAdd = () => {
    api.post('/superadmin/addOrgnaization', organization)                 
      .then(() => {
        fetchData();

        toast({ title: 'Organization added successfully' });
        setOpen(false);
      })
      .catch((error) => {
        console.error('Error adding organization:', error);
        toast({ title: 'Failed to add organization', description: error.message });
      });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };


  const filteredUsers = users.filter((user: any) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <>
      <div>
        <div className='flex'>
          <button
            className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
            onClick={() => {
              setOpen(true);
            }}
          >
            <Plus className='w-5' />
            Add Organization
          </button>
        </div>
        <Input
          className='my-4'
          placeholder='Search the organization name...'
          onChange={handleSearchChange}     
          
          />
        <div className='overflow-x-auto'>
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
              Our organizations
              <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
                Browse a list of Flowbite members designed to help you work and
                play, stay organized, get answers, keep in touch, grow your
                business, and more.
              </p>
            </caption>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='px-6 py-3'>
                  Name
                </th>
                <th scope='col' className='px-6 py-3'>
                  Email
                </th>
                <th scope='col' className='px-6 py-3'>
                  Phone Number
                </th>
                <th scope='col' className='px-6 py-3'>
                  Category
                </th>
                <th scope='col' className='px-6 py-3'>
                  Status
                </th>
                <th scope='col' className='px-6 py-3'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user: any, index: number) => {
                return (
                  <tr
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                    key={index}
                  >
                    <th
                      scope='row'
                      className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                    >
                      {user.name}
                    </th>
                    <td className='px-6 py-4'>{user.email ? user.email : 'Not Available'}</td>
                    <td className='px-6 py-4'>{user.phonenumber ? user.phonenumber : 'Not Available'}</td>

                    <td className='px-6 py-4'>{user.category ? user.category  : "Not Available" }</td>

                    <td className='px-6 py-4'>
                      {user.status === 'unactive' ? 'Unactive' : 'Active'}
                    </td>
                    {/* <td className='px-6 py-4'>{user.role.toUpperCase()}</td> */}
                    <td className='px-6 py-4 text-left'>
                      <button
                        onClick={() => {
                          api
                            .post('/superadmin/activeUser', {
                              user_id: user._id,
                            })
                            .then(() => {
                              setUsers(
                                users.map((item: any) => {
                                  if (item._id === user._id) {
                                    item.status =
                                      item.status === 'unactive'
                                        ? 'active'
                                        : 'unactive';
                                  }
                                  return item;
                                })
                              );
                            });
                        }}
                        className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300'
                      >
                        {user.status === 'unactive' ? 'Active' : 'Unactive'}
                      </button>
                      {/* <button
                        className='bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300'
                        onClick={() => {
                          api
                            .post('/admin/user/delete', { id: user._id })
                            .then(() => {
                              setUsers(
                                users.filter(
                                  (item: any) => item._id !== user._id
                                )
                              );
                            });
                        }}
                      >
                        Delete
                      </button> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}    >
        <DialogContent className='sm:max-w-[495px]    overflow-y-auto'  >
          <DialogHeader>
            <DialogTitle>Add Organization</DialogTitle>
            <DialogDescription>Please input the organization details</DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'   style={{overflowY: "scroll", height:'400px' , maxHeight: "90%"}}>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='name'>Name</Label>
              <Input
                value={organization.name}
                onChange={(e) => setOrganization({ ...organization, name: e.target.value })}
                id='name'
                placeholder='Organization Name'
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='name'>Email</Label>
              <Input
                value={organization.email}
                onChange={(e) => setOrganization({ ...organization, email: e.target.value })}
                id='email'
                placeholder='Email'
              />
            </div>
             <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='name'>User Name</Label>
              <Input
                value={organization.username}
                onChange={(e) => setOrganization({ ...organization, username: e.target.value })}
                id='email'
                placeholder='User Name'
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='name'>Phone Number</Label>
              <Input
                value={organization.phonenumber}
                onChange={(e) => setOrganization({ ...organization, phonenumber: e.target.value })}
                id='number'
                placeholder='Phone Number'
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='name'>Address</Label>
              <Input
                value={organization.address}
                onChange={(e) => setOrganization({ ...organization, address: e.target.value })}
                id='address'
                placeholder='Address '
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='name'>Registrtion No</Label>
              <Input
                value={organization.reg_num}
                onChange={(e) => setOrganization({ ...organization, reg_num: e.target.value })}
                id='registrtion'
                placeholder='Registrtion No'
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='name'>Vat No</Label>
              <Input
                value={organization.vat_num}
                onChange={(e) => setOrganization({ ...organization, vat_num: e.target.value })}
                id='vat'
                placeholder='Vat No'
              />
            </div>
            <div className='flex flex-col gap-3 space-y-1.5'>
  <Label htmlFor='category'>Category</Label>
  <select
    value={organization.category}
    onChange={(e) => setOrganization({ ...organization, category: e.target.value })}
    id='category'
    className='border border-gray-300 rounded-md p-2 focus:outline-none focus:border-blue-500'
  >
    <option value=''>Select Category</option>
    <option value='sports'>Sports</option>
    <option value='social'>Social</option>
    <option value='communal'>Communal</option>
    <option value='religion'>Religion</option>
    <option value='political'>Political</option>
    <option value='student'>Student</option>
    <option value='custom'>Custom</option>
  </select>
</div>
<div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='name'>Password</Label>
              <Input
                value={organization.password}
                onChange={(e) => setOrganization({ ...organization, password: e.target.value })}
                id='vat'
                placeholder='Password'
              />
            </div>

                       <Button onClick={handleAdd}>Add Organization</Button>

            {/* Add other input fields for organization details */}
          </div>
        
        </DialogContent>
      </Dialog>
    </>
  );
}
