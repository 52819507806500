import React, { useEffect, useState } from 'react';
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Calendar, Modal } from "rsuite";
import api from '../../../lib/api';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";

export default function ViewTea() {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEventListModalOpen, setIsEventListModalOpen] = useState(false);
  const [startTime, setStartTime] = useState("10:00");
  const [endTime, setEndTime] = useState("11:00");
  const [description, setDescription] = useState("");
  const [events, setEvents] = useState<any[]>([]); // Replace 'any[]' with the type of your events data
  const [data, setData] = useState<any[]>([]);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [orgId, setOrgId] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgRes = await api.get(`/auth/getUser`);
        console.log("orgRes", orgRes);
  
        setOrgId(orgRes.organization._id);
  
        const teaBookingRes = await api.get(`/user/getTeadeteils`);
        console.log("teaBookingRes", teaBookingRes);
        setData(teaBookingRes);
  
  
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData(); // Call the combined fetch function
  
  }, []);

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  function handleViewAllEvents(date: Date) {
    setSelectedDate(date);
    setIsEventListModalOpen(true);
  }

  function renderCell(date: Date) {
    const dateKey = date.toISOString().split("T")[0];

    const allEvents = data
      .filter((event) => event.startTime.includes(dateKey))
      .map((event, index) => (
        <div key={index}>
          {new Date(event.startTime).toLocaleTimeString()} - {new Date(event.endTime).toLocaleTimeString()}
          <br />
          {event.description}
        </div>
      ));

    const limitedEvents = allEvents.slice(0, 1);
    const remainingEvents = allEvents.slice(1);

    const eventList = showAllEvents ? allEvents : limitedEvents;

    return (
      <div>
        <div className="relative">
          <button
            className="bg-gray-600 rounded-lg absolute top-0 right-0 bottom-0 left-0"
            onClick={() => {
              setSelectedDate(date);
              setIsModalOpen(true);
            }}
          />
        </div>
        {eventList}
        {remainingEvents.length > 0 && (
          <button onClick={() => setShowAllEvents(!showAllEvents)}>
            {/* {showAllEvents ? "View Less" : "View More"} */}
          </button>
        )}
        {remainingEvents.length > 0 && (
          <button onClick={() => handleViewAllEvents(date)}>
            View All Events
          </button>
        )}
      </div>
    );
  }

  return (
    <>
      {!isModalOpen && !isEventListModalOpen && (
        <Calendar bordered renderCell={renderCell} />
      )}

      <Dialog open={isModalOpen} onOpenChange={(val) => setIsModalOpen(val)}>
        <DialogContent className="sm:max-w-[495px]">
          <DialogHeader>
            <DialogTitle>Create Tea Booking time </DialogTitle>
            <DialogDescription>Please select</DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="flex flex-col gap-3 space-y-1.5">
              <Label htmlFor="startTime">Start Time</Label>
              <Input
                type="time"
                id="startTime"
                name="startTime"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-3 space-y-1.5">
              <Label htmlFor="endTime">End Time</Label>
              <Input
                type="time"
                id="endTime"
                name="endTime"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-3 space-y-1.5">
              <Label htmlFor="description">Add description</Label>
              <Input
                id="description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <Button onClick={handleCloseModal}>Cancel</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isEventListModalOpen}
        onOpenChange={(val) => setIsEventListModalOpen(val)}
      >
        <DialogContent className="sm:max-w-[495px]">
          <DialogHeader>
            <DialogTitle>Event List</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            {data
              .filter((event) =>
                event.startTime.includes(
                  selectedDate?.toISOString().split("T")[0]
                )
              )
              .map((event, index) => (
                <div key={index}>
                  {new Date(event.startTime).toLocaleTimeString()} -{" "}
                  {new Date(event.endTime).toLocaleTimeString()}
                  <br />
                  {event.description}
                </div>
              ))}
          </div>
          <DialogFooter>
            <Button onClick={() => setIsEventListModalOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
