import React, { useEffect, useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';

import { Settings, PersonStanding, Image } from 'lucide-react';
import { Label } from '../../../components/ui/label';
import { Input } from '../../../components/ui/input';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
} from '../../../components/ui/avatar';
import { useDispatch } from 'react-redux';
import { login } from '../../../redux/store/auth';

import api from '../../../lib/api';

import { useToast } from '../../../components/ui/use-toast';

import { type UserType } from '../../../types/user';

function MannageProfile() {
  const [tab, setTab] = React.useState('tab1');

  const { toast } = useToast();

  const user = useSelector((root: RootState) => root.auth.user);

  const dispatch = useDispatch();

  const [inputs, setInputs] = React.useState({
    email: '',
    username: '',
    lastname: '',
    gender:'',
    dateofbirth:'',
    phonenumber:'' ,
    address:'',
    current_password: '',
    new_password: '',
    new_password_confirm: '',
    avatar: '',
  });

  const [avatarBlob, setAvatarBlob] = useState<any>(null);

  const handleSaveProfile = () => {
    api
      .post('/auth/editProfile', { ...inputs })
      .then((data) => {
        dispatch(login(data as unknown as UserType));
        toast({ title: 'Successfully updated!' });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return toast({
            title: 'Update is Failed',
            description: error.response.data.message,
          });
        }
        toast({
          title: 'Update is Failed',
          description:
            error.response.data.errors[0].path === 'username'
              ? 'Username must be 6 at mins'
              : 'Invalid Email address',
        });
      });
  };

  const uploadAvatar = async () => {
    if (!avatarBlob) {
      return toast({ title: 'Please select the file!' });
    }

    const avatarFile = await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(avatarBlob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    api
      .post('/auth/uploadAvatar', { data: avatarFile })
      .then((data) => {
        dispatch(login(data as unknown as UserType));
        toast({ title: 'Successfully updated!' });
      })
      .catch(() => {
        toast({ title: 'Uploading is Fiailed!' });
      });
  };

  const onHandleChangePass = () => {
    if (inputs.new_password !== inputs.new_password_confirm) {
      return toast({
        title: 'Update Password is Failed',
        description: 'Password confirm is not match with Password!',
      });
    }
    api
      .post('/auth/updatePassword', { ...inputs })
      .then(() => {
        toast({ title: 'Successfully updated!' });
        setInputs({
          ...inputs,
          current_password: '',
          new_password: '',
          new_password_confirm: '',
        });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return toast({
            title: 'Update is Failed',
            description: error.response.data.message,
          });
        }
        toast({
          title: 'Update is Failed',
          description:
            error.response.data.errors[0].path === 'password'
              ? 'Password must be 6 at mins'
              : 'Invalid Email address',
        });
      });
  };

  useEffect(() => {
    setInputs({
      ...inputs,
      email: user.email,
      username: user.username,
      lastname: user.lastname,
      dateofbirth:user.dateofbirth,
      gender:user.gender,
      address:user.address,
      phonenumber:user.phonenumber,
     
      avatar: user.avatar!,
    });
  }, [user]);

  console.log(user)
  return (
    <Tabs.Root className='TabsRoot' defaultValue='tab1'>
      <Tabs.List
        className='inline-flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400'
        aria-label='Manage your account'
      >
        <Tabs.Trigger
          className={
            tab === 'tab1'
              ? 'inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
              : 'inline-flex p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
          }
          value='tab1'
          onClick={() => {
            setTab('tab1');
          }}
        >
          <PersonStanding className='mr-2 w-4' />
          Account
        </Tabs.Trigger>
        <Tabs.Trigger
          className={
            tab === 'tab2'
              ? 'inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
              : 'inline-flex p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
          }
          value='tab2'
          onClick={() => {
            setTab('tab2');
          }}
        >
          <Settings className='mr-2 w-4' />
          Password
        </Tabs.Trigger>
        <Tabs.Trigger
          className={
            tab === 'tab3'
              ? 'inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
              : 'inline-flex p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
          }
          value='tab3'
          onClick={() => {
            setTab('tab3');
          }}
        >
          <Image className='mr-2 w-4' />
          Avatar
        </Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content className='TabsContent' value='tab1'>
        <p className='text-gray-700 my-3'>
          Make changes to your account here. Click save when you're done.
        </p>
        <div className='mt-1 grid w-full items-center gap-4'>
          <div className='flex flex-col gap-3  space-y-1.5'>
            <Label htmlFor='email'>Email</Label>
            <Input
              required
              value={inputs.email}
              onChange={(e) =>
                setInputs((prev) => ({ ...prev, email: e.target.value }))
              }
              id='email'
              placeholder='john.doe@example.com'
            />
          </div>
        </div>
        <div className='mt-4 grid w-full items-center gap-4'>
          <div className='flex flex-col gap-3  space-y-1.5'>
            <Label htmlFor='email'>first name</Label>
            <Input
              required
              value={inputs.username}
              onChange={(e) =>
                setInputs((prev) => ({ ...prev, username: e.target.value }))
              }
              id='email'
              placeholder='john doe'
            />
          </div>
        </div>
        <div className='mt-4 grid w-full items-center gap-4'>
          <div className='flex flex-col gap-3  space-y-1.5'>
            <Label htmlFor='email'>last name</Label>
            <Input
              required
              value={inputs.lastname}
              onChange={(e) =>
                setInputs((prev) => ({ ...prev, lastname: e.target.value }))
              }
              id='email'
              placeholder=' doe'
            />
          </div>
        </div>
     
        <div className='mt-4 grid w-full items-center gap-4'>
          <div className='flex flex-col gap-3  space-y-1.5'>
            <Label htmlFor='email'>Phone Number</Label>
            <Input
              required
              value={inputs.phonenumber}
              onChange={(e) =>
                setInputs((prev) => ({ ...prev, phonenumber: e.target.value }))
              }
              id='email'
              placeholder='+12 89388484'
            />
          </div>
        </div>
         <div className='mt-4 grid w-full items-center gap-4'>
          <div className='flex flex-col gap-3  space-y-1.5'>
            <Label htmlFor='email'>Address </Label>
            <Input
              required
              value={inputs.address}
              onChange={(e) =>
                setInputs((prev) => ({ ...prev, address: e.target.value }))
              }
              id='email'
              placeholder='Address'
            />
          </div>
        </div> 
        <div className='mt-4 grid w-full items-center gap-4'>
                <Label htmlFor='dob'>Date of Birth</Label>
                <Input
                  value={inputs.dateofbirth}
                  onChange={(e) => setInputs((prev) => ({ ...prev, dateofbirth: e.target.value }))}
                  id='dob'
                  type='date'
                />
              </div>
        <div className='mt-4 grid w-full items-center gap-4'>
                <Label htmlFor='gender'>Gender</Label>
                <select
                  value={inputs.gender}
                  onChange={(e:any) =>
                    setInputs((prev) => ({
                      ...prev,
                      gender: e.target.value,
                    }))
                  }
                  id='gender'
                >
                  <option value='male'>Male</option>
                  <option value='female'>Female</option>
                  <option value='other'>Other</option>
                </select>
              </div>
        <div
          style={{ display: 'flex', marginTop: 20, justifyContent: 'flex-end' }}
        >
          <button
           
            onClick={handleSaveProfile}
            className='disabled:cursor-not-allowed cursor-pointer text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
          >
            Save changes
          </button>
        </div>
      </Tabs.Content>
      <Tabs.Content className='TabsContent' value='tab2'>
        <p className='text-gray-700 my-3'>
          Change your password here. After saving, you'll be logged out.
        </p>
        <div className='mt-1 grid w-full items-center gap-4'>
          <div className='flex flex-col gap-3  space-y-1.5'>
            <Label htmlFor='current_password'>Old Password</Label>
            <Input
              required
              value={inputs.current_password}
              onChange={(e) =>
                setInputs((prev) => ({
                  ...prev,
                  current_password: e.target.value,
                }))
              }
              id='current_password'
              type='password'
            />
          </div>
        </div>
        <div className='mt-3 grid w-full items-center gap-4'>
          <div className='flex flex-col gap-3  space-y-1.5'>
            <Label htmlFor='new_password'>New Password</Label>
            <Input
              required
              value={inputs.new_password}
              onChange={(e) =>
                setInputs((prev) => ({ ...prev, new_password: e.target.value }))
              }
              id='new_password'
              type='password'
            />
          </div>
        </div>
        <div className='mt-3 grid w-full items-center gap-4'>
          <div className='flex flex-col gap-3  space-y-1.5'>
            <Label htmlFor='current_password'>New Password Confirm</Label>
            <Input
              required
              value={inputs.new_password_confirm}
              onChange={(e) =>
                setInputs((prev) => ({
                  ...prev,
                  new_password_confirm: e.target.value,
                }))
              }
              id='new_password_confirm'
              type='password'
            />
          </div>
        </div>
        <div
          style={{ display: 'flex', marginTop: 20, justifyContent: 'flex-end' }}
        >
          <button
            disabled={
              inputs.current_password === '' ||
              inputs.new_password === '' ||
              inputs.new_password_confirm === ''
            }
            onClick={onHandleChangePass}
            className='disabled:cursor-not-allowed cursor-pointer text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
          >
            Change password
          </button>
        </div>
      </Tabs.Content>
      <Tabs.Content className='' value='tab3'>
        <div className='max-w-[800px] mx-auto flex justify-center items-center my-4 flex-col md:flex-row'>
          <div className='md:w-1/2 w-full'>
            <Avatar className='w-52 h-52'>
              <AvatarImage
                className='AvatarImage'
                src={user.avatar!}
                alt='Pedro Duarte'
              />
              <AvatarFallback
                className='bg-blue-900 text-8xl text-white'
                delayMs={600}
              >
                {user.username.toLocaleUpperCase()[0] +
                  user.username.toLocaleUpperCase()[1]}
              </AvatarFallback>
            </Avatar>
          </div>
          <div className='md:w-1/2 w-full flex mt-5'>
            <Input
              type='file'
              onChange={(event) => {
                const file = event.target.files![0];
                setAvatarBlob(file);
              }}
              accept='image/png'
            />
            <div className='flex justify-center ml-2'>
              <button
                onClick={uploadAvatar}
                className='disabled:cursor-not-allowed cursor-pointer text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Tabs.Content>
    </Tabs.Root>
  );
}

export default MannageProfile;
