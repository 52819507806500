import React from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from '../../components/ui/card';
import { Label } from '../../components/ui/label';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import { Link } from 'react-router-dom';
import api from '../../lib/api';
import { useToast } from '../../components/ui/use-toast';

export default function ResetPassword() {
  const [inputs, setInputs] = React.useState({ email: '' });

  const { toast } = useToast();
  const sendRecoveryLink = () => {
    api
      .post('/auth/send_recovery_email', { ...inputs })
      .then(() => {
        toast({
          title: 'Recovery link was sent to your email!',
          description: 'Please check your email box',
        });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return toast({
            title: 'Action unsuccessful',
            description: error.response.data.message,
          });
        }
        toast({
          title: 'Action unsuccessful',
          description:
            error.response.data.errors[0].path === 'password'
              ? 'Password must be 6 at mins'
              : 'Invalid Email address',
        });
      });
  };
  return (
    <div className='flex h-screen flex-col items-center'>
      <Card className='mt-48 w-[380px] py-5 max-[900px]:mt-10 max-[400px]:w-[95%]'>
        <CardHeader>
          <CardTitle>Welcome back</CardTitle>
          <CardDescription>Please reset your password!</CardDescription>
        </CardHeader>
        <CardContent>
          <form>
            <div className='mt-1 grid w-full items-center gap-4'>
              <div className='flex flex-col gap-3  space-y-1.5'>
                <Label htmlFor='email'>Email</Label>
                <Input
                  required
                  value={inputs.email}
                  onChange={(e) =>
                    setInputs((prev) => ({ ...prev, email: e.target.value }))
                  }
                  id='email'
                  placeholder='john.doe@example.com'
                />
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className='mt-1 flex'>
          <Button
            onClick={sendRecoveryLink}
            variant='custom'
            className='w-full'
            disabled={!inputs.email}
          >
            Send Recovery Link
          </Button>
        </CardFooter>
      </Card>
      <span className='mt-6 text-sm'>
        Do you have account?{' '}
        <Link className='text-neutral-400' to='/login'>
          Sign in
        </Link>{' '}
      </span>
    </div>
  );
}
