import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL, API_VERSION } from '../config';

const apiInstance = axios.create({
  baseURL: 'https://api.example.com', // Set your API base URL
  headers: {
    'Content-Type': 'application/json', // Adjust the content type if needed
    Authorization: Cookies.get('user-token') ?? '', // Replace 'your_token' with the actual token
  },
});

const api = {
  post: async (url: string, data: any) => {
    let result = null;
    try {
      result = (
        await apiInstance.post(BASE_URL + '/' + API_VERSION + url, data)
      ).data.data;
    } catch (error) {
      if (!(error as any).response) {
        throw new Error('server have problem');
      }
      if (
        (error as any).response.status === 401 &&
        window.location.pathname !== '/' &&
        window.location.pathname !== '/blogs' &&
        window.location.pathname !== '/faqs' &&
        window.location.pathname !== '/about' &&
        window.location.pathname !== '/price_plans' &&
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/register' &&
        window.location.pathname !== '/register1' &&

        window.location.pathname !== '/register_organization' &&
        window.location.pathname !== '/reset_password' &&
        window.location.pathname !== '/change_password/verify'
      ) {
        window.location.href = '/login';
      }
      throw error;
    }
    return result;
  },
  get: async (url: string) => {
    let result = null;
    try {
      result = (await apiInstance.get(BASE_URL + '/' + API_VERSION + url)).data
        .data;
    } catch (error) {
      if (
        (error as any).response.status === 401 &&
        window.location.pathname !== '/' &&
        window.location.pathname !== '/blogs' &&
        window.location.pathname !== '/faqs' &&
        window.location.pathname !== '/price_plans' &&
        window.location.pathname !== '/about' &&
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/register' &&
        window.location.pathname !== '/register1' &&

        window.location.pathname !== '/register_organization' &&
        window.location.pathname !== '/reset_password' &&
        window.location.pathname !== '/change_password/verify'
      ) {
        window.location.href = '/login';
      }
      throw error;
    }
    return result;
  },
  put: async (url:string, data:any) => {
    let result = null;
    try {
      result = (
        await apiInstance.put(BASE_URL + '/' + API_VERSION + url, data)
      ).data.data;
    } catch (error) {
      if (!(error as any).response) {
        throw new Error('server have problem');
      }
      if (
        (error as any).response.status === 401 &&
        window.location.pathname !== '/' &&
        window.location.pathname !== '/blogs' &&
        window.location.pathname !== '/faqs' &&
        window.location.pathname !== '/about' &&
        window.location.pathname !== '/price_plans' &&
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/register' &&
        window.location.pathname !== '/register1' &&

        window.location.pathname !== '/register_organization' &&
        window.location.pathname !== '/reset_password' &&
        window.location.pathname !== '/change_password/verify'
      ) {
        window.location.href = '/login';
      }
      throw error;
    }
    return result;
  },
  delete: async (url:string, data:any) => {
    let result = null;
    try {
      result = (
        await apiInstance.delete(BASE_URL + '/' + API_VERSION + url, {
          data: data, // send data in the request body for delete
        })
      ).data.data;
    } catch (error) {
      if (!(error as any).response) {
        throw new Error('server have problem');
      }
      if (
        (error as any).response.status === 401 &&
        window.location.pathname !== '/' &&
        window.location.pathname !== '/blogs' &&
        window.location.pathname !== '/faqs' &&
        window.location.pathname !== '/about' &&
        window.location.pathname !== '/price_plans' &&
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/register' &&
        window.location.pathname !== '/register1' &&

        window.location.pathname !== '/register_organization' &&
        window.location.pathname !== '/reset_password' &&
        window.location.pathname !== '/change_password/verify'
      ) {
        window.location.href = '/login';
      }
      throw error;
    }
    return result;
  },
};


export default api;
