import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../../../components/ui/dialog';
import moment from 'moment';
import { Calendar, Whisper, Badge } from 'rsuite';

import api from '../../../lib/api';

type Event = {
  _id: string;
  title: string;
  description: string;
  date: string;
};

const Overlay = React.forwardRef(
  ({ style, onClose, children, ...rest }: any, ref) => {
    const styles = {
      ...style,
      color: '#000',
      background: '#fff',
      width: 200,
      padding: 10,
      borderRadius: 4,
      position: 'absolute',
      border: '1px solid #ddd',
      boxShadow: '0 3px 6px -2px rgba(0, 0, 0, 0.6)',
      zIndex: 50,
    };

    return (
      <div {...rest} style={styles} ref={ref}>
        {children}
      </div>
    );
  }
);

export default function ViewEvents() {
  const [open, setOpen] = useState(false);

  const [currentEvent, setCurrentEvent] = useState<Event | null>(null);

  const [eventList, setEventList] = useState<Event[]>([]);

  function renderCell(date: Date) {
    const list = eventList
      .filter((item) => {
        return (
          moment(item.date).format('YYYY-MM_DD') ===
          moment(date).format('YYYY-MM_DD')
        );
      })
      .map((item) => ({
        ...item,
        time: moment(item.date).format('HH:mm'),
      }));
    const displayList = list.filter((_, index) => index < 2);

    if (list.length) {
      const moreCount = list.length - displayList.length;
      const moreItem = (
        <li>
          <Whisper
            placement='top'
            trigger='click'
            speaker={(props, ref) => {
              const { className, left, top, onClose } = props;
              return (
                <Overlay
                  style={{ left, top }}
                  onClose={onClose}
                  className={className}
                  ref={ref}
                >
                  {list.map((item, index) => (
                    <p
                      key={index}
                      className='cursor-pointer'
                      onClick={() => {
                        onClose();
                        setOpen(true);
                        setCurrentEvent(item);
                      }}
                    >
                      <b>{item.time}</b> - {item.title}
                    </p>
                  ))}
                </Overlay>
              );
            }}
          >
            <span className='cursor-pointer'>{moreCount} more</span>
          </Whisper>
        </li>
      );

      return (
        <ul className='calendar-todo-list'>
          {displayList.map((item, index) => (
            <li
              key={index}
              className='cursor-pointer hover:bg-yellow-300'
              onClick={() => {
                setOpen(true);
                setCurrentEvent(item);
              }}
            >
              <Badge /> <b>{item.time}</b> - {item.title}
            </li>
          ))}
          {moreCount ? moreItem : null}
        </ul>
      );
    }

    return null;
  }

  useEffect(() => {
    api.get('/user/getEvents').then((data) => {
      setEventList(data);
    });
  }, []);

  return (
    <>
      <Calendar bordered renderCell={renderCell} />
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>{currentEvent?.title}</DialogTitle>
            <DialogDescription>
              {moment(currentEvent?.date).format('YYYY-MM-DD HH:mm')}
            </DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='grid grid-cols-4 items-center gap-4'>
              {currentEvent?.description}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
