'use client';

import {
  LayoutDashboardIcon,
  LogOut,
  User,
  Menu as MenuIcon,
} from 'lucide-react';

import { Link } from 'react-router-dom';

import Logo_img from '../assets/logo.svg';

import { Button } from './ui/button';
import { useToast } from './ui/use-toast';
// import { useRouter } from "next/navigation";
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { Sheet, SheetContent, SheetHeader, SheetTrigger } from './ui/sheet';

import { UserMenu, AdminMenu, SuperMenu } from './role-menu';

import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

type UserState = {
  id: string;
  avatar?: string;
  username: string;
  password: string;
  email: string;
};

const USER_INITIAL: UserState = {
  id: '',
  username: '',
  avatar: undefined,
  password: '',
  email: '',
};

export default function Menu() {
  const { toast } = useToast();

  const user_store = useSelector((state: RootState) => state.auth.user);

  // const updateUserEndPoint = api.auth.updateUser.useMutation({
  //   onSuccess: (data) => {},
  // });

  const [mode, setMode] = useState('dark');
  const [user, setUser] = useState(USER_INITIAL);

  useEffect(() => {
    let localMode = localStorage.getItem('mode');
    if (!localMode) {
      return;
    }
    if (localMode == 'dark') {
      document.getElementById('mode')?.classList.add('dark');
      setMode('dark');
    } else {
      document.getElementById('mode')?.classList.remove('dark');
      setMode('light');
    }
  }, []);

  function handleLogout() {
    Cookies.remove('user-token');
    window.location.href = '/login';
  }

  function toggleMode() {
    document.getElementById('mode')?.classList.toggle('dark');
    const v = mode == 'light' ? 'dark' : 'light';
    setMode(() => v);
    localStorage.setItem('mode', v);
  }

  return (
    <>
      <Sheet>
        <div className='flex items-center p-3 shadow-md'>
          <SheetTrigger asChild>
            <Button className='max-[500px]:left-2' variant='default'>
              <MenuIcon className='h-5 w-5' />{' '}
              <span className='ml-2 hidden sm:flex'>Menu</span>
            </Button>
          </SheetTrigger>
          <img src={Logo_img} alt='logo_img' width={40} className='ml-5' />
          <p className='ml-4 text-[30px] font-[700] text-[#8053FF] dark:text-white'>
            APZ-
            <span className='text-[#23155B] dark:text-gray-400'>
              Membership
            </span>
          </p>
        </div>
        <SheetContent side='left' className='z-[9999] dark:border-slate-800'>
          <SheetHeader>
            <div className='flex flex-col gap-2 pt-8'>
              <SheetTrigger asChild>
                <Link
                  to='/dashboard'
                  className='text-gray-900 flex cursor-pointer flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
                >
                  <LayoutDashboardIcon className='mr-2 h-5 w-5' />
                  <span>Dashboard</span>
                </Link>
              </SheetTrigger>
              {user_store.role === 'admin' && (
                <AdminMenu category={user_store.organization.category} />
              )}
              {user_store.role === 'user' && (
                <UserMenu category={user_store.organization.category} />
              )}
              {user_store.role === 'super_admin' && <SuperMenu />}
              {user_store.role !== 'super_admin' &&
              <SheetTrigger asChild>
                <Link
                  to={'/dashboard/edit_profile'}
                  className='text-gray-900 flex cursor-pointer flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
                >
                  <User className='mr-2 h-5 w-5' />
                  <span>Edit profile</span>
                </Link>
              </SheetTrigger>
}
              <div
                className='text-gray-900 flex cursor-pointer flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
                onClick={handleLogout}
              >
                <LogOut className='mr-2 h-5 w-5' />
                <span>Log out</span>
              </div>
            </div>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </>
  );
}
