import React, { useEffect, useState } from "react";
import api from "../../../lib/api";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Plus, ImportIcon, Download } from "lucide-react";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import { useToast } from "../../../components/ui/use-toast";
import { CSVLink } from "react-csv";

export default function MannageReport() {
  const [users, setUsers] = useState<any>([]);
  const [showBox, setShowBox] = useState<any>(false);
  const [selectedReportType, setSelectedReportType] = useState<string>("");
  const [showCommunityReportForm, setShowCommunityReportForm] = useState(false);
  const [showInvenrtoyReportForm, setShowInvenrtroyReportForm] = useState(false);

  const [val , setVal] = useState('');

  const handleGenerateReport = (reportType: string) => {
    setOpen(false); 
    setVal(reportType)
    console.log(reportType)

    if (reportType === "Community") {
      setShowCommunityReportForm(true);
      setShowBox(false);
    }
  else  if (reportType === "inventory") {
    setShowCommunityReportForm(true); 
      setShowBox(false); 
      console.log(reportType)

    }
    else  if (reportType === "performance") {
      setShowCommunityReportForm(true); 
      setShowBox(false);
    } else  if (reportType === "Booking") {
      setShowCommunityReportForm(true); 
      setShowBox(false); 
    } else  if (reportType === "Maintenance") {
      setShowCommunityReportForm(true); 
      setShowBox(false); 
    }
  };
  const { toast } = useToast();

  console.log("showCommunityReportForm" , showCommunityReportForm)

  const [open, setOpen] = useState(false);



  return (
    <>
      <div>
        <div className="flex">
          <button
            className="flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            onClick={() => {
              setOpen(true);
            }}
          >
            <Plus className="w-5" />
            Create Report
          </button>
          <label
            onClick={() => {
              setShowBox(true);
            }}
            className="flex justify-center items-center focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 mr-2"
          >
            <ImportIcon className="w-5 mr-2" />
            Import Report.
          </label>
          <label
            onClick={() => {
              setShowBox(true);
            }}
            className="flex justify-center items-center focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 mr-2"
          >
            <ImportIcon className="w-5 mr-2" />
            Export Report.
          </label>
        
        </div>
        <Input
          className="my-4"
          placeholder="Search the report type..."
          onChange={() => {}}
        />
         <div className='overflow-x-auto'>
          {
            <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
              Our Reports
              <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
                Browse a list of Flowbite members designed to help you work and
                play, stay organized, get answers, keep in touch, grow your
                business, and more.
              </p>
            </caption>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='px-6 py-3'>
                 Name
                </th>
                <th scope='col' className='px-6 py-3'>
                 Report
                </th>
                <th scope='col' className='px-6 py-3'>
                  Report Type
                </th>
                
              
                
                <th scope='col' className='px-6 py-3'>
                  Edit
                </th>
              </tr>
            </thead>
         

<tbody>
  <tr>
  <td className='px-6 py-4'> Jhone Dow</td>
         <td className='px-6 py-4'> Maintanince</td>
         <td className='px-6 py-4'> Maintanince </td>
         <td className='px-6 py-4'> Delete </td>

  </tr>
</tbody>


          </table>
          }
         
        </div>
      </div>
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className="sm:max-w-[495px]">
          <DialogHeader>
            <DialogTitle>Generate Report</DialogTitle>
            <DialogDescription>
              Please select the type of report you want to generate:
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            {/* Add buttons for different report types */}
            <Button onClick={() => handleGenerateReport("Community")}>
              Community Report
            </Button>
            <Button onClick={() => handleGenerateReport("inventory")}>
              Inventory Report
            </Button>
            <Button onClick={() => handleGenerateReport("performance")}>
              Users Report
            </Button>
            <Button onClick={() => handleGenerateReport("Booking")}>
              Booking Report
            </Button>
            <Button onClick={() => handleGenerateReport("Maintenance")}>
              Maintenance Report
            </Button>
          </div>
          <DialogFooter>
            {/* <Button onClick={handleGenerateReport}>Generate</Button> */}
            <Button variant="outline">Cancel</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
   
      <Dialog
        open={showCommunityReportForm}
        onOpenChange={(val) => setShowCommunityReportForm(val)}
      >
        <DialogContent className="sm:max-w-[495px]">
          <DialogHeader>
            <DialogTitle>{val} Report Form</DialogTitle>
            <DialogDescription>
              Please fill in the details for the Community report:
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <Label>Report Name</Label>

            <Input placeholder="Enter community name" />
            <Label>Report Location</Label>

            <Input placeholder="Enter community location" />

            <Label>Report Type</Label>

            <Input placeholder="Enter Type" />

          </div>
          <DialogFooter>
            <Button>Submit</Button>
            <Button
              variant="outline"
              onClick={() => setShowCommunityReportForm(false)}
            >
              Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
