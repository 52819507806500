import React, { useEffect, useState } from 'react';
import api from '../../../lib/api';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Plus, ImportIcon, Download } from 'lucide-react';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { useToast } from '../../../components/ui/use-toast';
import { number } from 'echarts';

export default function MannagePayment() {
  const [users, setUsers] = useState<any[]>([]);
  const [name, setName] = useState<string>('');
  const [ammount ,setAmount] = useState<any>(0)
  const [email ,setEmail] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [orgid, setOrgid] = useState<string>('');
  const [userid, setUserid] = useState<string>('');
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgRes = await api.get(`/auth/getUser`);
        setOrgid(orgRes.organization._id);
        setUserid(orgRes._id);
  
        // Fetch guest passes with organization ID
        const res = await api.get(`/user/gettransaction?userId=${orgRes._id}`);
        setUsers(res);
        setFilteredUsers(res);
      } catch (error) {
        console.error(error);
        // Handle error if needed
      }
    };
  
  
  
    fetchData();
   
  }, []);
  
  const handleAdd = () => {
    api
      .post('/user/transaction', {
        userName: name,
        description: description,
        organizationId: orgid,
        userId: userid,
        amount: ammount,
        email:email
      })
      .then((response) => {
        setOpen(false);
  
        setUsers((prevUsers) => [...prevUsers, response]);
        setFilteredUsers((prevFilteredUsers) => [...prevFilteredUsers, response]);
  
        console.log(response);
      })
      .catch((error) => {
        // Handle errors
      });
  };
  
  
  
  

 
  return (
    <>
      <div>
      <h5> Payment List</h5>

        <div className='flex'>
          <button
            className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
            onClick={() => {
              setOpen(true);
            }}
          >
            <Plus className='w-5' />
            Create Payment
          </button>

        

         
        </div>
    
        <div className='overflow-x-auto'>
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
              Our transection
              <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
                Browse a list of Flowbite members designed to help you work and
                play, stay organized, get answers, keep in touch, grow your
                business, and more.
              </p>
            </caption>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='px-6 py-3'>
                  Name
                </th>
                <th scope='col' className='px-6 py-3'>
                  Description
                </th>
                <th scope='col' className='px-6 py-3'>
                  Status
                </th>

                <th scope='col' className='px-6 py-3'>
                  Amount
                </th>
               
              </tr>
            </thead>
            <tbody>
              {
              filteredUsers && 
              filteredUsers.map((user: any, index: number) => {
                return (
                  <tr
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                    key={index}
                  >
                    <th
                      scope='row'
                      className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                    >
                      {user.userName}
                    </th>
                    <td className='px-6 py-4'>{user.description
}</td>
                    <td className='px-6 py-4'>
                                           {user.status === false ? 'Pending' : 'Accept'}

                    </td>
                    <td className='px-6 py-4' style={{cursor:'pointer'}} >{user.amount}</td>

                    {/* <td className='px-6 py-4'>{user.role.toUpperCase()}</td> */}
                    <td className='px-6 py-4 text-left'>
                      {/* <button
                        onClick={() => {
                          api
                            .post('/admin/user/activeUser', {
                              user_id: user._id,
                            })
                            .then(() => {
                              setUsers(
                                users.map((item: any) => {
                                  if (item._id === user._id) {
                                    item.status =
                                      item.status === 'unactive'
                                        ? 'active'
                                        : 'unactive';
                                  }
                                  return item;
                                })
                              );
                            });
                        }}
                        className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300'
                      >
                        {user.status === 'unactive' ? 'Active' : 'Unactive'}
                      </button> */}
                      {/* <button
                        className='bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300'
                        onClick={() => {
                          api
                            .post('/admin/user/delete', { id: user._id })
                            .then(() => {
                              setUsers(
                                users.filter(
                                  (item: any) => item._id !== user._id
                                )
                              );
                            });
                        }}
                      >
                        Delete
                      </button> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>Payment</DialogTitle>
            <DialogDescription>Please input the payment detail</DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
           
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='username'>Username</Label>
              <Input
                value={name}
                onChange={(e)=>setName(e.target.value)
                }
                id='username'
                placeholder='johndoe'
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='username'>Email</Label>
              <Input
                value={email}
                onChange={(e)=>setEmail(e.target.value)
                }
                id='email'
                placeholder='jhone@gmail.com'
              />
            </div>

            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='email'> Description</Label>
              <Input
                value={description}
                onChange={(e)=>setDescription(e.target.value)
                }
                id='email'
                placeholder='Description'
              />
            </div>

            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='email'> Amount</Label>
              <Input
                value={ammount}
                onChange={(e)=>setAmount(e.target.value)
                }
                id='email'
                placeholder='Amount'
              />
            </div>
           
          </div>
          <DialogFooter>
            <Button
              onClick={handleAdd}
              // disabled={!user.password && !user.username}
              type='submit'
            >
              Payment
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
