import { Routes, Route } from 'react-router-dom';

import Dashboard from '../pages/main/super/dashboard';
import EditUser from '../pages/main/super/edit_user';
import ManageOrg from '../pages/main/super/manage_org';
import ManageSystem from '../pages/main/super/manage_system';
import ManageSecurity from '../pages/main/super/manage_security';
import ManagePricePlans from '../pages/main/super/manage_price_plans';
import NoRoutePage from '../components/no_route_page';

import MainLayout from '../layouts/main';
import MannageProfile from '../pages/main/super/manage_profile';
import Report from '../pages/main/super/Report';
import Faq from '../pages/main/super/Faq';
import Transections from '../pages/main/super/Transections'
import Notifications from '../pages/main/super/Notifications';
import MemberShip from '../pages/main/super/MemberShip';
export default function SuperRouter() {
  return (
    <MainLayout>
      <Routes>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/dashboard/users' element={<EditUser />} />
        <Route path='/dashboard/manage_org' element={<ManageOrg />} />
        <Route path='/dashboard/manage_system' element={<ManageSystem />} />
        <Route path='/dashboard/manage_security' element={<ManageSecurity />} />
        <Route path='/dashboard/manage_price_plans' element={<ManagePricePlans />} />
        <Route path='/dashboard/manage_profile' element={<MannageProfile/>} /> 
        <Route path='/dashboard/report' element={<Report/>} />
        <Route path='/dashboard/faq' element={<Faq/>} />
        <Route path='/dashboard/transection' element={<Transections/>} />
        <Route path='/dashboard/notification' element={<Notifications/>} />
        <Route path='/dashboard/membership' element={<MemberShip/>} />





        <Route path='*' element={<NoRoutePage />} />
      </Routes>
    </MainLayout>
  );
}
