import React, { useState , useEffect } from "react";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Calendar, Modal } from "rsuite";
import api from '../../../lib/api';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";

interface Event {
  _id: any;
  startTime: string;
  endTime: string;
  description: string;
  username: string;
  // Add other properties if needed
}

export default function ManageTeaboking() {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEventListModalOpen, setIsEventListModalOpen] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<string>("10:00");
  const [endTime, setEndTime] = useState<string>("11:00");
  const [description, setDescription] = useState<string>("");
  const [events, setEvents] = useState<Event[]>([]);
  const [data, setData] = useState<Event[]>([]);
  const [orgName, setOrgName] = useState<string>('');
  const [orgId, setOrgId] = useState<string>('');
  const [cat, setCat] = useState<string>('');
  const [showAllEvents, setShowAllEvents] = useState(false);


  const [selectedEventId, setSelectedEventId] = useState<number | null>(null);

  function handleDateSelect(date : Date) {
    setSelectedDate(date);
    setIsModalOpen(true); // Open the modal when a date is selected
  }

  const [users, setUsers] = useState<any>([]);


  const init_user = {
    username: '',
    email: '',
    password: '',
  };

  function handleAddEvent() {
    if (selectedDate) {
      const isValidStartTime = !isNaN(new Date(`${selectedDate.toISOString().split("T")[0]}T${startTime}`).valueOf());
      const isValidEndTime = !isNaN(new Date(`${selectedDate.toISOString().split("T")[0]}T${endTime}`).valueOf());
  
      if (!isValidStartTime || !isValidEndTime) {
        console.log("Invalid start or end time. Please provide valid dates.");
        return;
      }
  
      const newEvent: Event = {
        _id: orgId, 
        startTime: new Date(`${selectedDate.toISOString().split("T")[0]}T${startTime}`).toISOString(),
        endTime: new Date(`${selectedDate.toISOString().split("T")[0]}T${endTime}`).toISOString(),
        description,
        username : orgName, // replace with the actual username
      };
  
      api.post('/admin/addteabooking', {
        organizationId : orgId,
        category: cat,
        description: newEvent.description,
        startTime: newEvent.startTime,
        endTime: newEvent.endTime,
        orgName: orgName,
      })
      .then((response) => {
        console.log("Event added successfully::", response);
        api.get(`/admin/getteabooking`).then((res) => {
          setData(res);
          console.log("apiCall")
          setDescription("");
        });
      })
      .catch((error) => {
        console.error('Error adding event:', error);
      });
  
      setEvents((prevEvents) => [...prevEvents, newEvent]);
      setIsModalOpen(false);
    } else {
      console.log("Please select a date before adding an event.");
    }
  }



  function handleDeleteEvent(Id:any) {
    console.log("Id" , Id)
    setSelectedEventId(Id);
    if (Id) {
      api.post('/admin/deleteteabooking', {
        teaBookingId: Id,
      })
      .then((response) => {
        console.log("Event deleted successfully:", response);
        api.get(`/admin/getteabooking`).then((res) => {
          setData(res);
        });
      })
      .catch((error) => {
        console.error('Error deleting event:', error);
      });

      setIsEventListModalOpen(false); // Close the event list modal after deletion
    } else {
      console.log("Please select an event to delete.");
    }
  }

  

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgRes = await api.get(`/auth/getUser`);
        console.log("orgRes", orgRes);
  
        setOrgName(orgRes.organization.name);
        setOrgId(orgRes.organization._id);
        setCat(orgRes.organization.category);
  
        const teaBookingRes = await api.get(`/admin/getteabooking`);
        console.log("teaBookingRes", teaBookingRes);
        setData(teaBookingRes);
  
        // Additional API calls can be added here if needed
  
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData(); // Call the combined fetch function
  
  }, []);
  

  function renderCell(date: Date) {
    const dateKey = date.toISOString().split("T")[0];
  
    const filteredEvents: Event[] = data.filter((event) => event.startTime.includes(dateKey));
  
    const allEvents = filteredEvents.map((event, index) => (
      <div key={index}>
        {new Date(event.startTime).toLocaleTimeString()} - {new Date(event.endTime).toLocaleTimeString()}
        <br />
        {event.description}
        <br />
        <button onClick={() => {
          setSelectedDate(date);
          setIsEventListModalOpen(true);
        }}>
          View All Events
        </button>
      </div>
    ));
  
    // Include the first event in limitedEvents
    const limitedEvents = allEvents.slice(0, 1);
    const remainingEvents = allEvents.slice(1);
  
    const eventList = showAllEvents ? allEvents : limitedEvents;
  
    return (
      <>
        {isModalOpen ? null : (
          <div className="relative">
            <button
              className="bg-gray-600 rounded-lg absolute top-0 right-0 bottom-0 left-0"
              onClick={() => {
                setSelectedDate(date);
                setIsModalOpen(true);
              }}
            />
          </div>
        )}
        <div>
          {eventList}
          {remainingEvents.length > 0 && (
            <button onClick={() => setShowAllEvents(!showAllEvents)}>
              {/* {showAllEvents ? "View Less" : "View More"} */}
            </button>
          )}
        </div>
      </>
    );
  }
  


  return (
    <>
      {!isModalOpen && (
        <Calendar bordered renderCell={renderCell} onSelect={handleDateSelect} />
      )}

      <Dialog open={isModalOpen} onOpenChange={(val) => setIsModalOpen(val)}>
        <DialogContent className="sm:max-w-[495px]">
          <DialogHeader>
            <DialogTitle>Create Tea Booking time </DialogTitle>
            <DialogDescription>Please select</DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="flex flex-col gap-3 space-y-1.5">
              <Label htmlFor="startTime">Start Time</Label>
              <Input
                type="time"
                id="startTime"
                name="startTime"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-3 space-y-1.5">
              <Label htmlFor="endTime">End Time</Label>
              <Input
                type="time"
                id="endTime"
                name="endTime"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-3 space-y-1.5">
              <Label htmlFor="description">Add description</Label>
              <Input
                id="description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button onClick={handleAddEvent}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={isEventListModalOpen} onOpenChange={(val) => setIsEventListModalOpen(val)}>
        <DialogContent className="sm:max-w-[495px]">
          <DialogHeader>
            <DialogTitle>Event List</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
  {data
    .filter((event) => {
      // Check if selectedDate is defined before accessing its properties
      return selectedDate && event.startTime.includes(selectedDate.toISOString().split("T")[0]);
    })
    .map((event, index) => (
      <div key={index}>
        {new Date(event.startTime).toLocaleTimeString()} - {new Date(event.endTime).toLocaleTimeString()}
        <br />
        <div className='flex justify-between'>
          <div>{event.description}</div>
          <div>
            <Button onClick={() => handleDeleteEvent(event._id)}>Delete</Button>
          </div>
        </div>
      </div>
    ))}
</div>

          <DialogFooter>
            <Button onClick={() => setIsEventListModalOpen(false)}>Close</Button>
            {/* <Button onClick={handleDeleteEvent}>Delete Selected Event</Button> */}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
