import React, { useState, useEffect } from 'react';
import { Button } from '../../components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../../components/ui/card';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { useToast } from '../../components/ui/use-toast';
import api from '../../lib/api';
import { useNavigate } from 'react-router-dom';

export default function Verify_email() {
  const [EmailValue, setEmailValue] = useState('');
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get('email');
    if (email) {
      setEmailValue(email);
    }
  }, []);

  const VerifyEmail = () => {
    api
      .post('/auth/emailverify', {
        email: EmailValue
      })
      .then((response) => {
        toast({ title: 'Register is done!' });
        setEmailValue('');
        navigate('/login');
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.data && error.response.data.status === 'error') {
          toast({ title: error.response.data.message });
        } else {
          toast({ title: 'An error occurred' });
        }
      });
  };

  return (
    <div className='flex h-screen flex-col items-center'>
      <Card className='mt-20 w-[380px] py-5 max-[900px]:mt-10 max-[400px]:w-[95%]'>
        <CardHeader>
          <CardTitle>Welcome back</CardTitle>
          <CardDescription>Please Verify your account.</CardDescription>
        </CardHeader>
        <CardContent>
          <form>
            <div className='mt-1 grid w-full items-center gap-4'>
              <div className='flex flex-col gap-3 space-y-1.5'>
                <Label htmlFor='email'>Email </Label>
                <Input onChange={(e) => setEmailValue(e.target.value)} placeholder='email' type='email' value={EmailValue} />
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className='mt-1 flex'>
          <Button onClick={VerifyEmail} variant='custom' className='w-full'>
            Verify
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
