import React, { useEffect, useState } from 'react';
import api from '../../../lib/api';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Plus, ImportIcon, Download } from 'lucide-react';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { useToast } from '../../../components/ui/use-toast';

export default function ViewHandicapped() {
    const [users, setUsers] = useState<any[]>([]);
  
      const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
      const [orgid, setOrgid] = useState<string>('');

    const [open, setOpen] = useState(false);
    const { toast } = useToast();
  
    useEffect(()=>{
      const fetchData = async () => {
        try {
          const orgRes = await api.get(`/auth/getUser`);
          setOrgid(orgRes.organization._id);
    
          // Fetch golf lessons with organization ID
          const res = await api.get(`/admin/getAllHandicappedUsers`);
          
          setUsers(res);
          console.log(res)
          setFilteredUsers(res);
        } catch (error) {
          console.error(error);
          // Handle error if needed
        }
      };
    
      fetchData();
    }, []);


    // useEffect(() => {
    //     const fetchUsers = async () => {
    //       try {
    //         const res = await api.get(`/admin/getAllHandicappedUsers`);
    //         setUsers(res);
    //         console.log(res)
    //         setFilteredUsers(res);
    //       } catch (error) {
    //         console.error(error);
    //         // Handle error if needed
    //       }
    //     };
    
    
    
    //     fetchUsers();
       
    //   }, []);
  
   



    const handleSearch = (searchText: string) => {
        const filtered = users.filter((user) =>
          user.username.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredUsers(filtered);
      };

    
  return (
    <>
  
      <div>
        <div className='flex'>
        

        
        </div>
        <Input className='my-4'  placeholder='Search by username...'
        onChange={(e) => handleSearch(e.target.value)}/>
        <div className='overflow-x-auto'>
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
            Handicapped users
              <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
                Browse a list of Flowbite members designed to help you work and
                play, stay organized, get answers, keep in touch, grow your
                business, and more.
              </p>
            </caption>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='px-6 py-3'>
                  Email
                </th>
                <th scope='col' className='px-6 py-3'>
                  Username
                </th>
                <th scope='col' className='px-6 py-3'>
                  Status
                </th>
                <th scope='col' className='px-6 py-3'>
                  Date
                </th>
                <th scope='col' className='px-6 py-3'>
                  Time
                </th>
               
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user: any, index: number) => {
                return (
                  <tr
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                    key={index}
                  >
                    <th
                      scope='row'
                      className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                    >
                      {user.email}
                    </th>
                    <td className='px-6 py-4'>{user.username}</td>
                    <td className='px-6 py-4'>
                      {user.status === 'unactive' ? 'Unactive' : 'Active'}
                    </td>
                    <td className='px-6 py-4'>
                    <div>{new Date(user.createdAt).toLocaleDateString()}</div>
                    </td>
                    <td className='px-6 py-4'>
  <div>{new Date(user.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
</td>
                    {/* <td className='px-6 py-4'>{user.role.toUpperCase()}</td> */}
                    <td className='px-6 py-4 text-left'>
                      {/* <button
                        onClick={() => {
                          api
                            .post('/admin/user/activeUser', {
                              user_id: user._id,
                            })
                            .then(() => {
                              setUsers(
                                users.map((item: any) => {
                                  if (item._id === user._id) {
                                    item.status =
                                      item.status === 'unactive'
                                        ? 'active'
                                        : 'unactive';
                                  }
                                  return item;
                                })
                              );
                            });
                        }}
                        className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300'
                      >
                        {user.status === 'unactive' ? 'Active' : 'Unactive'}
                      </button> */}
                      {/* <button
                        className='bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300'
                        onClick={() => {
                          api
                            .post('/admin/user/delete', { id: user._id })
                            .then(() => {
                              setUsers(
                                users.filter(
                                  (item: any) => item._id !== user._id
                                )
                              );
                            });
                        }}
                      >
                        Delete
                      </button> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      
    </>
  );
}
