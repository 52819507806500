import React, { useEffect } from 'react';

import api from '../../../lib/api';
import { useSelector } from 'react-redux';

import { RootState } from '../../../redux/store';

export default function ManageReligionGroups() {
  const [groups, setGroups] = React.useState<any[]>([]);

  const user = useSelector((root: RootState) => root.auth.user);

  useEffect(() => {
    api.get('/user/group').then((data) => {
      setGroups(data);
    });
  }, []);

  return (
    <div>
      <div className='overflow-x-auto'>
        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
          <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
            Our groups
            <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
              Browse a list of group.
            </p>
          </caption>
          <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th className='px-6 py-3'>Name</th>
              <th className='px-6 py-3'>Description</th>
              <th className='px-6 py-3'>Users</th>
              <th className='px-6 py-3'>Action</th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group: any, index: number) => {
              return (
                <tr
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  key={index}
                >
                  <th
                    scope='row'
                    className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                  >
                    {group.name}
                  </th>
                  <th
                    scope='row'
                    className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                  >
                    {group.description}
                  </th>

                  <td className='px-6 py-4'>{group.userCount}</td>
                  <td className='px-6 py-4 text-left'>
                    <button
                      onClick={() => {
                        api
                          .post('/user/group/join', { id: group._id })
                          .then((data) => {
                            setGroups(data);
                          });
                      }}
                      className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300'
                    >
                      {group.users.find((item: any) => item === user._id)
                        ? 'Left'
                        : 'Join'}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
