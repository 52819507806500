import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { cn } from '../../lib/utils';

import { ChevronDownIcon } from 'lucide-react';

export const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof Accordion.Trigger>,
  React.ComponentPropsWithoutRef<typeof Accordion.Trigger>
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className='AccordionHeader'>
    <Accordion.Trigger
      className={cn('text-xl flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3', className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <ChevronDownIcon className='AccordionChevron' aria-hidden />
    </Accordion.Trigger>
  </Accordion.Header>
));

export const AccordionContent = React.forwardRef<
  React.ElementRef<typeof Accordion.Content>,
  React.ComponentPropsWithoutRef<typeof Accordion.Content>
>(
  ({ children, className, ...props }, forwardedRef: any) => (
    <Accordion.Content
      className={cn('bg-white p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900', className)}
      {...props}
      ref={forwardedRef}
    >
      <div className='AccordionContentText'>{children}</div>
    </Accordion.Content>
  )
);
