import React, { useEffect, useState } from "react";
import api from "../../../lib/api";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Plus, ImportIcon, Download, UserPlus } from "lucide-react";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import { useToast } from "../../../components/ui/use-toast";
import { table } from "console";
export default function ManageBookings() {

  const [users, setUsers] = useState<any[]>([]);

  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [orgid, setOrgid] = useState<string>('');
  const [open, setOpen] = useState(false);
  const { toast } = useToast();



useEffect(()=>{
  const fetchData = async () => {
    try {
      const orgRes = await api.get(`/auth/getUser`);
      setOrgid(orgRes.organization._id);

      // Fetch golf lessons with organization ID
      const res = await api.get(`/admin/getBooking`);
      
      setUsers(res);
      console.log(res);
      setFilteredUsers(res);
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };

  fetchData();
}, []);

  const handleSearch = (searchText: string) => {
    const filtered = users.filter((user) => {
      if (
        user.name &&
        user.name.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return true;
      }
      // Add similar checks for other properties like description if needed
      return false;
    });

    setFilteredUsers(filtered);
  };

  const handleAccept = async (userId: string) => {
    try {
      const response = await api.put("/admin/updatebooking", {
        _id: userId,
        status: true,
      });

      console.log("Event added successfully:", response);
      window.location.reload();
    } catch (error) {
      console.error("Error rejecting user:", error);
    }
  };
  

  const handleReject = async (userId:any) => {
    try {
      const response = await api.delete("/admin/deletebooking", {
        bookingId: userId,
      });

      console.log("Event added successfully:");
      window.location.reload();
    } catch (error) {
      console.error("Error rejecting user:", error);
    }
  };

  // const handleAdd = async () => {
  //   try {
  //     // const response = await api.delete("/admin/deleteguestPass", {
  //     //   guestPassId: userId,
  //     // });

  //     console.log("Event added successfully:", response);
  //     window.location.reload();
  //   } catch (error) {
  //     console.error("Error rejecting user:", error);
  //   }
  // };




  return <div>
    <div>
        <div className="flex">
        {/* <button
            className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
            onClick={() => {
              setOpen(true);
            }}
          >
             <Plus className='w-5' />
            Add Booking
          </button>  */}

        </div>
        
        <Input
          className="my-4"
          placeholder="Search by username..."
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
              Guest pass
              <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                Browse a list of Flowbite members designed to help you work and
                play, stay organized, get answers, keep in touch, grow your
                business, and more.
              </p>
            </caption>
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user: any, index: number) => {
                return (
                  <tr
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    key={index}
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {user.name}
                    </th>
                    <td className="px-6 py-4">{user.description}</td>
                    <td className='px-6 py-4'>{new Date(user.Date).toLocaleDateString('en-GB')}</td>

                    <td className="px-6 py-4">
                      {user.status === false ? "Pending" : "Approved"}
                    </td>
                    <td className="px-6 py-4">
                      {user.status === false ? (
                        <Button onClick={() => handleAccept(user._id)}>
                          Approve
                        </Button>
                      ) : (
                        ""
                      )}
                        { user.status === false ? 
                      <Button
                        className="mx-2"
                        onClick={() => handleReject(user._id)}
                      >
                        Reject
                      </Button> : ""
              }
                 
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>Add User</DialogTitle>
            <DialogDescription>Please input the user detail</DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='email'>Username </Label>
              <Input
                // value={user.email}
                // onChange={(e) =>
                //   setUser((prev) => ({ ...prev, email: e.target.value }))
                // }
                id='email'
                placeholder='john.doe@example.com'
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='username'>Description</Label>
              <Input
                // value={user.username}
                // onChange={(e) =>
                //   setUser((prev) => ({ ...prev, username: e.target.value }))
                // }
                id='username'
                placeholder='johndoe'
              />
            </div>
            <div className='mt-2 flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='password'>Select Date</Label>
              <Input
                // value={user.password}
                // onChange={(e) =>
                //   setUser((prev) => ({ ...prev, password: e.target.value }))
                // }
                type='date'
                id='password'
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              onClick={handleAdd}
              // disabled={!user.password && !user.username}
              type='submit'
            >
              Add User
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog> */}
  </div>;
}
