import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Plus } from 'lucide-react';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';

import api from '../../../lib/api';

export default function ManageQueries() {
  const [open, setOpen] = useState(false);

  const [queries, setQueries] = useState<any>([
    {
      _id: '355',
      title: 'How to manage the API key for the organization?',
      description: 'To add the api key please here the contact',
    },
  ]);

  const [status, setStatus] = useState<boolean>(true);

  const init_query = {
    _id: '',
    title: '',
    description: '',
  };

  const [query, setQuery] = useState(init_query);

  const handleAdd = () => {
    if (status) {
      api
        .post('/admin/query/add', { ...query })
        .then((data) => {
          setOpen(false);
          queries.push(data);
          setQueries(queries);
          setQuery(init_query);
        })
        .catch(() => {});
    } else {
      api.post('/admin/query/edit', { ...query }).then((data) => {
        setQueries(
          queries.map((query: any) => {
            if (query._id === data._id) {
              return { ...data };
            }
            return { ...query };
          })
        );
        setOpen(false);
      });
    }
  };

  useEffect(() => {
    api.get('/admin/query').then((data) => {
      setQueries(data);
    });
  }, []);

  return (
    <>
      <div>
        <div className='flex'>
          <button
            className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
            onClick={() => {
              setStatus(true);
              setOpen(true);
              setQuery(init_query);
            }}
          >
            <Plus className='w-5' />
            Add Query
          </button>
        </div>
        <Input
          className='my-4'
          placeholder='Search the queries by the title...'
          onChange={() => {}}
        />
        <div className='overflow-x-auto'>
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
              Our queries
              <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
                Manage Queries
              </p>
            </caption>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='px-6 py-3'>
                  Title
                </th>
                <th scope='col' className='px-6 py-3'>
                  Description
                </th>
                <th scope='col' className='px-6 py-3'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {queries.map((query: any, index: number) => {
                return (
                  <tr
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                    key={index}
                  >
                    <th className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                      {query.title}
                    </th>
                    <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                      {query.description}
                    </td>
                    <td className='px-6 py-4 text-left'>
                      <button
                        onClick={() => {
                          setOpen(true);
                          setStatus(false);
                          setQuery(query);
                        }}
                        className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300'
                      >
                        Edit
                      </button>
                      <button
                        className='bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300'
                        onClick={() => {
                          api
                            .post('/admin/query/delete', { id: query._id })
                            .then(() => {
                              setQueries(
                                queries.filter(
                                  (item: any) => item._id !== query._id
                                )
                              );
                            });
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>{status ? 'Add' : 'Edit'} Query</DialogTitle>
            <DialogDescription>Please input the query</DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='title'>Title</Label>
              <Input
                value={query.title}
                onChange={(e) =>
                  setQuery((prev) => ({ ...prev, title: e.target.value }))
                }
                id='title'
                placeholder='Please enter the title'
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='description'>Description</Label>
              <textarea
                className='mt-5 flex w-full rounded-md border border-neutral-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-400 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:bg-neutral-950 dark:ring-offset-neutral-950 dark:placeholder:text-neutral-400 dark:focus-visible:ring-neutral-800'
                required
                rows={5}
                value={query.description}
                onChange={(event) => {
                  setQuery({ ...query, description: event.target.value });
                }}
                id='description'
                placeholder='Please enter the description of the query.'
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              onClick={handleAdd}
              disabled={!query.title && !query.description}
              type='submit'
            >
              {status ? 'Add' : 'Update'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
