import React, { useEffect, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import {
  AccordionTrigger,
  AccordionContent,
} from '../../../components/ui/accordion';
import api from '../../../lib/api';

export default function ViewFaqs() {
  const [queries, setQueries] = useState<any>([
    {
      _id: '355',
      title: 'How to manage the API key for the organization?',
      description: 'To add the api key please here the contact',
    },
  ]);

  useEffect(() => {
    api.get('/user/query').then((data) => {
      setQueries(data);
    });
  }, []);

  return (
    <div className='w-full'>
      <div className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800 w-full'>
        Our FAQs
        <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
          Explore the all faqs of our organization.
        </p>
      </div>
      <Accordion.Root
        className='AccordionRoot'
        type='single'
        defaultValue='item-1'
        collapsible
      >
        {queries.map((query: any) => {
          return (
            <Accordion.Item value={`item-${query._id}`} key={query._id}>
              <AccordionTrigger>{query.title}</AccordionTrigger>
              <AccordionContent>{query.description}</AccordionContent>
            </Accordion.Item>
          );
        })}
      </Accordion.Root>
    </div>
  );
}
