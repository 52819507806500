import React, { useEffect, useState } from 'react';
import { Plus } from 'lucide-react';
import { useToast } from '../../../components/ui/use-toast';
import * as Switch from '@radix-ui/react-switch';

import api from '../../../lib/api';

export default function ManageApiKeys() {
  const [keys, setKeys] = useState<any>([]);

  const { toast } = useToast();

  useEffect(() => {
    api.get('/admin/getApiKeys').then((data) => {
      setKeys(data);
    });
  }, []);

  const handleAdd = () => {
    api
      .post('/admin/addApiKey', {})
      .then((data) => {
        setKeys(data);
      })
      .catch((error) => toast({ title: 'Something is went wrong!' }));
  };

  const updatePermission = (
    key: string,
    target: 'update' | 'delete' | 'read'
  ) => {
    api
      .post('/admin/apikey/updatePermission', { key, target })
      .then((data) => {
        setKeys(data);
      })
      .catch(() => {
        toast({ title: 'Something is went wrong!' });
      });
  };

  const deletePermission = (key: string) => {
    api
      .post('/admin/apikey/delete', { key })
      .then((data) => {
        setKeys(data);
      })
      .catch(() => {
        toast({ title: 'Something is went wrong!' });
      });
  };

  return (
    <>
      <div>
        <div className='flex'>
          <button
            className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
            onClick={() => {
              handleAdd();
            }}
          >
            <Plus className='w-5' />
            Add API Key (By Randomly)
          </button>
        </div>

        <div className='overflow-x-auto'>
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
              API Keys
              <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
                You can add and manage the permissions of each api keys.
              </p>
            </caption>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='px-6 py-3'>
                  API Key
                </th>
                <th scope='col' className='px-6 py-3'>
                  Read
                </th>
                <th scope='col' className='px-6 py-3'>
                  Delete
                </th>
                <th scope='col' className='px-6 py-3'>
                  Update/Insert
                </th>
                <th scope='col' className='px-6 py-3'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {keys.map((key: any, index: number) => {
                return (
                  <tr
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                    key={index}
                  >
                    <th
                      scope='row'
                      className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                    >
                      {key.key}
                    </th>
                    <td className='px-6 py-4'>
                      <Switch.Root
                        className='SwitchRoot bg-gray-200'
                        id='airplane-mode'
                        checked={key.read}
                        onCheckedChange={(value) =>
                          updatePermission(key.key, 'read')
                        }
                      >
                        <Switch.Thumb className='SwitchThumb' />
                      </Switch.Root>
                    </td>
                    <td className='px-6 py-4'>
                      <Switch.Root
                        className='SwitchRoot bg-gray-200'
                        id='airplane-mode'
                        checked={key.delete}
                        onCheckedChange={(value) =>
                          updatePermission(key.key, 'delete')
                        }
                      >
                        <Switch.Thumb className='SwitchThumb' />
                      </Switch.Root>
                    </td>
                    <td className='px-6 py-4'>
                      <Switch.Root
                        className='SwitchRoot bg-gray-200'
                        id='airplane-mode'
                        checked={key.update}
                        onCheckedChange={(value) =>
                          updatePermission(key.key, 'update')
                        }
                      >
                        <Switch.Thumb className='SwitchThumb' />
                      </Switch.Root>
                    </td>
                    <td className='px-6 py-4 text-left'>
                      <button
                        onClick={() => {
                          deletePermission(key.key);
                        }}
                        className='bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300'
                      >
                        Delete API Key
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
