import React, { useEffect, useState } from 'react';
import api from '../../../lib/api';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Plus, ImportIcon, Download } from 'lucide-react';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { useToast } from '../../../components/ui/use-toast';
import {
  
  User,
} from 'lucide-react';
export default function Faq() {
  const [users, setUsers] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterOption, setFilterOption] = useState('all');
  const [userId , setUserid] = useState()
  const [faq, setFaq] = useState({ category: '', question: '' });
  const [faqId , setFaqId] = useState<any>();


  const { toast } = useToast();
 


  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [filteredUsers , setFilteredUsers] = useState<any>([]);



 useEffect(() => {

   
    api.get('/auth/getUser')
      .then((response) => {
        // Check if the response contains the expected user ID property
        if (response && response._id) {
          setUserid(response._id); // Set user ID
        } else {
          console.error('Unexpected response format for user:', response);
        }
      })
      .catch((error) => {
        console.error('Failed to fetch user:', error);
      });

     
  }, []);

  useEffect(() => {
    fetchData(); 
  }, []);
  
 
  

  const fetchData=()=>{
    api.get('/superadmin/getFaqs')
    .then((response) => {
      if (Array.isArray(response)) {
        setUsers(response);
        setFilteredUsers(response) // Set users directly since the data is not wrapped
        console.log(response); // Check the response data
      } else {
        console.error('Unexpected response format for FAQs:', response);
      }
    })
    .catch((error) => {
      console.error('Failed to fetch FAQs:', error);
    });
  }
  

  const handleAddFaq = () => {
    const newFaq = { ...faq, userId }; // Include the userId in the FAQ object
    api.post('/superadmin/createFaq', newFaq)
      .then((res) => {
        setFaq({ category: '', question: '' });
        setOpen(false);
        fetchData();
        toast({
          title: 'FAQ Added',
          description: 'New FAQ has been added successfully!',
          
        });
      })
      .catch((error) => {
        console.error('Failed to add FAQ:', error);
        toast({
          title: 'Failed to Add FAQ',
          description: 'An error occurred while adding the FAQ.',
         
        });
      });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filteredUsers = users.filter((user: any) =>
      user.question.toLowerCase().includes(query)
    );
    setFilteredUsers(filteredUsers);
  };
  
  const handleEditQuestion = (id:any) => {
    const selectedFaq = users.find((faq:any) => faq._id === id);
    if (selectedFaq) {
      setFaq(selectedFaq);
      setFaqId(selectedFaq._id);
      setOpen1(true);
    } else {
      console.error('FAQ not found');
    }
  };

  const handleUpdateFaq = async () => {
    try {
      const res = await api.put(`/superadmin/updateFaq/${faqId}`, faq);
      setFaq({ category: '', question: '' });
      setOpen1(false);
      fetchData();
      toast({
        title: 'FAQ Updated',
        description: 'FAQ has been updated successfully!',
      });
     
    } catch (error) {
      console.error('Failed to update FAQ:', error);
      toast({
        title: 'Failed to Update FAQ',
        description: 'An error occurred while updating the FAQ.',
      });
    }
  };


  const handleDeleteQuestion = async (id: any) => {
    try {
      await api.delete('/superadmin/deleteFaq', { id }); // Pass the ID in the request body
      fetchData(); // Fetch data again after successful deletion
      toast({
        title: 'FAQ Deleted',
        description: 'FAQ has been deleted successfully!',
      });
    } catch (error) {
      console.error('Failed to delete FAQ:', error);
      toast({
        title: 'Failed to Delete FAQ',
        description: 'An error occurred while deleting the FAQ.',
      });
    }
  };
  
 



  return (
    <>
      <div>
        <div className='flex'>
          <button
            className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
            onClick={() => {
              setOpen(true);
            }}
          >
            <Plus className='w-5' />
            Add Faq
          </button>

          


        </div>
        <Input className='my-4' placeholder='Search the Question...'           onChange={handleSearch}
/>
        <div className='overflow-x-auto'>
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
              Our members
              <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
                Browse a list of Flowbite members designed to help you work and
                play, stay organized, get answers, keep in touch, grow your
                business, and more.
              </p>
            </caption>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='px-6 py-3'>
                Questions
                </th>
              
                
                <th scope='col' className='px-6 py-3'>
                  Date
                </th>
                <th scope='col' className='px-6 py-3'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
  {filteredUsers.map((user: any, index: number) => {
    return (
      <tr
        className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
        key={index}
      >
        <th
          scope='row'
          className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
        >
          {user.question}
        </th>
        
        <td className='px-6 py-4'>{new Date(user.createdAt).toLocaleDateString('en-GB')}</td>

        <td className='px-6 py-4'>
          <span onClick={() => handleEditQuestion(user._id)}>Edit</span> / <span onClick={() => handleDeleteQuestion(user._id)}>Delete</span>
        </td>
      </tr>
    );
  })}
</tbody>

          
          </table>
        </div>
        <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>Add  Faq</DialogTitle>
            <DialogDescription>Please input the Faq detail</DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
           
    
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='email'> Your Question</Label>
             <Input
  value={faq.question}
  onChange={(e) => setFaq({ ...faq, question: e.target.value })}
  id='email'
  placeholder='Question'
/>

            </div>
          
           
          </div>
          <DialogFooter>
            <Button
             onClick={handleAddFaq}
              type='submit'
            >
              Add Faq
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={open1} onOpenChange={(val) => setOpen1(val)}>
      <DialogContent className='sm:max-w-[495px]'>
            <DialogHeader>
              <DialogTitle>Update FAQ</DialogTitle>
              <DialogDescription>Please update the FAQ detail</DialogDescription>
            </DialogHeader>
            <div className='grid gap-4 py-4'>
              <div className='flex flex-col gap-3 space-y-1.5'>
                <Label htmlFor='editQuestion'>Your Question</Label>
                <Input
                  value={faq.question}
                  onChange={(e) => setFaq({ ...faq, question: e.target.value })}
                  id='editQuestion'
                  placeholder='Question'
                />
              </div>
            </div>
            <DialogFooter>
              <Button onClick={handleUpdateFaq} type='submit'>Update FAQ</Button>
            </DialogFooter>
          </DialogContent>
      </Dialog>
      </div>
    
   

    </>
  );
}