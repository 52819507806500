import React, { useEffect, useState } from "react";
import api from "../../../lib/api";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Plus, ImportIcon, Download, UserPlus  , Star , Trash2 , Pencil} from "lucide-react";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import { useToast } from "../../../components/ui/use-toast";


interface DropdownStyle {
  position: 'absolute';
  top: string;
  // Add other style properties if needed
}

type UserData = {
  _id: string ;
  productName: string;
  description?: string;
  price?: number;
  // Add more properties if needed
};

export default function MannageShop() {
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);

    const [prodName, setProdname] = useState<string>('');
    const [proddescription, setProddescription] = useState<string>('');
    const [prodimage, setProdimage] = useState<string>('');
    const [orgId , setOrgid] = useState<string | object>('');
    const [userId, setUserId] = useState<string>('');
    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
    const [prodId, setProdId] = useState<string>('');
    const [custumClass , setCustumClass] = useState('hidden');
    const [shopprod , setShopprod] =  useState<string>('');
    const [shopproddes , setShopproddes] = useState<string>('');
    const [shopprodprice , setShopprodprice] = useState<number | string>(0)
    const [filteredUsers, setFilteredUsers] = useState<any[]>([]);


    const [dropdownStyle, setDropdownStyle] = useState<DropdownStyle>({
      position: 'absolute',
      top: '85px',
    });

     const [prodprice, setProdprice] = useState<number | string>(0);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [data , setData] = useState<any>([])
    const [shopdata , setShopData] = useState<any>([])



    const handleFilterButtonClick = () => {
      const newTopPosition = window.innerWidth > 768 ? '85px' : '150px';
      setDropdownStyle({
          position: 'absolute', 
          top: newTopPosition,
        });
      setIsFilterDropdownOpen(!isFilterDropdownOpen);
    };



    useEffect(() => {
      const fetchData = async () => {
        try {
          const orgRes = await api.get(`/auth/getUser`);
          console.log("orgRes", orgRes);
          setOrgid(orgRes.organization._id);
          setUserId(orgRes._id);
    
          const userRes = await api.get(`/admin/getinventoryProduct?organizationId=${orgRes.organization._id}`);
          setData(userRes);
          setFilteredUsers(userRes);
        } catch (error) {
          console.error(error);
        }
      }

        const fetchUsers1 = async () => {
          try {
            const res = await api.get(`/admin/getshopProduct`);
            setShopData(res)
          } catch (error) {
            console.error(error);
            // Handle error if needed
          }
        };
    
        // const fetchOrgId = async () => {
        //   try {
        //     const res = await api.get(`/auth/getUser`);
        //     setOrgid(res.organization._id);
        //     setUserId(res._id);
        //   } catch (error) {
        //     console.error(error);
        //   }
        // };
    
       // fetchUsers();
       fetchData();
       fetchUsers1();
      }, []);


  
const AddProduct = () => {
  if (!prodName || !proddescription || !prodprice) {
    return;
  }

  const payload = {
    productName : prodName,
    organizationId : orgId,
    description : proddescription,
    sale : true,
    price : prodprice,
    category : 'sport',
    userId : userId
  };

  api.post('/admin/createshopProduct', payload)
    .then(async (response) => {
      console.log('Product added successfully:', response);

      try {
        const res = await api.get(`/admin/getinventoryProduct?organizationId=${orgId}`);
        const res1 = await api.get(`/admin/getshopProduct`);
        setData(res);
        setShopData(res1)
      } catch (error) {
        console.error('Error fetching updated data:', error);
      }

      setOpen(false);
      setProdname('');
      setProddescription('');
      setProdprice(0);
      setProdimage('');
      setSelectedImage(null);
    })
    .catch((error) => {
      console.error('Error adding product:', error);
      // Handle error if needed
    });
};

  
   

      const HandelAddtoSell=(item : any)=>{
        setProdname(item.productName);
        setProddescription(item.description);
        setProdprice(item.price); 
               setOpen(true)

      }


      const handleEdit = (product: any) => {
        setProdname(product.productName);
        setProddescription(product.description);
        setProdprice(product.price);
         setProdId(product._id)
    
        setOpen1(true);
    };


    const handleEditone = (product: any) => {
      setProdname(product.productName);
      setProddescription(product.description);
      setProdprice(product.price);
       setProdId(product._id)
  
      setOpen1(true);
  };

    const toggleClass = () => {
      setCustumClass((prevClass) => (prevClass === 'hidden' ? '' : 'hidden'));
    };
    
     
    
     const handleEditSubmit = async () => {
        const payload = { 
              _id : prodId ,
            productName: prodName,
            description: proddescription,
            price: prodprice.toString(),
            organizationId: orgId,
            userId: userId,
            category: 'shop',
        } 
        const response = await api.put("/admin/updateshopProduct", payload)
            .then(async (response) => {
          console.log('Product added successfully:', response);
        
          try {
            const res = await api.get(`/admin/getshopProduct`);
            setShopData(res);
            setOpen(false);
            setOpen1(false);
          } catch (error) {
            console.error('Error fetching updated data:', error);
          }
        })
          .catch((error) => {
            console.error('Error adding product:', error);
            // Handle error if needed
          });
      
      };


      const handleDelete = async (inventoryProductId: string |  undefined) => {
        console.log(inventoryProductId)
        try {
          const response = await api.delete("/admin/deleteshopProduct", {
            
            shopProductId: inventoryProductId
          });
      
          console.log("Product deleted successfully:", response);
      
          try {
            const res = await api.get(`/admin/getshopProduct`);
            setShopData(res);
          } catch (error) {
            console.error('Error fetching updated data:', error);
          }
        } catch (error) {
          console.error("Error deleting product:", error);
          // Handle error if needed
        }
      };

      const handleSearch = (searchText: string) => {
        const filtered = data.filter((user:any) =>
          user.productName && user.productName.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredUsers(filtered);
      };
    


  return (
    
     <>
  
   
  
   <section className="bg-gray-50 dark:bg-gray-900 w-100 flex items-center mb-5">
  <div className=" px-0 mx-auto  w-full">
    <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
      <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
        <div className="w-full md:w-1/2">
          <form className="flex items-center">
            <label htmlFor="simple-search" className="sr-only">Search</label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                </svg>
              </div>
              <input 
                            onChange={(e) => handleSearch(e.target.value)}             

              type="text" id="simple-search" className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search"  />
            </div>
          </form>
        </div>
        <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full  md:w-auto md:flex-row  md:items-center md:space-x-3">
       
          <div className="flex items-center w-full space-x-3 md:w-auto">
          
            <div id="actionsDropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
              <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="actionsDropdownButton">
                <li>
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Mass Edit</a>
                </li>
              </ul>
              <div className="py-1">
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete all</a>
              </div>
            </div>
            <button id="filterDropdownButton" data-dropdown-toggle="filterDropdown"
                 onClick={handleFilterButtonClick}

            className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="w-4 h-4 mr-2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd" />
              </svg>
              Filter
              <svg className="-mr-1 ml-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path clip-rule="evenodd" fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
            </button>
            <div  id="filterDropdown"
      className={`${
        isFilterDropdownOpen ? 'block' : 'hidden'
      } z-10 w-36 p-3 bg-white rounded-lg shadow dark:bg-gray-700`}
      style={dropdownStyle}>
              <h6 className="mb-3 text-sm font-medium text-gray-900 dark:text-white">
                Category
              </h6>
              <ul className="space-y-2 text-sm" aria-labelledby="dropdownDefault">
                <li className="flex items-center">
                  <input id="apple" type="checkbox" value=""
                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="apple" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                    Apple (56)
                  </label>
                </li>
                <li className="flex items-center">
                  <input id="fitbit" type="checkbox" value=""
                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="fitbit" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                    Fitbit (56)
                  </label>
                </li>
                <li className="flex items-center">
                  <input id="dell" type="checkbox" value=""
                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="dell" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                    Dell (56)
                  </label>
                </li>
                <li className="flex items-center">
                  <input id="asus" type="checkbox" value="" checked
                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="asus" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                    Asus (97)
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
     <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
Add Product to sell              
            </caption>
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                 Product Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((item: any, index: number) => {
                return (
                  <tr
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    key={index}
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {item.productName as string}
                    </th>
                    <td className="px-6 py-4">{item.price}</td>
                    <td className="px-6 py-4">{item.description}</td>

                   <td className="px-6 py-4">
                     <Button              onClick={() => HandelAddtoSell(item)}
 > Add to sell </Button>
                    </td>
                      {/*<td className="px-6 py-4">
                      {user.status === false ? (
                        <Button onClick={() => handleAccept(user._id)}>
                          Approve
                        </Button>
                      ) : (
                        ""
                      )}
                      { user.status === false ? 
                      <Button
                        className="mx-2"
                        onClick={() => handleReject(user._id)}
                      >
                        Reject
                      </Button> : ""
              }
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
  </div>
</section>
<h5>Products for sell</h5>

  <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 " style={{justifyContent:'center'}}>
{/*


  {filteredUsers.map((item: {
  [x: string]: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; 
  productName: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined; 
}, index: React.Key | null | undefined) => (
  <div key={index} className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <div className="px-5 pb-5">

    <div className="flex items-center justify-between"> 
            <div>   
            <h5 className="text-xl pt-2 font-semibold tracking-tight text-gray-900 dark:text-white">{item.productName as string} </h5>
            <p>{item.description} </p> 
          </div>
          <div>  <button
            className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
            onClick={() => HandelAddtoSell(item)}
          >
            <Plus className='w-5' />
            Add to sell
          </button></div>
        
          </div>

   
      {item.price !== undefined && (
        <div className="flex items-center justify-between">
          <span className="text-3xl font-bold text-gray-900 dark:text-white">${item.price as string}</span>
          <div className="flex items-center justify-between">
           
          </div>
        </div>
      )}
    </div>
  </div>
))}

*/}
















 
  </div>

  


<div id="accordion-collapse pt-4" style={{paddingTop:'30px' , paddingBottom:'20px'}} data-accordion="collapse">
  <h4 id="accordion-collapse-heading-1">
    <button type="button" onClick={toggleClass} className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
      <span>sell products</span>
      <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
      </svg>
    </button>
  </h4>
  <div id="accordion-collapse-body-1" className={custumClass} aria-labelledby="accordion-collapse-heading-1" style={{paddingTop:'23px'}}>
    <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 " style={{justifyContent:'center'}}>



  {shopdata.map((item: UserData, index: React.Key | null | undefined) =>(
  <div key={index} className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <div className="px-5 pb-5">

    <div className="flex items-center justify-between"> 
            <div>   
            <h5 className="text-xl pt-2 font-semibold tracking-tight text-gray-900 dark:text-white">{item.productName as string} </h5>
            <p>{item.description} </p> 
          </div>
         
        
          </div>

   
      {item.price !== undefined && (
        <div className="flex items-center justify-between">
          <span className="text-3xl font-bold text-gray-900 dark:text-white">${item.price as number}</span>
          <div className="flex items-center justify-between">
            {/* <span className="text-black " style={{ cursor: 'pointer' }}   onClick={() =>handleDelete (item._id)}  ><Trash2 /></span> */}
            <span className="text-black " style={{ cursor: 'pointer' }}   onClick={() =>handleDelete (item._id)}  ><Trash2 /></span>
            <span className="text-black mx-4" style={{ cursor: 'pointer' }} onClick={() => handleEditone(item)}>
              <Pencil />
            </span>
          </div>
        </div>
      )}
    </div>
  </div>
))}

















 
  </div>
  </div>
 
  
 
</div>




 
  <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>Add Product</DialogTitle>
            <DialogDescription>Please input the product detail</DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
          <div className='flex flex-col gap-3  space-y-1.5'>
              <Label > Product Name </Label>
              <Input
              value={prodName}
               onChange={(e)=>setProdname(e.target.value)}
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label >Price</Label>
              <Input
                 value={prodprice}
                 onChange={(e)=>setProdprice(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col gap-3 space-y-1.5'>
              <Label > Description</Label>
              <Input
                 value={proddescription}
                 onChange={(e)=>setProddescription(e.target.value)}
              />
            </div>
           
          </div>
          <DialogFooter>
            <Button
              type='submit'
              onClick={AddProduct}
            >
              Add Product
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
       <Dialog open={open1} onOpenChange={(val) => setOpen1(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>Edit Product Details</DialogTitle>
            <DialogDescription>Please input the product detail</DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
          <div className='flex flex-col gap-3  space-y-1.5'>
              <Label > Product Name </Label>
              <Input
              value={prodName}
               onChange={(e)=>setProdname(e.target.value)}
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label >Price</Label>
              <Input
                 value={prodprice}
                 onChange={(e)=>setProdprice(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col gap-3 space-y-1.5'>
              <Label > Description</Label>
              <Input
                 value={proddescription}
                 onChange={(e)=>setProddescription(e.target.value)}
              />
            </div>
           
          </div>
          <DialogFooter>
            <Button
              type='submit'
              onClick={handleEditSubmit}
            >
              Save Change
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
</>

    
  );
}
