import { Link } from 'react-router-dom';
import { SheetTrigger } from '../ui/sheet';
import {
  ShieldQuestionIcon,
  GraduationCap,
  AlarmCheck,
  MessageCircleMoreIcon,
  BookImageIcon,
  PersonStanding,
  HammerIcon,
  BadgeDollarSign,
  HandMetal,
  Accessibility,
  Coffee,
  UserPlus,
  LandPlot,
  ShoppingCart,
  Construction,
  File,
  ScanBarcode,
  BookMarkedIcon,
} from 'lucide-react';
import { Org_Category } from '../../types/user';

export default function UserMenu({ category }: { category: Org_Category }) {
  console.log(category)
  return (
    <>
      <SheetTrigger asChild>
        <Link
          to='/dashboard/read_faqs'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <ShieldQuestionIcon className='mr-2 h-5 w-5' />
          <span>View Faqs</span>
        </Link>
      </SheetTrigger>
      <SheetTrigger asChild>
        <Link
          to='/dashboard/expore_careers'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <GraduationCap className='mr-2 h-5 w-5' />
          <span>Explore Careers</span>
        </Link>
      </SheetTrigger>
      <SheetTrigger asChild>
        <Link
          to='/dashboard/view_events'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <AlarmCheck className='mr-2 h-5 w-5' />
          <span>View Event</span>
        </Link>
      </SheetTrigger>
      <SheetTrigger asChild>
        <Link
          to='/dashboard/message'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <MessageCircleMoreIcon className='mr-2 h-5 w-5' />
          <span>Chat with Members</span>
        </Link>
      </SheetTrigger>
  
      {category === 'social' && (
        <>
          <SheetTrigger asChild>
            <Link
              to='/dashboard/view_gallery'
              className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
            >
              <BookImageIcon className='mr-2 h-5 w-5' />
              <span>View Photo Gallery</span>
            </Link>
          </SheetTrigger>
        </>
      )}
       {category === 'communal' && (
        <>
          <SheetTrigger asChild>
            <Link
              to='/dashboard/mannage_maintenance'
              className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
            >
              <  Construction 
 className='mr-2 h-5 w-5' />
              <span>Maintenance Request</span>
            </Link>
          </SheetTrigger>

          <SheetTrigger asChild>
            <Link
              to='/dashboard/manage_booking'
              className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
            >
              <  BookMarkedIcon 
 className='mr-2 h-5 w-5' />
              <span>Add Booking </span>
            </Link>
          </SheetTrigger>
          <SheetTrigger asChild>
            <Link
              to='/dashboard/manage_comunity'
              className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
            >
              <  File 
 className='mr-2 h-5 w-5' />
              <span>Community Documents</span>
            </Link>
          </SheetTrigger>
          <SheetTrigger asChild>
            <Link
              to='/dashboard/manage_payment'
              className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
            >
              <  ScanBarcode 
 className='mr-2 h-5 w-5' />
              <span>Payment</span>
            </Link>
          </SheetTrigger>
        </>
      )}
  
      {category === 'religion' && (
        <>
          <SheetTrigger asChild>
            <Link
              to='/dashboard/manage_religion_groups'
              className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
            >
              <PersonStanding className='mr-2 h-5 w-5' />
              <span>Manage Groups</span>
            </Link>
          </SheetTrigger>
          <SheetTrigger asChild>
            <Link
              to='/dashboard/manage_volunteer_activities'
              className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
            >
              <HammerIcon className='mr-2 h-5 w-5' />
              <span>Manage Volunteer Activities</span>
            </Link>
          </SheetTrigger>
          <SheetTrigger asChild>
            <Link
              to='/dashboard/manage_finance'
              className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
            >
              <BadgeDollarSign className='mr-2 h-5 w-5' />
              <span>Manage Finance Contributions</span>
            </Link>
          </SheetTrigger>
          <SheetTrigger asChild>
            <Link
              to='/dashboard/manage_prayer'
              className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
            >
              <HandMetal className='mr-2 h-5 w-5' />
              <span>Manage Prayer Request</span>
            </Link>
          </SheetTrigger>
        </>
      )}
  
      {category === 'political' && (
        <>
          <SheetTrigger asChild>
            <Link
              to='/dashboard/manage_religion_groups'
              className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
            >
              <PersonStanding className='mr-2 h-5 w-5' />
              <span>Manage Groups</span>
            </Link>
          </SheetTrigger>
          <SheetTrigger asChild>
            <Link
              to='/dashboard/manage_volunteer_activities'
              className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
            >
              <HammerIcon className='mr-2 h-5 w-5' />
              <span>Manage Volunteer Activities</span>
            </Link>
          </SheetTrigger>
          <SheetTrigger asChild>
            <Link
              to='/dashboard/viewnomination'
              className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
            >
              <HammerIcon className='mr-2 h-5 w-5' />
              <span>Nominations</span>
            </Link>
          </SheetTrigger>
        </>
      )}
  
  { category  === "sports" && (
  <>
    <SheetTrigger asChild>
      <Link
        to='/dashboard/manageteabooking'
        className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
      >
        <Coffee className='mr-2 h-5 w-5' />
        <span>Tea Time </span>
      </Link>
    </SheetTrigger>
    <SheetTrigger asChild>
      <Link
        to='/dashboard/handicapped'
        className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
      >
        <Accessibility className='mr-2 h-5 w-5' />
        <span> Handicapped user </span>
      </Link>
    </SheetTrigger>
    <SheetTrigger asChild>
      <Link
        to='/dashboard/ManagePass'
        className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
      >
        <UserPlus className='mr-2 h-5 w-5' />
        <span> Create Guest pass </span>
      </Link>
    </SheetTrigger>
    <SheetTrigger asChild>
      <Link
        to='/dashboard/viewgolf'
        className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
      >
        <LandPlot className='mr-2 h-5 w-5' />
        <span> Golf Lesson </span>
      </Link>
    </SheetTrigger>
    <SheetTrigger asChild>
      <Link
        to='dashboard/products'
        className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
      >
        <ShoppingCart className='mr-2 h-5 w-5' />
        <span> Products </span>
      </Link>
    </SheetTrigger>
  </>
)}
    </>
  );
  
  }