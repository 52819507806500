import React from 'react';

export default function PricePlans() {
  return (
    <div className='min-h-screen md:px-0 px-6 w-full'>
      <section className='flex items-center lg:h-screen font-poppins max-w-[1500px] mx-auto'>
        <div className='justify-center flex-1 px-4 py-4 mx-auto lg:py-0 md:px-6'>
          <h2 className='mb-4 text-3xl font-bold text-center text-gray-800 md:text-5xl dark:text-gray-400'>
            Business Plan for bright future
          </h2>
          <p className='mb-6 text-lg font-medium text-center text-gray-500 md:text-xl dark:text-gray-400'>
            Pay with any platforms
          </p>
          <div className='flex flex-wrap justify-center pt-8 -mx-3'>
            <div className='w-full px-3 mb-14 lg:px-2 md:w-1/2 lg:w-1/3 '>
              <div className='flex flex-col border-b-4 border-blue-400 shadow-md rounded-2xl dark:border-gray-800 dark:bg-gray-800 bg-gray-50'>
                <div className='relative flex justify-center'>
                  <div className='absolute top-0 z-10 flex items-center justify-center -mt-7 '>
                    <h2 className='px-8 py-2 text-xl font-semibold tracking-wide text-center text-gray-200 bg-blue-800 rounded-full lg:text-xl dark:bg-gray-700 dark:text-gray-300'>
                      Basic
                    </h2>
                  </div>
                </div>
                <div className='flex items-center justify-between px-4 pb-2 border-b border-blue-300 dark:border-gray-600 pt-7'>
                  <div className='flex items-center dark:text-gray-400'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='w-6 h-6 mr-2 text-blue-700 dark:text-gray-400 bi bi-people-fill'
                      viewBox='0 0 16 16'
                    >
                      <path d='M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z'></path>
                      <path
                        fill-rule='evenodd'
                        d='M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z'
                      ></path>
                      <path d='M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z'></path>
                    </svg>
                    <span className='mr-2 text-2xl font-semibold'>06 </span>
                    Users
                  </div>
                  <div className='text-2xl font-bold dark:text-gray-300'>
                    <div className=''>
                      <span className='text-2xl font-medium text-gray-700 dark:text-gray-300'>
                        $10
                      </span>
                      <span className='text-base font-medium text-gray-700 dark:text-gray-300'>
                        /month
                      </span>
                    </div>
                  </div>
                </div>
                <div className='self-center py-2'>
                  <ul className='mt-6 '>
                    <li className='flex items-center mb-3 font-medium text-gray-500 dark:text-gray-400'>
                      <a
                        href='#'
                        className='mr-2 text-blue-900 dark:text-gray-400'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-check-circle-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'></path>
                        </svg>
                      </a>
                      <span>Complete Code</span>
                    </li>
                    <li className='flex items-center mb-3 font-medium text-gray-500 dark:text-gray-400'>
                      <a
                        href='#'
                        className='mr-2 text-blue-900 dark:text-gray-400'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-check-circle-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'></path>
                        </svg>
                      </a>
                      <span>3 Emails</span>
                    </li>
                    <li className='flex items-center mb-3 font-medium text-gray-500 dark:text-gray-400'>
                      <a
                        href='#'
                        className='mr-2 text-blue-900 dark:text-gray-400'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-check-circle-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'></path>
                        </svg>
                      </a>
                      <span>Manage disc space</span>
                    </li>
                    <li className='flex items-center font-medium text-gray-500 dark:text-gray-400'>
                      <a
                        href='#'
                        className='mr-2 text-blue-900 dark:text-gray-400'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-check-circle-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'></path>
                        </svg>
                      </a>
                      <span>Unlimited Domains</span>
                    </li>
                  </ul>
                </div>
                <div className='p-4'>
                  <a
                    href='#'
                    className='inline-block w-full py-2 font-medium text-center text-blue-600 border border-blue-600 rounded-full hover:bg-blue-700 hover:text-gray-200 dark:border-gray-500 dark:hover:bg-gray-900 dark:hover:border-gray-900 dark:hover:text-gray-300 dark:text-gray-400'
                  >
                    Buy Now{' '}
                  </a>
                </div>
              </div>
            </div>
            <div className='w-full px-3 mb-14 lg:px-2 md:w-1/2 lg:w-1/3 '>
              <div className='flex flex-col bg-blue-100 border-b-4 border-blue-700 shadow-md dark:border-gray-800 rounded-2xl dark:bg-gray-800'>
                <div className='relative flex justify-center'>
                  <div className='absolute top-0 z-10 flex items-center justify-center -mt-7 '>
                    <h2 className='px-8 py-2 text-xl font-semibold tracking-wide text-center text-gray-200 bg-blue-800 rounded-full lg:text-xl dark:bg-gray-700 dark:text-gray-300'>
                      Premium
                    </h2>
                  </div>
                </div>
                <div className='flex items-center justify-between px-4 pb-2 border-b border-blue-300 pt-7 dark:border-gray-600'>
                  <div className='flex items-center dark:text-gray-400'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='w-6 h-6 mr-2 text-blue-700 dark:text-gray-400 bi bi-people-fill'
                      viewBox='0 0 16 16'
                    >
                      <path d='M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z'></path>
                      <path
                        fill-rule='evenodd'
                        d='M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z'
                      ></path>
                      <path d='M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z'></path>
                    </svg>
                    <span className='mr-2 text-2xl font-semibold'>25 </span>
                    Users
                  </div>
                  <div className=''>
                    <span className='text-2xl font-medium text-gray-700 dark:text-gray-300'>
                      $20
                    </span>
                    <span className='text-base font-medium text-gray-700 dark:text-gray-300'>
                      /month
                    </span>
                  </div>
                </div>
                <div className='self-center py-2'>
                  <ul className='mt-6 '>
                    <li className='flex items-center mb-3 font-medium text-gray-500 dark:text-gray-400'>
                      <a
                        href='#'
                        className='mr-2 text-blue-900 dark:text-gray-400'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-check-circle-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'></path>
                        </svg>
                      </a>
                      <span>Complete Code</span>
                    </li>
                    <li className='flex items-center mb-3 font-medium text-gray-500 dark:text-gray-400'>
                      <a
                        href='#'
                        className='mr-2 text-blue-900 dark:text-gray-400'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-check-circle-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'></path>
                        </svg>
                      </a>
                      <span>3 Emails</span>
                    </li>
                    <li className='flex items-center mb-3 font-medium text-gray-500 dark:text-gray-400'>
                      <a
                        href='#'
                        className='mr-2 text-blue-900 dark:text-gray-400'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-check-circle-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'></path>
                        </svg>
                      </a>
                      <span>Manage disc space</span>
                    </li>
                    <li className='flex items-center font-medium text-gray-500 dark:text-gray-400'>
                      <a
                        href='#'
                        className='mr-2 text-blue-900 dark:text-gray-400'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-check-circle-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'></path>
                        </svg>
                      </a>
                      <span>Unlimited Domains</span>
                    </li>
                  </ul>
                </div>
                <div className='p-4'>
                  <a
                    href='#'
                    className='inline-block w-full py-2 font-medium text-center text-blue-600 border border-blue-600 rounded-full hover:bg-blue-700 hover:text-gray-200 dark:border-gray-500 dark:hover:bg-gray-900 dark:hover:border-gray-900 dark:hover:text-gray-300 dark:text-gray-400'
                  >
                    Buy Now{' '}
                  </a>
                </div>
              </div>
            </div>
            <div className='w-full px-3 mb-14 lg:px-2 md:w-1/2 lg:w-1/3 '>
              <div className='flex flex-col border-b-4 border-blue-400 shadow-md rounded-2xl dark:border-gray-800 dark:bg-gray-800 bg-gray-50'>
                <div className='relative flex justify-center'>
                  <div className='absolute top-0 z-10 flex items-center justify-center -mt-7 '>
                    <h2 className='px-8 py-2 text-xl font-semibold tracking-wide text-center text-gray-200 bg-blue-800 rounded-full lg:text-xl dark:bg-gray-700 dark:text-gray-300'>
                      Ultimate
                    </h2>
                  </div>
                </div>
                <div className='flex items-center justify-between px-4 pb-2 border-b border-blue-300 pt-7 dark:border-gray-600'>
                  <div className='flex items-center dark:text-gray-400'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='w-6 h-6 mr-2 text-blue-700 dark:text-gray-400 bi bi-people-fill'
                      viewBox='0 0 16 16'
                    >
                      <path d='M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z'></path>
                      <path
                        fill-rule='evenodd'
                        d='M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z'
                      ></path>
                      <path d='M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z'></path>
                    </svg>
                    <span className='mr-2 text-2xl font-semibold'>45 </span>
                    Users
                  </div>
                  <div className=''>
                    <span className='text-2xl font-medium text-gray-700 dark:text-gray-300'>
                      $30
                    </span>
                    <span className='text-base font-medium text-gray-700 dark:text-gray-300'>
                      /month
                    </span>
                  </div>
                </div>
                <div className='self-center py-2'>
                  <ul className='mt-6 '>
                    <li className='flex items-center mb-3 font-medium text-gray-500 dark:text-gray-400'>
                      <a
                        href='#'
                        className='mr-2 text-blue-900 dark:text-gray-400'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-check-circle-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'></path>
                        </svg>
                      </a>
                      <span>Complete Code</span>
                    </li>
                    <li className='flex items-center mb-3 font-medium text-gray-500 dark:text-gray-400'>
                      <a
                        href='#'
                        className='mr-2 text-blue-900 dark:text-gray-400'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-check-circle-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'></path>
                        </svg>
                      </a>
                      <span>3 Emails</span>
                    </li>
                    <li className='flex items-center mb-3 font-medium text-gray-500 dark:text-gray-400'>
                      <a
                        href='#'
                        className='mr-2 text-blue-900 dark:text-gray-400'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-check-circle-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'></path>
                        </svg>
                      </a>
                      <span>Manage disc space</span>
                    </li>
                    <li className='flex items-center font-medium text-gray-500 dark:text-gray-400'>
                      <a
                        href='#'
                        className='mr-2 text-blue-900 dark:text-gray-400'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-check-circle-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'></path>
                        </svg>
                      </a>
                      <span>Unlimited Domains</span>
                    </li>
                  </ul>
                </div>
                <div className='p-4'>
                  <a
                    href='#'
                    className='inline-block w-full py-2 font-medium text-center text-blue-600 border border-blue-600 rounded-full hover:bg-blue-700 hover:text-gray-200 dark:border-gray-500 dark:hover:bg-gray-900 dark:hover:border-gray-900 dark:hover:text-gray-300 dark:text-gray-400'
                  >
                    Buy Now{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
