import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import api from '../../lib/api';
import { Button } from '../../components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { Link } from 'react-router-dom';
import { useToast } from '../../components/ui/use-toast';
import { useDispatch } from 'react-redux';
import { UserType } from '../../types/user';
import { login } from '../../redux/store/auth';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, provider , provider1 } from "./Config";
import Google__G__logo from '../../Google__G__logo.svg.png';
import facebook from '../../download.jpg';
import Apple from '../../siwa-logo-masked-circle@2x.png';

export default function Login() {
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
    rememberMe: false
  });
  const { toast } = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    const storedEmail = localStorage.getItem('rememberedEmail');
    if (storedEmail) {
      setInputs(prev => ({
        ...prev,
        email: storedEmail,
        rememberMe: true
      }));
    }
  }, []);

  function handleLogin() {
    if (inputs.rememberMe) {
      localStorage.setItem('rememberedEmail', inputs.email);
    } else {
      localStorage.removeItem('rememberedEmail');
    }
    const { email, password } = inputs;

    api
      .post('/auth/login', { email, password })
      .then((data) => {
        Cookies.set('user-token', data.cookie);
        dispatch(login(data.user as unknown as UserType));
        window.location.href = '/dashboard';
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return toast({
            title: 'Login unsuccessful',
            description: error.response.data.message,
          });
        }
        toast({
          title: 'Login unsuccessful',
          description:
            error.response.data.errors[0].path === 'password'
              ? 'Password must be at least 6 characters long'
              : 'Invalid email address',
        });
      });
  }

  const handleChange = (e:any) => {
    const { name, value, checked, type } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setInputs(prev => ({
      ...prev,
      [name]: newValue
    }));
  };

  const handleGoogleSignUp = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        console.log("Google Login successful", user);
        
        // Get the access token
        user.getIdToken().then((idToken) => {
          console.log("Access Token:", idToken);
          const { email, displayName } = user; // Destructure email and display name
          console.log("Access Token:", idToken);
          console.log("Email:", email);
  
          api.post('/auth/loginGoogle', { email })
            .then(response => {
              if (response.user) {
                      console.log("response:", response.token);
                      Cookies.set('user-token', response.token, { expires: 2 }); // Expires in 2 days

                window.location.href = '/dashboard';
              } else {
                console.error('Error calling backend API:', response.statusText);
              }
            })
            .catch(error => {
              console.error('Error calling backend API:', error);
            });
        });
      })
      .catch((error) => {
        console.error("Google Login failed", error);
      });
  };

  const handleFbSignup=()=>{
    signInWithPopup(auth, provider1)
    .then((result) => {
      const user = result.user;
      console.log("Google Login successful", user);
      
      // Get the access token
      user.getIdToken().then((idToken) => {
        console.log("Access Token:", idToken);
        const { email, displayName } = user; // Destructure email and display name
        console.log("Access Token:", idToken);
        console.log("Email:", email);

        api.post('/auth/loginGoogle', { email })
          .then(response => {
            if (response.user) {
                    console.log("response:", response.token);
                    Cookies.set('user-token', response.token, { expires: 2 }); // Expires in 2 days

              window.location.href = '/dashboard';
            } else {
              console.error('Error calling backend API:', response.statusText);
            }
          })
          .catch(error => {
            console.error('Error calling backend API:', error);
          });
      });
    })
    .catch((error) => {
      console.error("Google Login failed", error);
    });
  }

  const responseFacebook = (response:any) => {
    console.log(response);
  }

  return (
    <div className='flex h-screen flex-col items-center'>
      <Card className='mt-20 w-[380px] py-5 max-[900px]:mt-10 max-[400px]:w-[95%]'>
        <CardHeader>
          <CardTitle>Welcome back</CardTitle>
          <CardDescription>Login to your account.</CardDescription>
        </CardHeader>
        <CardContent>
          <form>
            <div className='mt-1 grid w-full items-center gap-4'>
              <div className='flex flex-col gap-3 space-y-1.5'>
                <Label htmlFor='email'>Email or username</Label>
                <Input
                  required
                  value={inputs.email}
                  onChange={handleChange}
                  name='email'
                  id='email'
                  placeholder='john.doe@example.com'
                />
              </div>
              <div className='mt-2 flex flex-col gap-3 space-y-1.5'>
                <Label htmlFor='password'>Password</Label>
                <Input
                  value={inputs.password}
                  onChange={handleChange}
                  required
                  type='password'
                  name='password'
                  id='password'
                />
              </div>
              <div className='mt-2 flex items-center'>
                <input
                  type='checkbox'
                  id='rememberMe'
                  name='rememberMe'
                  checked={inputs.rememberMe}
                  onChange={handleChange}
                />
                <label htmlFor='rememberMe' className='ml-2'>
                  Remember Me
                </label>
              </div>
            </div>
            <p className='my-2 text-sm text-right'>
              <Link to={'/reset_password'}>forgot password?</Link>
            </p>
          </form>
        </CardContent>
        <CardFooter className='mt-1 flex'>
          <Button
            onClick={handleLogin}
            variant='custom'
            className='w-full'
            disabled={!inputs.email || !inputs.password}
          >
            Login
          </Button>
        </CardFooter>
        <p style={{textAlign:'center'}}>Or Login with</p>
        <CardFooter className='mt-1 flex'>
          <div style={{display:'flex' , justifyContent:'space-between' ,margin:'auto' }}>
            <div style={{padding:'10px' , cursor:'pointer'}}
              ><img 
              onClick={handleGoogleSignUp}
              src={Google__G__logo} 
              style={{height:'50px'}} 
            /></div>
            <div style={{padding:'10px' , cursor:'pointer'}}
              ><img src={facebook}  onClick={handleFbSignup} style={{height:'50px'}} 
            /></div>
         
            {/* <div style={{padding:'10px' , cursor:'pointer'}}
              ><img src={Apple} style={{height:'50px'}} 
            /></div> */}
          </div>
        </CardFooter>
      </Card>
      <span className='mt-6 text-sm'>
        Don't have an account?{' '}
        <Link className='text-neutral-400' to='/register'>
          Sign up
        </Link>
      </span>
    </div>
  );
}
