import React, { useEffect } from 'react';

import { Plus } from 'lucide-react';
import api from '../../../lib/api';
import moment from 'moment';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { DatePicker } from 'rsuite';

export default function ManageFinance() {
  const initialInput = {
    id: '',
    name: '',
    purpose: '',
    time: new Date(),
    amount: '',
  };

  const [records, setRecords] = React.useState<any[]>([]);

  const [input, setInput] = React.useState(initialInput);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    api.get('/user/finance').then((data) => {
      setRecords(data);
    });
  }, []);

  const handleAdd = () => {
    if (input.id !== '') {
      return api.post('/user/finance/edit', { ...input }).then((data) => {
        setRecords(data);
        setOpen(false);
      });
    }
    api.post('/user/finance/add', { ...input }).then((data) => {
      setRecords(data);
      setOpen(false);
    });
  };

  return (
    <div>
      <div className='flex'>
        <button
          className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
          onClick={() => {
            setInput(initialInput);
            setOpen(true);
          }}
        >
          <Plus className='w-5' />
          Create Record
        </button>
      </div>
      <div className='overflow-x-auto'>
        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
          <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
            Our Contribution Event
            <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
              Browse a list of contribution event.
            </p>
          </caption>
          <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th className='px-6 py-3'>Name</th>
              <th className='px-6 py-3'>Purpose</th>
              <th className='px-6 py-3'>Date</th>
              <th className='px-6 py-3'>Amount</th>
              <th className='px-6 py-3'>Action</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record: any, index: number) => {
              return (
                <tr
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  key={index}
                >
                  <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                    {record.name}
                  </td>
                  <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                    {record.purpose}
                  </td>
                  <td className='px-6 py-4'>
                    {moment(record.time).format('YYYY-MM-DD')}
                  </td>
                  <td className='px-6 py-4'>{record.amounts}</td>
                  <td className='px-6 py-4'>
                    <button
                      onClick={() => {
                        setInput({
                          id: record._id,
                          purpose: record.purpose,
                          name: record.name,
                          time: new Date(record.time),
                          amount: record.amounts,
                        });
                        setOpen(true);
                      }}
                      className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300'
                    >
                      Edit
                    </button>
                    <button
                      className='bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300'
                      onClick={() => {
                        api
                          .post('/user/finance/delete', { id: record._id })
                          .then((data) => {
                            setRecords(data);
                          });
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>{input.id ? 'Edit' : 'Add'} Record</DialogTitle>
            <DialogDescription>
              Please input the contribution event.
            </DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='activity_name'>Contributor Name</Label>
              <Input
                value={input.name}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, name: e.target.value }))
                }
                id='activity_name'
                placeholder='Please input activity name.'
              />
            </div>

            <div className=''>
              <Label>Date</Label>
              <DatePicker
                format='yyyy-MM-dd'
                className='w-full mt-4'
                value={input.time}
                onChange={(date) => {
                  setInput({ ...input, time: date! });
                }}
                showMeridian
              />
            </div>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='activity_description'>Amounts</Label>
              <Input
                value={input.amount}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, amount: e.target.value }))
                }
                id='activity_description'
                placeholder='Please enter the description of the activity.'
              />
            </div>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='activity_location'>Purpose</Label>
              <Input
                value={input.purpose}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, purpose: e.target.value }))
                }
                id='activity_location'
                placeholder='Please enter the location of the activity.'
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              onClick={handleAdd}
              disabled={
                !input.name || !input.amount || !input.time || !input.purpose
              }
              type='submit'
            >
              {input.id ? 'Update' : 'Add'} Record
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
