import React, { useEffect } from 'react';

import { Plus } from 'lucide-react';
import api from '../../../lib/api';
import moment from 'moment';

export default function ManageVolunteerActivities() {
  const [activities, setActivities] = React.useState<any[]>([]);

  useEffect(() => {
    api.get('/user/activity').then((data) => {
      setActivities(data);
    });
  }, []);

  return (
    <div>
      <div className='overflow-x-auto'>
        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
          <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
            Our activities
            <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
              Browse a list of activities.
            </p>
          </caption>
          <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th className='px-6 py-3'>Name</th>
              <th className='px-6 py-3'>Description</th>
              <th className='px-6 py-3'>Date and Time</th>
              <th className='px-6 py-3'>Location</th>
              <th className='px-6 py-3'>Status</th>
            </tr>
          </thead>
          <tbody>
            {activities.map((activity: any, index: number) => {
              return (
                <tr
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  key={index}
                >
                  <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                    {activity.name}
                  </td>
                  <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                    {activity.description}
                  </td>
                  <td className='px-6 py-4'>
                    {moment(activity.time).format('YYYY-MM-DD HH:mm')}
                  </td>
                  <td className='px-6 py-4'>{activity.location}</td>
                  <td className='px-6 py-4'>{activity.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
