import React, { useEffect, useState } from 'react';
import api from '../../../lib/api';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Plus, ImportIcon, Download } from 'lucide-react';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { useToast } from '../../../components/ui/use-toast';


export default function ViewGolf() {
  const [users, setUsers] = useState<any[]>([]);
  const [orgid, setOrgid] = useState<string>('');
  const [userid, setUserid] = useState<string>('');
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch organization ID and user ID
        const orgRes = await api.get(`/auth/getUser`);
        setOrgid(orgRes.organization._id);
        setUserid(orgRes._id);
  
        // Fetch golf lessons for a specific user
        const res = await api.get(`/user/getGolfLesson`);
        setUsers(res);
        setFilteredUsers(res);
      } catch (error) {
        console.error(error);
        // Handle error if needed
      }
    };
  
    fetchData();
  }, []);

  

 



 
  return (
    <>
      <div>
        <div className='flex'>
          

        

         
        </div>
    
        <div className='overflow-x-auto'>
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
              Our members
              <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
                Browse a list of Flowbite members designed to help you work and
                play, stay organized, get answers, keep in touch, grow your
                business, and more.
              </p>
            </caption>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='px-6 py-3'>
                  Name
                </th>
                <th scope='col' className='px-6 py-3'>
                  Description
                </th>
                {/* <th scope='col' className='px-6 py-3'>
                  Status
                </th> */}

               
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user: any, index: number) => {
                return (
                  <tr
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                    key={index}
                  >
                    <th
                      scope='row'
                      className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                    >
                      {user.name}
                    </th>
                    <td className='px-6 py-4'>{user.description
}</td>
                    {/* <td className='px-6 py-4'>
                                           {user.status === false ? 'Pending' : 'Accept'}

                    </td> */}

                    {/* <td className='px-6 py-4'>{user.role.toUpperCase()}</td> */}
                    <td className='px-6 py-4 text-left'>
                      {/* <button
                        onClick={() => {
                          api
                            .post('/admin/user/activeUser', {
                              user_id: user._id,
                            })
                            .then(() => {
                              setUsers(
                                users.map((item: any) => {
                                  if (item._id === user._id) {
                                    item.status =
                                      item.status === 'unactive'
                                        ? 'active'
                                        : 'unactive';
                                  }
                                  return item;
                                })
                              );
                            });
                        }}
                        className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300'
                      >
                        {user.status === 'unactive' ? 'Active' : 'Unactive'}
                      </button> */}
                      {/* <button
                        className='bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300'
                        onClick={() => {
                          api
                            .post('/admin/user/delete', { id: user._id })
                            .then(() => {
                              setUsers(
                                users.filter(
                                  (item: any) => item._id !== user._id
                                )
                              );
                            });
                        }}
                      >
                        Delete
                      </button> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
 
    </>
  );
}
