import React, { useEffect, useState } from 'react';
import { Calendar, Whisper, Badge } from 'rsuite';
import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';
import moment from 'moment';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../components/ui/dialog';

import { DatePicker } from 'rsuite';

import api from '../../../lib/api';

type Event = {
  _id: string;
  title: string;
  description: string;
  date: string;
};

const Overlay = React.forwardRef(
  ({ style, onClose, children, ...rest }: any, ref) => {
    const styles = {
      ...style,
      color: '#000',
      background: '#fff',
      width: 200,
      padding: 10,
      borderRadius: 4,
      position: 'absolute',
      border: '1px solid #ddd',
      boxShadow: '0 3px 6px -2px rgba(0, 0, 0, 0.6)',
      zIndex: 50,
    };

    return (
      <div {...rest} style={styles} ref={ref}>
        {children}
      </div>
    );
  }
);

export default function ManageEvent() {
  const [inputs, setInputs] = useState<any>({
    title: '',
    description: '',
    time: null,
    date: new Date(),
  });

  const [open, setOpen] = useState(false);

  const [currentEvent, setCurrentEvent] = useState<Event | null>(null);

  const [eventList, setEventList] = useState<Event[]>([]);

  const handleDeleteEvent = () => {
    api.post('/admin/event/delete', { id: currentEvent?._id }).then((data) => {
      setEventList(data);
      setOpen(false);
    });
  };

  useEffect(() => {
    api.get('/admin/event/getEvent').then((data) => {
      setEventList(data);
    });
  }, []);

  function renderCell(date: Date) {
    const list = eventList
      .filter((item) => {
        return (
          moment(item.date).format('YYYY-MM_DD') ===
          moment(date).format('YYYY-MM_DD')
        );
      })
      .map((item) => ({
        ...item,
        time: moment(item.date).format('HH:mm'),
      }));
    const displayList = list.filter((_, index) => index < 2);

    if (list.length) {
      const moreCount = list.length - displayList.length;
      const moreItem = (
        <li>
          <Whisper
            placement='top'
            trigger='click'
            speaker={(props, ref) => {
              const { className, left, top, onClose } = props;
              return (
                <Overlay
                  style={{ left, top }}
                  onClose={onClose}
                  className={className}
                  ref={ref}
                >
                  {list.map((item, index) => (
                    <p
                      key={index}
                      className='cursor-pointer'
                      onClick={() => {
                        onClose();
                        setOpen(true);
                        setCurrentEvent(item);
                      }}
                    >
                      <b>{item.time}</b> - {item.title}
                    </p>
                  ))}
                </Overlay>
              );
            }}
          >
            <span className='cursor-pointer'>{moreCount} more</span>
          </Whisper>
        </li>
      );

      return (
        <ul className='calendar-todo-list'>
          {displayList.map((item, index) => (
            <li
              key={index}
              className='cursor-pointer hover:bg-yellow-300'
              onClick={() => {
                setOpen(true);
                setCurrentEvent(item);
              }}
            >
              <Badge /> <b>{item.time}</b> - {item.title}
            </li>
          ))}
          {moreCount ? moreItem : null}
        </ul>
      );
    }

    return null;
  }

  const addEvent = () => {
    if (!inputs.time && !inputs.date) {
      return;
    }

    const year = inputs.date.getFullYear();
    const month = String(inputs.date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(inputs.date.getDate()).padStart(2, '0');
    const hours = String(inputs.time.getHours()).padStart(2, '0');
    const minutes = String(inputs.time.getMinutes()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    api
      .post('/admin/event/addEvent', {
        title: inputs.title,
        description: inputs.description,
        date: new Date(formattedDate),
      })
      .then((data) => {
        setEventList(data);
      })
      .catch(() => {});
  };

  return (
    <>
      <Calendar
        bordered
        renderCell={renderCell}
        onSelect={(date) => {
          setInputs({ ...inputs, date });
        }}
      />
      <div className='mt-3 px-4'>
        <p className='text-gray-800 text-xl my-2'>
          Please make a new event here (before add event, please check if you
          select the date on calendar).
        </p>
        <div className='grid md:grid-cols-2 md:gap-x-10 grid-cols-1 gap-y-3'>
          <div className=''>
            <Label>Title</Label>
            <Input
              className='mt-4'
              placeholder='Please input title here...'
              value={inputs.title}
              onChange={(event) => {
                setInputs({ ...inputs, title: event.target.value });
              }}
            />
          </div>
          <div className=''>
            <Label>Time</Label>
            <DatePicker
              format='HH:mm'
              className='w-full mt-4'
              value={inputs.time}
              onChange={(date) => {
                setInputs({ ...inputs, time: date });
              }}
            />
          </div>
        </div>
        <div className='mt-3'>
          <Label>Description</Label>
          <textarea
            className='mt-5 flex w-full rounded-md border border-neutral-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-400 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:bg-neutral-950 dark:ring-offset-neutral-950 dark:placeholder:text-neutral-400 dark:focus-visible:ring-neutral-800'
            required
            rows={5}
            value={inputs.description}
            onChange={(event) => {
              setInputs({ ...inputs, description: event.target.value });
            }}
            id='description'
            placeholder='Please enter the description of the feedback.'
          />
        </div>
        <div className='w-full flex justify-end mt-4'>
          <button
            onClick={addEvent}
            className='disabled:cursor-not-allowed cursor-pointer text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
          >
            Add Event
          </button>
        </div>
      </div>
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>{currentEvent?.title}</DialogTitle>
            <DialogDescription>
              {moment(currentEvent?.date).format('YYYY-MM-DD HH:mm')}
            </DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='grid grid-cols-4 items-center gap-4'>
              {currentEvent?.description}
            </div>
          </div>
          <DialogFooter>
            <button
              onClick={handleDeleteEvent}
              className='disabled:cursor-not-allowed cursor-pointer text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
            >
              Delete
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
