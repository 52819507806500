import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';

import { Quote, History, ChevronUp } from 'lucide-react';

import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';
import api from '../../../lib/api';

export default function ChatSupport() {
  const [tab, setTab] = React.useState('tab1');

  const [inputs, setInputs] = React.useState({
    title: '',
    description: '',
  });

  const [fileBlob, setFileBlob] = React.useState<any>(null);

  const [supports, setSupports] = React.useState<any[]>([]);

  const [openedId, setOpenedId] = React.useState('');

  const handleSubmit = async () => {
    const uploadFile = !fileBlob
      ? null
      : await new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.readAsDataURL(fileBlob);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

    api
      .post('/admin/chat_support/submit', { ...inputs, uploadFile })
      .then((data) => {
        supports.push(data);
        setSupports(supports);
      })
      .catch((error) => {});
  };

  const uploadFile = (event: any) => {
    setFileBlob(event.target.files![0]);
  };

  React.useEffect(() => {
    api.get('/admin/chat_support/get').then((data) => {
      setSupports(data);
    });
  }, []);

  return (
    <div>
      You can chat here with our support team
      <Tabs.Root className='TabsRoot' defaultValue='tab1'>
        <Tabs.List
          className='inline-flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400'
          aria-label='Manage your account'
        >
          <Tabs.Trigger
            className={
              tab === 'tab1'
                ? 'inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
                : 'inline-flex p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
            }
            value='tab1'
            onClick={() => {
              setTab('tab1');
            }}
          >
            <Quote className='mr-2 w-4' />
            General Chat with Support
          </Tabs.Trigger>
          <Tabs.Trigger
            className={
              tab === 'tab2'
                ? 'inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
                : 'inline-flex p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
            }
            value='tab2'
            onClick={() => {
              setTab('tab2');
            }}
          >
            <History className='mr-2 w-4' />
            Chat History
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content className='TabsContent' value='tab1'>
          <div className='grid w-full items-center gap-4 mt-5'>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='title'>Title</Label>
              <Input
                required
                value={inputs.title}
                onChange={(e) =>
                  setInputs((prev) => ({ ...prev, title: e.target.value }))
                }
                id='title'
                placeholder='Please enter the title of the feedback.'
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='email'>Details</Label>
              <textarea
                className='flex w-full rounded-md border border-neutral-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-400 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:bg-neutral-950 dark:ring-offset-neutral-950 dark:placeholder:text-neutral-400 dark:focus-visible:ring-neutral-800'
                required
                rows={5}
                value={inputs.description}
                onChange={(e) =>
                  setInputs((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                id='description'
                placeholder='Please enter the description of the feedback.'
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='title'>Attachment</Label>
              <Input
                required
                id='attach'
                type='file'
                placeholder='Please choose the file to attach.'
                onChange={uploadFile}
              />
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: 20,
                justifyContent: 'flex-end',
              }}
            >
              <button
                onClick={handleSubmit}
                className='disabled:cursor-not-allowed cursor-pointer text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
              >
                Submit
              </button>
            </div>
          </div>
        </Tabs.Content>
        <Tabs.Content className='TabsContent' value='tab2'>
          <div className='mt-3'>
            {supports.filter(support => (support.isOrg)).map((support, index) => {
              return (
                <>
                  <h5 id='accordion-collapse-heading-1' key={index}>
                    <button
                      type='button'
                      className='flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3'
                      onClick={() => {
                        if (openedId !== support._id) {
                          setOpenedId(support._id);
                        } else {
                          setOpenedId('');
                        }
                      }}
                    >
                      <span>{support.title}</span>
                      <ChevronUp
                        className={
                          'transition-all w-5 h-5 shrink-0 ' + (openedId === support._id
                            ? ''
                            : 'rotate-180')
                        }
                      />
                    </button>
                  </h5>
                  <div
                    id='accordion-collapse-body-1'
                    className={openedId === support._id ? 'block' : 'hidden'}
                  >
                    <div className='p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900'>
                      <p className='mb-2 text-gray-500 dark:text-gray-400'>
                        {support.description}
                      </p>
                      <p className='text-gray-500 dark:text-gray-400'>
                        Attached File: {support.uploadFile ? <a href={support.uploadFile} download>Download</a> : 'None'}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </Tabs.Content>
      </Tabs.Root>
    </div>
  );
}
