export const BASE_URL = 'https://apzmember.com';
export const API_VERSION = 'v1';
export const JWT_SECRET_KEY = 'secret';

export const ORGANIZATION_TYPES = [
  { value: 'sports', name: 'Sports' },
  { value: 'social', name: 'Social' },
  { value: 'communal', name: 'Communal' },
  { value: 'religion', name: 'Religion' },
  { value: 'political', name: 'Political' },
  { value: 'student', name: 'Student Organization' },
  { value: 'custom', name: 'Custom' },
];
