import { GoogleAuthProvider, getAuth , FacebookAuthProvider } from "@firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyB9IiTpNC4IaNPMjXoL7JFS0oXFnM7Lof0",
    authDomain: "membership-2b77f.firebaseapp.com",
    projectId: "membership-2b77f",
    storageBucket: "membership-2b77f.appspot.com",
    messagingSenderId: "230889417314",
    appId: "1:230889417314:web:947fbe13cd1d2dd1f5ff3b",
    measurementId: "G-9R510NFW74"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const provider1 = new FacebookAuthProvider();

export { auth, provider , provider1 };