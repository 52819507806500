import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
  Linkedin,
  Instagram,
  Facebook,
  PhoneCall,
  MailIcon,
  MapPin,
} from 'lucide-react';

import Logo_img from '../assets/logo.svg';
import Background_img from '../assets/background.png';

function Navbar() {
  return (
    <div className='flex w-full items-center justify-between bg-white p-6 shadow-xl'>
      <Link to={'/'}>
        <img src={Logo_img} alt='logo_img' width={40} />
      </Link>
      <div>
        <Link
          to={'/blogs'}
          className='text-gray-900 no-underline ml-auto rounded-md bg-transparent px-6 py-2 text-[15px] md:text-[20px] transition-all hover:bg-[#8053FF] hover:text-white'
        >
          Blogs
        </Link>
        <Link
          to={'/faqs'}
          className='text-gray-900 no-underline rounded-md bg-transparent px-6 py-2 text-[15px] md:text-[20px] transition-all hover:bg-[#8053FF] hover:text-white'
        >
          FAQs
        </Link>
        <Link
          to={'/about'}
          className='text-gray-900 no-underline mr-3 rounded-md bg-transparent px-6 py-2 text-[15px] md:text-[20px] transition-all hover:bg-[#8053FF] hover:text-white'
        >
          About us
        </Link>
        <Link
          to={'/price_plans'}
          className='text-gray-900 no-underline mr-3 rounded-md bg-transparent px-6 py-2 text-[15px] md:text-[20px] transition-all hover:bg-[#8053FF] hover:text-white'
        >
          Price Plans
        </Link>
        <Link
          to={'/register_organization'}
          className='no-underline rounded-md bg-[#8053FF] text-white px-6 py-2 text-[15px] md:text-[20px] transition-all hover:opacity-80'
        >
          Create Business
        </Link>
      </div>
    </div>
  );
}

export function Footer() {
  return (
    <>
      <div className='w-full bg-[#8053FF] text-white opacity-90 md:px-0 px-4'>
        <div className='mx-auto my-12 flex max-w-[1500px] gap-10  md:flex-row flex-col'>
          <div className='md:w-[30%] w-full'>
            <h2 className='text-[30px] font-bold'>APZ-MEMBERSHIP</h2>
            <p className='mt-8'>
              We are an organization dedicated to make membership management
              easier than it was. Quickly get started and enjoy our services.
            </p>
            <div className='mt-4 flex gap-6'>
              <Linkedin className='h-8 w-8 text-white' />
              <Instagram className='h-8 w-8 text-white' />
              <Facebook className='h-8 w-8 text-white' />
            </div>
          </div>
          <div className='md:w-[70%] w-full'>
            <p className='text-[23px] font-bold'>Contact Info</p>
            <div className='mt-6 flex gap-4 md:flex-row flex-col'>
              <div className='flex gap-2 items-center'>
                <PhoneCall className='h-6 w-6 text-white' />
                +554563421432
              </div>
              <div className='flex gap-2 items-center'>
                <MailIcon className='h-6 w-6 text-white' />
                support@apzdkyc.com
              </div>
              <div className='flex gap-2 items-center'>
                <MapPin className='h-6 w-6 text-white' />
                Somewhere in South Africa.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full bg-white py-3 text-center'>
        © copyright APZ-Memership
      </div>
    </>
  );
}

export default function PubilcLayout({ children }: { children: ReactNode }) {
  return (
    <main className='flex min-h-screen flex-col items-center justify-center'>
      <Navbar />
      <img
        src={Background_img}
        className='fixed left-0 top-0 -z-10 h-full w-full'
        alt='background_img'
      />
      {children}
      <Footer />
    </main>
  );
}
