import React from 'react';

import {
  Card,
  CardContent,
  CardTitle,
  CardDescription,
  CardFooter,
  CardHeader,
} from '../../../components/ui/card';

import {
  Avatar,
  AvatarImage,
  AvatarFallback,
} from '../../../components/ui/avatar';

import api from '../../../lib/api';

export default function ExporeCareers() {
  const [admins, setAdmins] = React.useState<any[]>([]);

  React.useEffect(() => {
    api.get('/user/getAdmins').then((data) => {
      setAdmins(data);
    });
  }, []);

  return (
    <div className='flex justify-between flex-wrap w-full'>
      {admins.map((admin) => {
        return (
          <Card className='w-[400px] my-5' key={admin._id}>
            <CardHeader>
              <CardTitle>{admin.username}</CardTitle>
              <CardDescription>Admin</CardDescription>
            </CardHeader>
            <CardContent>
              <div className='flex justify-center w-full'>
                <Avatar className='w-52 h-52'>
                  <AvatarImage
                    className='AvatarImage'
                    src={admin.avatar}
                    alt='Pedro Duarte'
                  />
                  <AvatarFallback
                    className='bg-blue-900 text-8xl text-white'
                    delayMs={600}
                  >
                    {admin.username.toLocaleUpperCase()[0] +
                      admin.username.toLocaleUpperCase()[1]}
                  </AvatarFallback>
                </Avatar>
              </div>
            </CardContent>
            <CardFooter>
              <div className='flex justify-center w-full'>
                <a
                  href={`mailto:${admin.email}?subject=This mail is from apzmember.com`}
                  className='transition-all duration-100 disabled:cursor-not-allowed cursor-pointer text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
                >
                  Contact with email
                </a>
              </div>
            </CardFooter>
          </Card>
        );
      })}
    </div>
  );
}
