import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from '../../../../lib/api';
import { MoveLeftIcon, Plus } from 'lucide-react';
import Loading from '../../../../components/loading';
import moment from 'moment';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../../components/ui/dialog';
import { Input } from '../../../../components/ui/input';
import { Label } from '../../../../components/ui/label';
import { Button } from '../../../../components/ui/button';
import { useToast } from '../../../../components/ui/use-toast';
import e from 'express';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

export default function ManageGalleryEdit() {
  const { id } = useParams();

  const [gallery, setGallery] = useState<any>(null);

  const [photos, setPhotos] = useState<any[]>([]);

  const [open, setOpen] = useState(false);

  const [fileBlob, setFileBlob] = useState<any>(null);

  const { toast } = useToast();

  const user = useSelector((root: RootState) => root.auth.user);

  const initialInput = {
    id: '',
    name: '',
    description: '',
  };

  const [input, setInput] = React.useState(initialInput);

  useEffect(() => {
    api.get('/admin/gallery/getdetail/' + id).then((data) => {
      setGallery(data.gallery);
      setPhotos(data.photos);
    });
  }, []);

  const handleAdd = async () => {
    if (input.id !== '') {
      let fileStr = null;
      if (fileBlob) {
        fileStr = await new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.readAsDataURL(fileBlob);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      }

      return api
        .post('/admin/gallery/photo/edit', {
          ...input,
          photo: fileStr,
          gallery: id,
        })
        .then((data) => {
          setOpen(false);
          setPhotos(data);
          setFileBlob(null);
        });
    }

    if (!fileBlob) {
      return toast({ title: 'Please select the file!' });
    }

    const fileStr = await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(fileBlob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    api
      .post('/admin/gallery/photo/add', { ...input, photo: fileStr, id })
      .then((data) => {
        setOpen(false);
        setPhotos(data);
        setFileBlob(null);
      });
  };

  if (!gallery) return <Loading />;

  return (
    <div>
      <div className='flex'>
        <Link
          to={'/dashboard/manage_gallery'}
          className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
        >
          <MoveLeftIcon className='w-5 mr-2' />
          Back to Gallery List
        </Link>
        <button
          className='flex justify-center items-center focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800'
          onClick={() => {
            setInput(initialInput);
            setOpen(true);
          }}
        >
          <Plus className='w-5' />
          Add Photo
        </button>
      </div>
      <h3 className='mt-2'>{gallery.name}</h3>
      <p className='text-lg'>{gallery.description}</p>
      <div className='grid grid-cols-2 md:grid-cols-4 gap-4 mt-5'>
        {[0, 1, 2, 3].map((_, index) => {
          return (
            <div className='grid gap-4' key={index}>
              {photos.map((photo, index1) => {
                if (index1 % 4 === index)
                  return (
                    <div
                      key={index1}
                      className='cursor-pointer relative hover:-translate-y-1 transition-all overflow-hidden rounded-lg'
                    >
                      <img
                        className='h-auto max-w-full'
                        src={photo.photo}
                        alt=''
                      />
                      <div className='absolute top-0 left-0 w-full h-full bg-gray-700 opacity-0 hover:opacity-60 transition-all text-white p-4'>
                        <h3>{photo.name}</h3>
                        <p className='text-xl mt-3'>{photo.description}</p>
                        <p className='mt-3 text-md'>
                          <b>Created At: </b>
                          {moment(photo.createdAt).format('YYYY-MM-DD')}
                        </p>
                        <p className='mt-3 text-md'>
                          <b>Author: </b>
                          {photo.author.username}
                        </p>
                        {user._id === photo.author._id && (
                          <div className='absolute bottom-4 right-3'>
                            <button
                              className='flex justify-center items-center focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800'
                              onClick={() => {
                                setInput({
                                  id: photo._id,
                                  description: photo.description,
                                  name: photo.name,
                                });
                                setOpen(true);
                              }}
                            >
                              Edit
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                else return <div key={index1}></div>;
              })}
            </div>
          );
        })}
      </div>
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>{input.id ? 'Edit' : 'Add'} Photo</DialogTitle>
            <DialogDescription>
              Please input the photo detail.
            </DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='photo_name'>Name</Label>
              <Input
                value={input.name}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, name: e.target.value }))
                }
                id='photo_name'
                placeholder='Please input gallery name.'
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='description'>Description</Label>
              <Input
                value={input.description}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, description: e.target.value }))
                }
                id='description'
                placeholder='Please enter the description of the gallery.'
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='description'>Photo File</Label>
              <Input
                type='file'
                onChange={(event) => {
                  const file = event.target.files![0];
                  setFileBlob(file);
                }}
                accept='image/*'
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              onClick={handleAdd}
              disabled={!input.name || !input.description}
            >
              {input.id ? 'Update' : 'Add'} Photo
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
