import React, { useCallback, useEffect, useState } from 'react';

import {
  Search,
  PanelLeftCloseIcon,
  SendHorizonalIcon,
  PanelLeftOpenIcon,
} from 'lucide-react';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import api from '../../../lib/api';
import { UserType } from '../../../types/user';
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
} from '../../../components/ui/avatar';

import { useToast } from '../../../components/ui/use-toast';

import moment from 'moment';

import { BASE_URL } from '../../../config';

const socket = io(BASE_URL);

export default function Chatting() {
  const user = useSelector((root: RootState) => root.auth.user);

  const [userList, setUserList] = useState<UserType[]>([]);

  const [onlineUserList, setOnlineUserList] = useState<any[]>([]);

  const [selectedUser, setSelectedUser] = useState<string>('');

  const [message, setMessage] = useState<string>('');

  const [messageHistory, setMessageHistory] = useState<any[]>([]);

  const [unReadMessages, setUnReadMessages] = useState<any[]>([]);

  const [searchUsername, setSearchUsername] = useState('');

  const [leftPanelOpen, setLeftPanelOpen] = useState(true);

  const { toast } = useToast();

  useEffect(() => {
    api.get('/user/getUsers').then((data) => {
      setUserList(data);
    });

    api.get('/user/chatting/getUnreadMessage').then((data) => {
      setUnReadMessages(data);
    });

    socket.on('chatroom_users', (data) => {
      setOnlineUserList(data);
    });
  }, []);

  useEffect(() => {
    socket.on('receive_message', (data) => {
      if (
        (data.from === selectedUser && data.to === user._id) ||
        (data.from === user._id && data.to === selectedUser)
      ) {
        api.get(`/user/chatting/getMessage/${selectedUser}`).then((data) => {
          setMessageHistory(data);
          setTimeout(() => {
            const objDiv = document.getElementById('message-panel') as any;
            objDiv.scrollTop = objDiv.scrollHeight;
          }, 100);
        });
      } else {
        api.get('/user/chatting/getUnreadMessage').then((data) => {
          setUnReadMessages(data);
        });
      }
    });
  }, [selectedUser]);

  useEffect(() => {
    if (user) {
      socket.emit('join_room', { user, room: user.organization._id });
    }
  }, [user]);

  const isOnlnie = (user: UserType) => {
    return onlineUserList.find((onlineUser) => {
      return onlineUser.user._id === user._id;
    });
  };

  const selectUser = (userID: string) => {
    setSelectedUser(userID);
    api.get(`/user/chatting/getMessage/${userID}`).then((data) => {
      setMessageHistory(data);
      setTimeout(() => {
        const objDiv = document.getElementById('message-panel') as any;
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 100);
    });
    setUnReadMessages(
      unReadMessages.filter((item) => {
        return item._id !== userID;
      })
    );
  };

  const sendMessage = (event: any) => {
    event.preventDefault();
    if (selectedUser === '') {
      return toast({
        title: 'Please select the member who you want to chat with.',
      });
    }
    socket.emit('send_message', {
      message,
      to: selectedUser,
      room: user.organization._id,
      from: user._id,
    });

    const newArray = [...messageHistory];
    newArray.push({
      _id: 'new',
      content: message,
      to: userList.find((item) => item._id === selectedUser),
      from: userList.find((item) => item._id === user._id),
      createdAt: moment().toString(),
      updatedAt: moment().toString(),
    });

    setMessageHistory(newArray);

    setTimeout(() => {
      const objDiv = document.getElementById('message-panel') as any;
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 100);
    setMessage('');
  };

  const findUnreadMessageCount = (userID: string) => {
    const column = unReadMessages.find((item) => item._id === userID);
    return column ? column.count : 0;
  };

  const getTotalUnreadMessage = useCallback(() => {
    if (unReadMessages.length === 0) return 0;
    if (unReadMessages.length === 1) return unReadMessages[0].count;
    return unReadMessages.reduce((a, b) => a.count + b.count);
  }, [unReadMessages]);

  return (
    <div className='flex flex-row h-screen antialiased text-gray-800 relative'>
      <div
        className={
          'z-10 flex flex-row sm:w-96 overflow-hidden transition-all flex-shrink-0 bg-gray-100 sm:p-4 md:relative absolute h-full ' +
          (leftPanelOpen ? 'w-full' : 'w-0')
        }
      >
        <div className='flex flex-col w-full h-full sm:p-4 sm:-mr-4 mr-0'>
          <div className='block md:hidden mb-3 ml-auto'>
            <button
              className='p-2 bg-gray-300 rounded-md'
              onClick={() => {
                setLeftPanelOpen(false);
              }}
            >
              <PanelLeftCloseIcon className='w-4 h-4' />
            </button>
          </div>
          <div className='flex flex-row items-center'>
            <div className='flex flex-row items-center'>
              <div className='text-xl font-semibold'>Messages</div>
              {getTotalUnreadMessage() === 0 ? (
                <></>
              ) : (
                <div className='flex items-center justify-center ml-2 text-xs h-5 w-5 text-white bg-red-500 rounded-full font-medium'>
                  {getTotalUnreadMessage()}
                </div>
              )}
            </div>
            <div className='ml-auto'>
              <div className='flex items-center justify-center h-7 bg-gray-200 text-gray-500 rounded-full px-3'>
                <input
                  type='text'
                  className='bg-transparent focus:outline-none w-30 text-xs'
                  placeholder='Serach for username'
                  value={searchUsername}
                  onChange={(event) => {
                    setSearchUsername(event.target.value);
                  }}
                />
                <Search className='w-4 h-4 stroke-current' />
              </div>
            </div>
          </div>
          <div className='mt-2'>
            <div className='flex flex-col -mx-4'>
              {userList
                .filter((item) => item._id !== user._id)
                .filter((item) =>
                  item.username.toLowerCase().includes(searchUsername)
                )
                .map((user) => {
                  return (
                    <div
                      className={
                        'flex flex-row items-center p-4 hover:bg-gradient-to-r from-red-100 to-transparent hover:border-l-2 hover:border-red-500 cursor-pointer ' +
                        (selectedUser === user._id
                          ? 'bg-gradient-to-r from-green-100 to-transparent border-l-2 border-green-500'
                          : '')
                      }
                      key={user._id}
                      onClick={() => {
                        selectUser(user._id);
                      }}
                    >
                      <Avatar className='flex items-center justify-center h-10 w-10 rounded-full flex-shrink-0'>
                        <AvatarImage
                          className='AvatarImage'
                          src={user.avatar!}
                          alt='Pedro Duarte'
                        />
                        <AvatarFallback
                          className='font-bold bg-pink-500 text-pink-300 '
                          delayMs={600}
                        >
                          {user.username.toLocaleUpperCase()[0] +
                            user.username.toLocaleUpperCase()[1]}
                        </AvatarFallback>
                      </Avatar>

                      <div className='flex flex-col flex-grow ml-3'>
                        <div className='flex items-center'>
                          <div className='text-sm font-medium'>
                            {user.username}
                          </div>
                          {isOnlnie(user) && (
                            <div className='h-2 w-2 rounded-full bg-green-500 ml-2'></div>
                          )}
                        </div>
                        <div className='text-xs truncate w-40'>{user.role}</div>
                      </div>
                      {findUnreadMessageCount(user._id) === 0 ? (
                        <></>
                      ) : (
                        <div className='flex-shrink-0 ml-2 self-end mb-1'>
                          <span className='flex items-center justify-center h-5 w-5 bg-red-500 text-white text-xs rounded-full'>
                            {findUnreadMessageCount(user._id)}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col h-full w-full bg-white px-4 py-6'>
        <div className='md:hidden block'>
          <button
            className='p-2 bg-gray-300 rounded-md'
            onClick={() => {
              setLeftPanelOpen(true);
            }}
          >
            <PanelLeftOpenIcon className='w-6 h-6' />
          </button>
        </div>
        <div className='h-full overflow-hidden py-4'>
          <div className='h-full overflow-y-auto' id='message-panel'>
            <div className='grid grid-cols-12 gap-y-2'>
              {messageHistory.map((item) => {
                if (item.from._id === user._id)
                  return (
                    <div
                      className='col-start-6 col-end-13 p-3 rounded-lg'
                      key={item._id}
                    >
                      <div className='flex items-center justify-start flex-row-reverse'>
                        <Avatar className='h-10 w-10 '>
                          <AvatarImage
                            className='AvatarImage'
                            src={item.from.avatar!}
                            alt='Pedro Duarte'
                          />
                          <AvatarFallback
                            className='font-bold bg-blue-500 text-blue-300'
                            delayMs={600}
                          >
                            {item.from.username.toLocaleUpperCase()[0] +
                              item.from.username.toLocaleUpperCase()[1]}
                          </AvatarFallback>
                        </Avatar>
                        <div className='relative mr-3 text-sm'>
                          <div className='bg-white py-2 px-4 shadow rounded-xl'>
                            {item.content}
                          </div>
                          <div className='absolute mt-2 text-xs right-3 text-gray-400'>
                            {item._id !== 'new' &&
                              moment(item.createdAt).format('MM-DD HH:mm')}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                return (
                  <div
                    className='col-start-1 col-end-8 p-3 rounded-lg'
                    key={item._id}
                  >
                    <div className='flex flex-row items-center'>
                      <Avatar className='h-10 w-10 '>
                        <AvatarImage
                          className='AvatarImage'
                          src={item.from.avatar!}
                          alt='Pedro Duarte'
                        />
                        <AvatarFallback
                          className='font-bold bg-pink-500 text-pink-300'
                          delayMs={600}
                        >
                          {item.from.username.toLocaleUpperCase()[0] +
                            item.from.username.toLocaleUpperCase()[1]}
                        </AvatarFallback>
                      </Avatar>
                      <div className='relative ml-3 text-sm'>
                        <div className='bg-white py-2 px-4 shadow rounded-xl'>
                          {item.content}
                        </div>
                        <div className='absolute mt-2 text-xs ml-3 text-gray-400'>
                          {moment(item.createdAt).format('MM-DD HH:mm')}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <form className='flex flex-row items-center' onSubmit={sendMessage}>
          <div className='flex flex-row items-center w-full border rounded-3xl h-12 px-2'>
            <div className='w-full'>
              <input
                type='text'
                className='border border-transparent w-full focus:outline-none text-sm h-10 flex items-center pl-3'
                placeholder='Type your message....'
                onChange={(event) => {
                  setMessage(event.target.value);
                }}
                value={message}
              />
            </div>
            {/* <div className='flex flex-row'>
              <button className='flex items-center justify-center h-10 w-8 text-gray-400'>
                <Paperclip className='w-5 h-5' />
              </button>
              <button className='flex items-center justify-center h-10 w-8 text-gray-400 ml-1 mr-2'>
                <Image className='w-5 h-5' />
              </button>
            </div> */}
          </div>
          <div className='ml-6'>
            <button
              type='submit'
              className='flex items-center justify-center h-10 w-10 rounded-full bg-gray-200 hover:bg-gray-300 text-indigo-800'
            >
              <SendHorizonalIcon className='w-5 h-5 transform -mr-px' />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
