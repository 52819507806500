import { Link, redirect } from 'react-router-dom';
import { useState  , useEffect} from 'react';
import Verfiy_email from '../landing/Verify_email'
import { Button } from '../../components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import { Dialog , DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,} from '../../components/ui/dialog';
import { Label } from '../../components/ui/label';
import { Input } from '../../components/ui/input';
import { useToast } from '../../components/ui/use-toast';
import { Select } from '../../components/ui/select';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, provider , provider1 } from "./Config";
import Google__G__logo from '../../Google__G__logo.svg.png'
import facebook from '../../download.jpg'
import Apple from '../../siwa-logo-masked-circle@2x.png'
import { ORGANIZATION_TYPES } from '../../config';
import e from 'express';
import api from '../../lib/api';

export default function RegisterOrganization() {
    const [termsAccepted, setTermsAccepted] = useState(false); 
   
    const [val , setVal] = useState('');
    const [open, setOpen] = useState(false);

  const [inputs, setInputs] = useState({
    email: '',
    username: '',
    lastname :'' ,
    dateofbirth:'' ,
    gender:'male',
    organization: '',
    password: '',
    regnum: '',
    vatnum: '',
    category: '',
    subcategory: '',
    phonenumber:'',
    address:'',
    termscondition:termsAccepted
  });
  const [organizationName , setOrganizationName] = useState('')
  const [organizationvatNumber , setOrganizationvatNumber] = useState('')
  const [organizationregnum , setOrganizationregnum] = useState('')
  const [categoryName , setCategoryName] = useState('')
  const [GoogleName , setGoogleName] = useState<any>('');
  const [GoogleEmail , setGoogleEmail] = useState<any>('');

  const { toast } = useToast();

 

  const SUB_CATEGORIES = {
    religion: ['Hindu', 'Islam', 'Christianity', 'Shembe', 'Other'],
    communal: [
      'Estate membership',
      'Homeowners’ associations',
      'Neighborhood Watch',
      'Other',
    ],
    student: ['Universities', 'Colleges', 'Schools', 'National Student Community'],
  };

  function handleSignup() {
    api.post('/auth/register_org', { ...inputs })
      .then(() => {
        toast({ title: 'Please verify your email using the link sent to your registered email!' });
        redirect('/login');
      })
      .catch((error:any) => {
        if (error.response.data.status === 400)
          toast({
            title: 'Register is Failed!',
            description: error.response.data.message,
          });
        else if (error.response.status === 403) {
          let message = '';
          if (error.response.data.errors[0].path === 'username')
            message = 'Username must be more than 4 characters';
          else if (error.response.data.errors[0].path === 'password')
            message = 'Password must be 6 at mins';
          else if (error.response.data.errors[0].path === 'email')
            message = 'Invalid Email address';
          else if (error.response.data.errors[0].path === 'category')
            message = 'Please select Organization Category!';
          toast({ title: 'Register is Failed!', description: message });
        }
      });
  }
  useEffect(() => {
    setInputs(prevInputs => ({
      ...prevInputs,
      termscondition: termsAccepted,
    }));
  }, [termsAccepted]);

  const handleSubCategoryChange = (e:any) => {
    setInputs((prev) => ({
      ...prev,
      subcategory: e.target.value,
    }));
  };
 
  const handleGoogleSignUp = () => {
    // Check if email and display name are available
    if (GoogleEmail && GoogleName) {
        api.post('/auth/googleSignup', {  
            organizationName, 
            organizationvatNumber, 
            organizationregnum, 
            categoryName,  
            email: GoogleEmail,  
            displayName: GoogleName  
        })
        .then(() => {
          redirect('/login');
          toast({
            title: 'Register Done'
          });
          setOpen(false);
        })
        .catch((error:any) => {
          if (error.response.data.status === 400)
          
            toast({
              title: 'Register is Failed!',
              description: error.response.data.message,
            });
          else if (error.response.status === 403) {
            let message = '';
           
            toast({ title: 'Register is Failed!', description: message });
          }
        });
    } else {
        console.error("Email or display name not available");
    }
};

  
  const SoceilMedia=()=>{
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        console.log("Google Login successful", user);
  
       
  
        user.getIdToken().then((idToken) => {
          const { email, displayName } = user; 
          console.log("Access Token:", idToken);
          console.log("Email:", email);
          setGoogleEmail(email)
          setGoogleName(displayName)
          console.log("Display Name:", displayName);
          setOpen(true);
        
        });
      })
      .catch((error) => {
        console.error("Google Login failed", error);
      });
  }
  
  const SoceilMediaFb=()=>{
    signInWithPopup(auth , provider1)
    .then((result) => {
      const user = result.user;
      console.log("Google Login successful", user);

     

      user.getIdToken().then((idToken) => {
        const { email, displayName } = user; 
        console.log("Access Token:", idToken);
        console.log("Email:", email);
        setGoogleEmail(email)
        setGoogleName(displayName)
        console.log("Display Name:", displayName);
        setOpen(true);
      
      });
    })
    .catch((error) => {
      console.error("Google Login failed", error);
    });
  }
  

  return (
    <div className='flex h-screen flex-col items-center'>
      <Card className='mt-20 w-[380px] py-5 max-[900px]:mt-10 max-[400px]:w-[95%]'>
        <CardHeader>
          <CardTitle>Welcome!</CardTitle>
          <CardDescription>Create your own organization.</CardDescription>
        </CardHeader>
        <CardContent>
          <form>
            <div className='mt-1 grid w-full items-center gap-4'>
              <div className='flex flex-col gap-3  space-y-1.5'>
                <Label htmlFor='email'>Email</Label>
                <Input
                  value={inputs.email}
                  onChange={(e) =>
                    setInputs((prev) => ({ ...prev, email: e.target.value }))
                  }
                  id='email'
                  placeholder='john.doe@example.com'
                />
              </div>
              <div className='flex flex-col gap-3 space-y-1.5'>
                <Label htmlFor='username'>first name</Label>
                <Input
                  value={inputs.username}
                  onChange={(e) =>
                    setInputs((prev) => ({ ...prev, username: e.target.value }))
                  }
                  id='username'
                  type='text'
                  placeholder='john'
                />
              </div>
              <div className='flex flex-col gap-3 space-y-1.5'>
                <Label htmlFor='username'>Last name</Label>
                <Input
                  value={inputs.lastname}
                  onChange={(e) =>
                    setInputs((prev) => ({ ...prev, lastname: e.target.value }))
                  }
                  // id='username'
                  type='text'
                  placeholder='doe'
                />
              </div>
              <div className='flex flex-col gap-3 space-y-1.5'>
                <Label htmlFor='gender'>Gender</Label>
                <Select
                  value={inputs.gender?inputs.gender: 'male'}
                  onChange={(e) =>
                    setInputs((prev) => ({
                      ...prev,
                      gender: e.target.value,
                    }))
                  }
                  id='gender'
                >
                  <option value='male'>Male</option>
                  <option value='female'>Female</option>
                  <option value='other'>Other</option>
                </Select>
              </div>
                <div className='flex flex-col gap-3 space-y-1.5'>
                <Label htmlFor='username'>Address</Label>
                <Input
                  value={inputs.address}
                  onChange={(e) =>
                    setInputs((prev) => ({ ...prev, address: e.target.value }))
                  }
                  
                  type='text'
                  placeholder='address'
                />
              </div>
              <div className='flex flex-col gap-3 space-y-1.5'>
                <Label htmlFor='dob'>Date of Birth</Label>
                <Input
                  value={inputs.dateofbirth}
                  onChange={(e) => setInputs((prev) => ({ ...prev, dateofbirth: e.target.value }))}
                  id='dob'
                  type='date'
                />
              </div>
              <div className='flex flex-col gap-3  space-y-1.5'>
                <Label htmlFor='vat_num'>Contact No</Label>
                <Input
                  value={inputs.phonenumber}
                  onChange={(e) =>
                    setInputs((prev) => ({
                      ...prev,
                      phonenumber: e.target.value,
                    }))
                  }
                  id='vat_num'
                  placeholder='Contact number.'
                />
              </div>
              <div className='flex flex-col gap-3  space-y-1.5'>
                <Label htmlFor='organization'>Organization Category</Label>
                <Select
                  value={inputs.category}
                  onChange={(e) =>
                    setInputs((prev) => ({
                      ...prev,
                      category: e.target.value,
                    }))
                  }
                  id='organization'
                >
                  <option value={''}>None</option>
                  {ORGANIZATION_TYPES.map((type, index) => (
                    <option value={type.value} key={index}>
                      {type.name}
                    </option>
                  ))}
                </Select>
              </div>
              {inputs.category === 'religion' && (
        <div className='flex flex-col gap-3 space-y-1.5'>
          <Label htmlFor='subCategory'>Religion</Label>
          <Select
            value={inputs.subcategory}
            onChange={handleSubCategoryChange}
            id='subCategory'
          >
            {SUB_CATEGORIES.religion.map((subCat, index) => (
              <option value={subCat} key={index}>
                {subCat}
              </option>
            ))}
          </Select>
        </div>
      )}

      {inputs.category === 'communal' && (
        <div className='flex flex-col gap-3 space-y-1.5'>
          <Label htmlFor='subCategory'>Communal</Label>
          <Select
            value={inputs.subcategory}
            onChange={handleSubCategoryChange}
            id='subCategory'
          >
            {SUB_CATEGORIES.communal.map((subCat, index) => (
              <option value={subCat} key={index}>
                {subCat}
              </option>
            ))}
          </Select>
        </div>
      )}

      {inputs.category === 'student' && (
        <div className='flex flex-col gap-3 space-y-1.5'>
          <Label htmlFor='subCategory'>Student</Label>
          <Select
            value={inputs.subcategory}
            onChange={handleSubCategoryChange}
            id='subCategory'
          >
            {SUB_CATEGORIES.student.map((subCat, index) => (
              <option value={subCat} key={index}>
                {subCat}
              </option>
            ))}
          </Select>
        </div>
      )}

              <div className='flex flex-col gap-3  space-y-1.5'>
                <Label htmlFor='organization'>Organization Name</Label>
                <Input
                  value={inputs.organization}
                  onChange={(e) =>
                    setInputs((prev) => ({
                      ...prev,
                      organization: e.target.value,
                    }))
                  }
                  id='organization'
                  placeholder='Organization Name'
                />
              </div>
              <div className='flex flex-col gap-3  space-y-1.5'>
                <Label htmlFor='reg_num'>Reg No</Label>
                <Input
                  value={inputs.regnum}
                  onChange={(e) =>
                    setInputs((prev) => ({
                      ...prev,
                      regnum: e.target.value,
                    }))
                  }
                  id='reg_num'
                  placeholder='Reg No.'
                />
              </div>
              <div className='flex flex-col gap-3  space-y-1.5'>
                <Label htmlFor='vat_num'>VAT No</Label>
                <Input
                  value={inputs.vatnum}
                  onChange={(e) =>
                    setInputs((prev) => ({
                      ...prev,
                      vatnum: e.target.value,
                    }))
                  }
                  id='vat_num'
                  placeholder='Vat Num.'
                />
              </div>
              <div className='mt-2 flex flex-col gap-3 space-y-1.5'>
                <Label htmlFor='password'>Password</Label>
                <Input
                  value={inputs.password}
                  onChange={(e) =>
                    setInputs((prev) => ({ ...prev, password: e.target.value }))
                  }
                  type='password'
                  id='password'
                />
              </div>
              <div className='mt-2 flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='terms'>
                <input
                  type='checkbox'
                  id='terms'
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                
                />
                <span className='ml-2'>I accept the terms and conditions</span>
              </Label>
            </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className='mt-1 flex'>
          <Button
            disabled={
              !inputs.organization ||
              !inputs.email ||
              !inputs.password ||
              !inputs.username ||
              !inputs.regnum ||
              !inputs.vatnum ||
              !inputs.category
            }
            onClick={handleSignup}
            variant='custom'
            className='w-full'
          >
            Register & Sign up
          </Button>
        </CardFooter>
        <p style={{textAlign:'center'}}>Or Signup with</p>

        <CardFooter className='mt-1 flex'>
           <div style={{display:'flex' , justifyContent:'space-between' ,margin:'auto' }}>
            <div style={{padding:'10px' , cursor:'pointer'}}
        
            
             ><img 
             onClick={SoceilMedia}
             src={Google__G__logo} 
         
             style={{height:'50px'}} /></div>
             <div style={{padding:'10px' , cursor:'pointer'}}
           
             ><img src={facebook} 
             onClick={SoceilMediaFb}
             style={{height:'50px'}} /></div>
             {/* <div style={{padding:'10px' , cursor:'pointer'}}><img src={Apple} style={{height:'50px'}} /></div> */}
             </div>
        </CardFooter>
      </Card>
      <span className='mt-6 text-sm max-[400px]:pb-4'>
        Already have an account?{' '}
        <Link className='text-neutral-400' to='/login'>
          Log in
        </Link>{' '}
       
      </span>

      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>First Complete the require-filds</DialogTitle>
            <DialogDescription>Please input the organization detail</DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='Organization'>Organization  Name</Label>
              <Input
                onChange={(e)=>setOrganizationName(e.target.value)}
                id='firstName'
                value={organizationName}
                placeholder='Organization'
              />
            </div>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='lastName'>Vat Number </Label>
              <Input
               
               onChange={(e)=>setOrganizationvatNumber(e.target.value)}
                  value={organizationvatNumber}
                placeholder='45556566'
              />
            </div>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='email'>Reg No</Label>
              <Input
              value={organizationregnum}
                                onChange={(e)=>setOrganizationregnum(e.target.value)}

                placeholder='445555665'
              />
            </div>
          
            <div className='flex flex-col gap-3  space-y-1.5'>
                <Label htmlFor='organization'>Organization Category</Label>
                <Select

                  onChange={(e)=>setCategoryName(e.target.value)}
                   value={categoryName}
                  id='organization'
                >
                  <option value={''}>None</option>
                  {ORGANIZATION_TYPES.map((type, index) => (
                    <option value={type.value} key={index}>
                      {type.name}
                    </option>
                  ))}
                </Select>
              </div>
          </div>
          <DialogFooter>
            <Button onClick={handleGoogleSignUp}>Save </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

    </div>
  );
}