import React from 'react';

import { Link } from 'react-router-dom';

import Landing_left_img from '../../assets/landing.png';
import Logo_img from  '../../assets/logo.svg'

export default function Landing() {
  return (
    
      <main className='flex min-h-screen flex-col items-center justify-center w-full'>
        <div className='flex w-full flex-col items-center justify-between gap-12 py-16 md:flex-row'>
          <img
            src={Landing_left_img}
            alt='landing_left_img'
            className='shrink-0 rounded-r-[6rem] shadow-lg'
          />
          <div className='items-left mx-auto flex w-full flex-col justify-center px-4 md:w-[36.5rem] md:px-0'>
            
            <img src={Logo_img} alt='logo_img' width={80}/>
            <h1 className='mt-[1rem] text-[3.25rem] font-[700] leading-[4rem] text-[#8053FF]'>
              Brought to you <p className='text-[#23155B]'>by APZ</p>
            </h1>
            <p className='mt-[3.5rem] text-[1.125rem] leading-[1.75rem] text-[#23155B]'>
              Welcome to our exclusive community! Unleash the full potential of
              your membership with our seamless management system. Joining is a
              breeze—just a few clicks stand between you and a world of premium
              benefits.
            </p>
            <p className='mt-[1.5rem] text-[1.125rem] leading-[1.75rem] text-[#23155B]'>
              Manage your profile with ease, stay in the loop with timely
              notifications, and explore exclusive content crafted just for you.
              Our secure payment gateways ensure smooth transactions, while
              administrators keep everything running like a well-oiled machine.
              Your journey starts here; become a member and unlock a realm of
              possibilities!
            </p>
            <div className='mt-[4rem] flex justify-center gap-[0.625rem]'>
              <Link
                to={'/login'}
                className='rounded-[0.5rem] bg-[#8053FF] px-[1.5rem] py-[0.75rem] text-white transition-all duration-300 hover:bg-[#8053FF]/80'
              >
                Login
              </Link>
              <Link
                to={'/register'}
                className='rounded-[0.5rem] border border-[#8053FF] px-[1.5rem] py-[0.75rem] text-[#8053FF] transition-all duration-300 hover:bg-[#8053FF]/20'
              >
                Create Account
              </Link>
            </div>
          </div>
        </div>
      </main>
  );
}
