import React, { useEffect } from 'react';

import { Plus } from 'lucide-react';
import Select from 'react-select';
import api from '../../../lib/api';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { Link } from 'react-router-dom';

export default function ManageReligionGroups() {
  const initialInput = {
    id: '',
    name: '',
    description: '',
  };

  const [open, setOpen] = React.useState(false);

  const [groups, setGroups] = React.useState<any[]>([]);

  const [input, setInput] = React.useState(initialInput);

  const handleAdd = () => {
    if (input.id !== '') {
      return api.post('/admin/group/edit', { ...input }).then((data) => {
        setOpen(false);
        setGroups(data);
      });
    }
    api.post('/admin/group/add', { ...input }).then((data) => {
      setGroups(data);
      setOpen(false);
    });
  };

  useEffect(() => {
    api.get('/admin/group').then((data) => {
      setGroups(data);
    });
  }, []);

  return (
    <div>
      <div className='flex'>
        <button
          className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
          onClick={() => {
            setInput(initialInput);
            setOpen(true);
          }}
        >
          <Plus className='w-5' />
          Create Group
        </button>
      </div>

      <div className='overflow-x-auto'>
        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
          <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
            Our groups
            <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
              Browse a list of group.
            </p>
          </caption>
          <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th className='px-6 py-3'>Name</th>
              <th className='px-6 py-3'>Description</th>
              <th className='px-6 py-3'>Users</th>
              <th className='px-6 py-3'>Action</th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group: any, index: number) => {
              return (
                <tr
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  key={index}
                >
                  <th
                    scope='row'
                    className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                  >
                    {group.name}
                  </th>
                  <th
                    scope='row'
                    className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                  >
                    {group.description}
                  </th>

                  <td className='px-6 py-4'>{group.userCount}</td>
                  <td className='px-6 py-4 text-left'>
                    <button
                      onClick={() => {
                        setInput({
                          id: group._id,
                          description: group.description,
                          name: group.name,
                        });
                        setOpen(true);
                      }}
                      className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300'
                    >
                      Edit
                    </button>
                    <button
                      className='bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300'
                      onClick={() => {
                        api
                          .post('/admin/group/delete', { id: group._id })
                          .then((data) => {
                            setGroups(data);
                          });
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>{input.id ? 'Edit' : 'Add'} Group</DialogTitle>
            <DialogDescription>
              Please input the group name and description.
            </DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='group_name'>Group Name</Label>
              <Input
                value={input.name}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, name: e.target.value }))
                }
                id='group_name'
                placeholder='Please input gallery name.'
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='group_description'>Description</Label>
              <Input
                value={input.description}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, description: e.target.value }))
                }
                id='group_description'
                placeholder='Please enter the description of the gallery.'
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              onClick={handleAdd}
              disabled={!input.name || !input.description}
              type='submit'
            >
              {input.id ? 'Update' : 'Add'} Group
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
