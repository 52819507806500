import CommunityValueImage from "../../assets/blogs/community-value-proposition-canvas.webp";
import DevelopAFundraisingImage from "../../assets/blogs/develop-a-fundraising-strategy-1.webp";
import EpisodeImage from "../../assets/blogs/Episode_5.webp";
import HalloweenImage from "../../assets/blogs/halloween-infographic.webp";
import NewBrandIdentityImage from "../../assets/blogs/new-brand-identity-hivebrite.webp";
import { Calendar } from "lucide-react";

function Banner() {
  return (
    <div className="w-full h-[500px] relative">
      <img
        src={DevelopAFundraisingImage}
        alt="community"
        className="h-full w-full object-cover"
      />
      <div className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-30">
      </div>
      <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center">
        <div className="w-full max-w-[700px]">
          <h3 className="text-[40px] font-bold text-white text-center">How to develop a fundraising strategy</h3>
          <p className="text-white text-center mt-4">7 min read  | 23, November 2023</p>
          <div className="flex justify-center"><button className="mt-8 px-8 py-3 bg-[#23155B] text-white rounded-lg">Read Now</button></div>
        </div>
      </div>
    </div>
  );
}

export default function Blog() {
  return (
    <>
      <Banner />
      <div className="min-h-screen w-full max-w-[1500px] p-4 sm:p-10">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-10 max-w-[1400px]">
          <div className="w-full p-0 py-6 sm:p-6">
            <div className="flex flex-col justify-center overflow-hidden rounded-lg bg-white shadow-lg transition-all hover:-translate-y-2 hover:cursor-pointer">
              <div className="h-[300px] w-full">
                <img
                  src={DevelopAFundraisingImage}
                  alt="community"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="flex flex-col items-center justify-center px-5 py-5">
                <p className="text-center text-[24px] font-bold">
                  How to develop a fundraising strategy
                </p>
                <p className="mt-4 flex justify-center gap-6 font-bold opacity-80">
                  <span>7 min read</span>
                  <span className="flex gap-3">
                    <Calendar /> 23, Nov 2023
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-0 py-6 sm:p-6">
            <div className="flex flex-col justify-center overflow-hidden rounded-lg bg-white shadow-lg transition-all hover:-translate-y-2 hover:cursor-pointer">
              <div className="h-[300px] w-full">
                <img
                  src={CommunityValueImage}
                  alt="community"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="flex flex-col items-center justify-center px-5 py-5">
                <p className="text-center text-[24px] font-bold">
                  How to develop a fundraising strategy
                </p>
                <p className="mt-4 flex justify-center gap-6 font-bold opacity-80">
                  <span>7 min read</span>
                  <span className="flex gap-3">
                    <Calendar /> 23, Nov 2023
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-0 py-6 sm:p-6">
            <div className="flex flex-col justify-center overflow-hidden rounded-lg bg-white shadow-lg transition-all hover:-translate-y-2 hover:cursor-pointer">
              <div className="h-[300px] w-full">
                <img
                  src={EpisodeImage}
                  alt="community"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="flex flex-col items-center justify-center px-5 py-5">
                <p className="text-center text-[24px] font-bold">
                  [Podcast] Community Therapy: Breaking Down the AI Barrier
                </p>
                <p className="mt-4 flex justify-center gap-6 font-bold opacity-80">
                  <span>6 min read</span>
                  <span className="flex gap-3">
                    <Calendar /> 23, Nov 2023
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-0 py-6 sm:p-6">
            <div className="flex flex-col justify-center overflow-hidden rounded-lg bg-white shadow-lg transition-all hover:-translate-y-2 hover:cursor-pointer">
              <div className="h-[300px] w-full">
                <img
                  src={HalloweenImage}
                  alt="community"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="flex flex-col items-center justify-center px-5 py-5">
                <p className="text-center text-[24px] font-bold">
                  [Podcast] Community Therapy: Building Confidence to Connect
                </p>
                <p className="mt-4 flex justify-center gap-6 font-bold opacity-80">
                  <span>7 min read</span>
                  <span className="flex gap-3">
                    <Calendar /> 23, Nov 2023
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-0 py-6 sm:p-6">
            <div className="flex flex-col justify-center overflow-hidden rounded-lg bg-white shadow-lg transition-all hover:-translate-y-2 hover:cursor-pointer">
              <div className="h-[300px] w-full">
                <img
                  src={NewBrandIdentityImage}
                  alt="community"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="flex flex-col items-center justify-center px-5 py-5">
                <p className="text-center text-[24px] font-bold">
                  Define your community value proposition with a value
                  proposition canvas
                </p>
                <p className="mt-4 flex justify-center gap-6 font-bold opacity-80">
                  <span>7 min read</span>
                  <span className="flex gap-3">
                    <Calendar /> 23, Nov 2023
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
