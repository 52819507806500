import { Link } from 'react-router-dom';

import { Cloud, LucideGroup, DollarSign, KeyIcon, PlaneLanding,   User, Bell, ArrowLeftRight ,
  Shield , Mailbox  , MessageCircleWarning } from 'lucide-react';

import { SheetTrigger } from '../ui/sheet';

export default function SuperMenu({}) {
  return (
    <>
      <SheetTrigger asChild>
        <Link
          to='/dashboard/users'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <LucideGroup className='mr-2 h-5 w-5' />
          <span>Manage Users</span>
        </Link>
      </SheetTrigger>
      <SheetTrigger asChild>
        <Link
          to='/dashboard/manage_org'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <Cloud className='mr-2 h-5 w-5' />
          <span>Manage Organization</span>
        </Link>
      </SheetTrigger>
      <SheetTrigger asChild>
        <Link
          to='/dashboard/manage_system'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <Shield className='mr-2 h-5 w-5' />
          <span>Manage System</span>
        </Link>
      </SheetTrigger>
      <SheetTrigger asChild>
        <Link
          to='/dashboard/manage_security'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <KeyIcon className='mr-2 h-5 w-5' />
          <span>Manage Security</span>
        </Link>
      </SheetTrigger>
      <SheetTrigger asChild>
        <Link
          to='/dashboard/manage_price_plans'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <DollarSign className='mr-2 h-5 w-5' />
          <span>Manage Price Plans</span>
        </Link>
      </SheetTrigger>
      <SheetTrigger asChild>
        <Link
          to='/dashboard/manage_profile'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <User className='mr-2 h-5 w-5' />
          <span>Edit Profile</span>
        </Link>
      </SheetTrigger>
      <SheetTrigger asChild>
        <Link
          to='dashboard/report'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <MessageCircleWarning className='mr-2 h-5 w-5' />
          <span> Report </span>
        </Link>
      </SheetTrigger>
      <SheetTrigger asChild>
        <Link
          to='dashboard/faq'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <Mailbox className='mr-2 h-5 w-5' />
          <span> FAQ </span>
        </Link>
      </SheetTrigger>
      <SheetTrigger asChild>
        <Link
          to='/dashboard/transection'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <ArrowLeftRight className='mr-2 h-5 w-5' />
          <span> 
Transactions </span>
        </Link>
      </SheetTrigger>
      <SheetTrigger asChild>
        <Link
          to='/dashboard/notification'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <Bell className='mr-2 h-5 w-5' />
          <span> 
Notifications </span>
        </Link>
      </SheetTrigger>
      <SheetTrigger asChild>
        <Link
          to='/dashboard/membership'
          className='text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit'
        >
          <User className='mr-2 h-5 w-5' />
          <span> 
          Membership </span>
        </Link>
      </SheetTrigger>
    </>
  );
}
