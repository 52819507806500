import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../../lib/api';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

export default function ViewGalleryIndex() {
  const [galleries, setGalleries] = React.useState<any[]>([]);

  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    api.get('/user/gallery').then((data) => {
      setGalleries(data);
    });
  }, []);

  return (
    <div className='overflow-x-auto'>
      <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
        <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
          Photo Galleries
          <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
            Browse a list of photo galleries.
          </p>
        </caption>
        <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
          <tr>
            <th className='px-6 py-3'>Name</th>
            <th className='px-6 py-3'>Description</th>
            <th className='px-6 py-3'>Status</th>
          </tr>
        </thead>
        <tbody>
          {galleries.map((gallery: any, index: number) => {
            return (
              <tr
                className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                key={index}
              >
                <th
                  scope='row'
                  className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                >
                  <Link to={gallery._id}>{gallery.name}</Link>
                </th>
                <th
                  scope='row'
                  className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                >
                  {gallery.description}
                </th>
                <td className='px-6 py-4'>
                  <button className='bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300'>
                    {gallery.publish ? 'Publish' : 'Unpublish'}
                    {gallery.share.find((item: string) => item === user._id)
                      ? ' and Shared'
                      : ''}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
