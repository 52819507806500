import React, { useEffect, useState } from "react";
import api from "../../../lib/api";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Plus, ImportIcon, Download, UserPlus } from "lucide-react";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import { useToast } from "../../../components/ui/use-toast";
import { table } from "console";

export default function MannagePayment() {
  const [users, setUsers] = useState<any[]>([]);

  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [orgid, setOrgid] = useState<string>('');
  const [open, setOpen] = useState(false);
  const { toast } = useToast();



  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgRes = await api.get(`/auth/getUser`);
        setOrgid(orgRes.organization._id);
  
        // Fetch golf lessons with organization ID
        const res = await api.get(`/admin/gettransaction`);
        
        setUsers([...res]);  
        setFilteredUsers([...res]);  
        console.log(res);
      } catch (error) {
        console.error(error);
        // Handle error if needed
      }
    };
  
    fetchData();
  }, []);
  

  const handleSearch = (searchText: string) => {
    const filtered = users.filter((user) => {
      if (
        user.userName &&
        user.userName.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return true;
      }
      // Add similar checks for other properties like description if needed
      return false;
    });

    setFilteredUsers(filtered);
  };

  



  return (
    <>
      <div>
        <div>
            <h4>Transactions</h4>
        </div>
        <div className="flex"></div>
        <Input
          className="my-4"
          placeholder="Search by username..."
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            Transaction List
              <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                Browse a list of Flowbite members designed to help you work and
                play, stay organized, get answers, keep in touch, grow your
                business, and more.
              </p>
            </caption>
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Amount
                </th>
                
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user: any, index: number) => {
                return (
                  <tr
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    key={index}
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {user.userName}
                    </th>
                    <td className="px-6 py-4">{user.description}</td>
                    <td className="px-6 py-4">{user.amount}</td>

                   
                  
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
