import { Link } from 'react-router-dom';
import { useState } from 'react';
import { GoogleOAuthProvider, useGoogleOAuth } from '@react-oauth/google';
import { useEffect } from 'react';
import { redirect } from 'react-router-dom';
import { Button } from '../../components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import { Label } from '../../components/ui/label';
import { Input } from '../../components/ui/input';
import { Select } from '../../components/ui/select';
import { useToast } from '../../components/ui/use-toast';

import api from '../../lib/api';

export default function Register() {
 

  const [inputs, setInputs] = useState({
    email: '',
    username: '',
    organization: '',
    password: '',
  });

  const [organizations, setOrganizations] = useState<any[]>([]);
  const [termsAccepted, setTermsAccepted] = useState(false); // State to manage terms acceptance
  const { toast } = useToast();

  useEffect(() => {
    api.get('/auth/getOranizations').then((data) => {
      setOrganizations(data);
    });
  }, []);

  function handleSignup() {
  

    api
      .post('/auth/register', { ...inputs })
      .then(() => {
        toast({ title: 'Register is done!' });
        redirect('/login');
      })
      .catch((error) => {
        if (error.response.data.status === 400)
          toast({
            title: 'Register is Failed!',
            description: error.response.data.message,
          });
        else if (error.response.status === 403) {
          let message = '';
          if (error.response.data.errors[0].path === 'username')
            message = 'Username must be more than 4 characters';
          else if (error.response.data.errors[0].path === 'password')
            message = 'Password must be 6 at mins';
          else if (error.response.data.errors[0].path === 'email')
            message = 'Invalid Email address';
          toast({ title: 'Register is Failed!', description: message });
        }
      });
  }





  return (
    <div className='flex h-screen flex-col items-center'>
      <Card className='mt-12 w-[380px]  max-[900px]:mt-10 max-[400px]:w-[95%]'>
        <CardHeader>
          <CardTitle>Welcome!</CardTitle>
          <CardDescription>Create your account.</CardDescription>
        </CardHeader>
        <CardContent>
          
          <form>
            <div className='mt-1 grid w-full items-center gap-4'>
              <div className='flex flex-col gap-3  space-y-1.5'>
                <Label htmlFor='email'>Email </Label>
                <Input
                  value={inputs.email}
                  onChange={(e) =>
                    setInputs((prev) => ({ ...prev, email: e.target.value }))
                  }
                  id='email'
                  placeholder='john.doe@example.com'
                />
              </div>
              <div className='flex flex-col gap-3  space-y-1.5'>
                <Label htmlFor='username'>Username</Label>
                <Input
                  value={inputs.username}
                  onChange={(e) =>
                    setInputs((prev) => ({ ...prev, username: e.target.value }))
                  }
                  id='username'
                  placeholder='johndoe'
                />
              </div>
              <div className='flex flex-col gap-3  space-y-1.5'>
                <Label htmlFor='avatar'>Organization</Label>
                <Select
                  value={inputs.organization}
                  onChange={(e) =>
                    setInputs((prev) => ({
                      ...prev,
                      organization: e.target.value,
                    }))
                  }
                >
                  <option value={''}>None</option>
                  {organizations.map((organization) => (
                    <option key={organization._id} value={organization._id}>
                      {organization.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className='mt-2 flex flex-col gap-3 space-y-1.5'>
                <Label htmlFor='password'>Password</Label>
                <Input
                  value={inputs.password}
                  onChange={(e) =>
                    setInputs((prev) => ({ ...prev, password: e.target.value }))
                  }
                  type='password'
                  id='password'
                />
              </div>
              <div className='mt-2 flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='terms'>
                <input
                  type='checkbox'
                  id='terms'
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
                <span className='ml-2'>I accept the terms and conditions</span>
              </Label>
            </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className='mt-1 flex'>
          <Button
            disabled={
              !inputs.organization ||
              !inputs.email ||
              !inputs.password ||
              !inputs.username ||
              !termsAccepted // Disable button if terms are not accepted
            }
            onClick={handleSignup}
            variant='custom'
            className='w-full'
          >
            Signup
          </Button>
        </CardFooter>
      
      </Card>
      <span className='mt-6 text-sm max-[400px]:pb-4'>
        Already have an account?{' '}
        <Link className='text-neutral-400' to='/login'>
          Log in
        </Link>{' '}
      </span>
    </div>
  );
}
