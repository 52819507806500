import React from 'react'

import { graphic } from 'echarts'
import ReactECharts from 'echarts-for-react'

const RadarOption = {
  legend: [
    {
      data: [
        {
          name: 'Current Supplier Range',
          itemStyle: {
            color: new graphic.LinearGradient(
              0,
              4,
              80,
              4,
              [
                {
                  offset: 0,
                  color: '#ff0000',
                },
                {
                  offset: 0.35,
                  color: '#ef1818',
                },
                {
                  offset: 0.5,
                  color: '#df1818',
                },
                {
                  offset: 1,
                  color: '#FC6B02',
                },
              ],
              true,
            ),
            opacity: 1,
          },
        },
        {
          name: 'Optimal Supplier Range',
          icon: 'rect',
          itemStyle: {
            color: '#54CE3E',
          },
        },
      ],
      align: 'left',
      right: 0,
      bottom: 30,
      itemWidth: 70,
      itemHeight: 8,
      orient: 'vertical',
      textStyle: { color: '#FFF' },
      icon: 'rect',
    },
  ],
  animation: true,
  tooltip: {},
  radar: {
    indicator: [
      { name: 'Cyber Hygiene', max: 1 },
      { name: 'Peer Lifespan', max: 25 },
      { name: 'Business Resilience', max: 10 },
      { name: 'Lifespan', max: 25 },
    ],
    shape: 'polygon',
    splitNumber: 5,
    axisName: {
      color: '#FFF',
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: 16,
      fontWeight: 500,
      padding: [3, 5],
    },
    splitLine: {
      lineStyle: {
        color: ['#72efdd', '#64dfdf', '#56cfe1', '#48bfe3', '#4ea8de', '#5390d9'].reverse(),
        width: 1.5,
      },
    },
    splitArea: {
      show: false,
    },
    axisLine: {
      lineStyle: {
        color: '#00b4d8',
        width: 2,
      },
    },
  },
  series: [
    {
      type: 'radar',
      barWidth: '100%',
      z: 1,
      data: [
        {
          value: [0.84, 8.7, 9],
          name: 'Current Supplier Range',
          symbol: 'none',
          areaStyle: {
            color: new graphic.LinearGradient(
              240,
              0,
              240,
              320,
              [
                {
                  offset: 0,
                  color: '#df1818',
                },
                {
                  offset: 0.35,
                  color: '#df1818',
                },
                {
                  offset: 0.5,
                  color: '#30b607',
                },
                {
                  offset: 0.65,
                  color: '#df1818',
                },
                {
                  offset: 1,
                  color: '#df1818',
                },
              ],
              true,
            ),
            opacity: 1,
          },
          lineStyle: {
            width: 0,
          },
        },
      ],
    },
    {
      type: 'radar',
      barWidth: '100%',
      lineStyle: {
        type: 'dashed',
        color: '#7ED957',
        width: 2.5,
      },
      data: [
        {
          value: [0.2, 25, 2, 25, 25, 30],
          name: 'Optimal Supplier Range',
          symbol: 'none',
          areaStyle: {
            color: '#0ead69BB',
            lineStyle: {
              width: 0,
            },
            opacity: 0.5,
          },
        },
      ],
    },
  ],
}

export default function Dashboard() {
  const [option, setOption] = React.useState(RadarOption)
  const raderRef = React.useRef<any>(null)

  return (
    <div>
      <div className='h-[320px] w-[320px] sm:w-full'>
      <ReactECharts
        ref={raderRef}
        option={option}
        className='!h-[320px] w-full'
        opts={{ renderer: 'svg' }}
        notMerge
      />
    </div>
    </div>
  )
}
