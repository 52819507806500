import React, { useEffect, useState } from 'react';
import api from '../../../lib/api';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Plus, ImportIcon, Download } from 'lucide-react';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { useToast } from '../../../components/ui/use-toast';
import {
  
  User,
} from 'lucide-react';
export default function EditUser() {
  const [users, setUsers] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterOption, setFilterOption] = useState('all');


  const { toast } = useToast();
  const init_user = {
    username: '',
    lastname: '',
    email: '',
    phonenumber: '',
    role: 'User', // Set default role to 'User'
    password: '',
  };

  const [user, setUser] = useState(init_user);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    api.get(`/superadmin/getUsers`).then((res) => {
      setUsers(res || []);
      console.log(res)
    });
  }, []);

  const handleAdd = () => {
    
    api
      .post('/superadmin/user/add', { ...user })
      .then((data) => {
        setUser({
          username: '',
          lastname: '',
          email: '',
          phonenumber: '',
          role: '', // Set default role to 'User'
          password: '',
        });
        setOpen(false);
        api.get(`/superadmin/getUsers`).then((res) => {
          setUsers(res || []);
          // console.log(res)
        });
     //   setUsers([...users, data]);
      })
      .catch((error) => {
        if (error.response.data.status === 400)
          toast({
            title: 'Register is Failed!',
            description: error.response.data.message,
          });
        else if (error.response.status === 403) {
          let message = '';
          if (error.response.data.errors[0].path === 'username')
            message = 'Username must be more than 4 characters';
          else if (error.response.data.errors[0].path === 'password')
            message = 'Password must be 6 at mins';
          else if (error.response.data.errors[0].path === 'email')
            message = 'Invalid Email address';
          toast({ title: 'Register is Failed!', description: message });
        }
      });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <div>
        <div className='flex'>
          <button
            className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
            onClick={() => {
              setOpen(true);
            }}
          >
            <Plus className='w-5' />
            Add user
          </button>

          <button
            className='flex justify-center items-center focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 mr-2'
            // onClick={() => {
            //   setOpen(true);
            // }}
          >
            <ImportIcon className='w-5 mr-2' />
            Import from CSV
          </button>

          <button
            className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
            // onClick={() => {
            //   setOpen(true);
            // }}
          >
            <Download className='w-5 mr-2' />
            Export to CSV
          </button>

          <select
  value={filterOption}
  onChange={(e) => setFilterOption(e.target.value)}
  className='flex justify-center items-center focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
>
  <option value='all'><Plus className='w-5'   style={{color:'white'}}/>
 All Users</option>
  <option value='active'><Plus className='w-5' />
 Active Users</option>
  <option value='inactive'><Plus className='w-5' />
 Inactive Users</option>
</select>


        </div>
        <Input className='my-4' placeholder='Search the user name...'           onChange={handleSearch}
/>
        <div className='overflow-x-auto'>
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
              Our members
              <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
                Browse a list of Flowbite members designed to help you work and
                play, stay organized, get answers, keep in touch, grow your
                business, and more.
              </p>
            </caption>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='px-6 py-3'>
                  Email
                </th>
                <th scope='col' className='px-6 py-3'>
                  First Name
                </th>
                <th scope='col' className='px-6 py-3'>
                  Last Name
                </th>
                <th scope='col' className='px-6 py-3'>
                Phone Number
                </th>
                <th scope='col' className='px-6 py-3'>
                  Status
                </th>
                <th scope='col' className='px-6 py-3'>
                  Role
                </th>
                <th scope='col' className='px-6 py-3'>
                  Edit
                </th>
              </tr>
            </thead>
            <tbody>
              {users.filter((user: any) => {
    if (filterOption === 'active') {
      return user.status === 'active';
    } else if (filterOption === 'inactive') {
      return user.status === 'unactive';
    } else {
      return true; // Show all users when filter option is 'all'
    }
  })
                .map((user: any, index: number) => (
                  <tr
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                    key={index}
                  >
                    <th
                      scope='row'
                      className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                    >
                      {user.email?user.email:""}
                    </th>
                    <td className='px-6 py-4'>{user.username}</td>
                    <td className='px-6 py-4'>{user.lastname}</td>
                    <td className='px-6 py-4'>{user.phonenumber}</td>



                    <td className='px-6 py-4'>
                      {user.status === 'unactive' ? 'Unactive' : 'Active'}
                    </td>
                    <td className='px-6 py-4 text-left'>
          <select
            value={user.role}
            onChange={(e) => {
              const newRole = e.target.value;
              api
                .post('/auth/changeUserRole', {
                  user_id: user._id,
                  role: newRole,
                })
                .then(() => {
                  toast({
                    title: 'Role is Changed!',
                  });
                  setUsers((prevUsers: any) =>
                    prevUsers.map((prevUser: any) =>
                      prevUser._id === user._id
                        ? { ...prevUser, role: newRole }
                        : prevUser
                    )
                  );
                });
            }}
          >
            <option value='user'>User</option>
            <option value='admin'>Admin</option>
          </select>
        </td>
                    <td className='px-6 py-4 text-left'>
                      <button
                        onClick={() => {
                          api
                            .post('/superadmin/user/activeUser', {
                              user_id: user._id,
                            })
                            .then(() => {
                              setUsers(
                                users.map((item: any) => {
                                  if (item._id === user._id) {
                                    item.status =
                                      item.status === 'unactive'
                                        ? 'active'
                                        : 'unactive';
                                  }
                                  return item;
                                })
                              );
                            });
                        }}
                        className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300'
                      >
                        {user.status === 'unactive' ? 'Active' : 'Unactive'}
                      </button>
                      <button
                        className='bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300'
                        onClick={() => {
                          api
                            .post('/superadmin/user/delete', { id: user._id })
                            .then(() => {
                              setUsers(
                                users.filter(
                                  (item: any) => item._id !== user._id
                                )
                              );
                            });
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>Add User</DialogTitle>
            <DialogDescription>Please input the user detail</DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='email'>Email </Label>
              <Input
                value={user.email}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, email: e.target.value }))
                }
                id='email'
                placeholder='john.doe@example.com'
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='username'>Username</Label>
              <Input
                value={user.username}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, username: e.target.value }))
                }
                id='username'
                placeholder='johndoe'
              />
            </div>
            <div className='mt-2 flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='password'>Password</Label>
              <Input
                value={user.password}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, password: e.target.value }))
                }
                type='password'
                id='password'
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              onClick={handleAdd}
              // disabled={!user.password && !user.username}
              type='submit'
            >
              Add User
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog> */}
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>Add User</DialogTitle>
            <DialogDescription>Please input the user detail</DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='firstName'>First Name</Label>
              <Input
                value={user.username}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, username: e.target.value }))
                }
                id='firstName'
                placeholder='John'
              />
            </div>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='lastName'>Last Name</Label>
              <Input
                value={user.lastname}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, lastname: e.target.value }))
                }
                id='lastName'
                placeholder='Doe'
              />
            </div>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='email'>Email</Label>
              <Input
                value={user.email || ''}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, email: e.target.value }))
                }
                id='email'
                placeholder='john.doe@example.com'
              />
            </div>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='phoneNumber'>Phone Number</Label>
              <Input
                value={user.phonenumber}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, phonenumber: e.target.value }))
                }
                id='phoneNumber'
                placeholder='1234567890'
              />
            </div>
         <div className='flex flex-col gap-3 space-y-1.5'>
  <Label htmlFor='role'>Role</Label>
  <select
    value={user.role}
    onChange={(e) =>
      setUser((prev) => ({ ...prev, role: e.target.value }))
    }
    id='role'
    className='px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
  >
    <option value='User'>User</option>
    <option value='Admin'>Admin</option>
  </select>
</div>


            <div className='mt-2 flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='password'>Password</Label>
              <Input
                value={user.password}
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, password: e.target.value }))
                }
                type='password'
                id='password'
              />
            </div>
          </div>
          <DialogFooter>
            <Button onClick={handleAdd}>Add User</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

    </>
  );
}
