import React, { useEffect, useState } from 'react';
import api from '../../../lib/api';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Plus, ImportIcon, Download } from 'lucide-react';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { useToast } from '../../../components/ui/use-toast';
import {
  User,
} from 'lucide-react';

interface UserData {
  organization: {
    _id: string;
    name: string;
  };
}

function Notifications() {
  const [data, setData] = useState([]);
  const [orgdata, setOrgData] = useState<UserData[]>([]); 
   const [searchQuery, setSearchQuery] = useState('');
  const { toast } = useToast();

  const fetchData = () => {
    api.get(`/superadmin/getNotification`)
      .then((res) => {
        setData(res);
        console.log(res);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const fetchOrgData = () => {
    api.get(`/superadmin/getUsers`)
      .then((res) => {
        setOrgData(res);
        console.log(res);
      })
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(() => {
    fetchData();
    fetchOrgData();
  }, []);

  const handleDelete = async (id: string) => {
    try {
      await api.delete('/superadmin/deleteNotification', { id });
      fetchData();
      toast({
        title: 'Notification',
        description: 'Notification has been deleted successfully!',
      });
    } catch (error) {
      toast({
        title: 'Failed to Delete Notification',
        description: 'An error occurred while deleting the Notification.',
      });
      console.error(error);
    }
  };

  const filteredData = data.filter((notification:any) => {
    const userNames = notification.userNames.join(', ').toLowerCase();
    const organizationName = notification.organizationName?.toLowerCase() || '';
    const searchLower = searchQuery.toLowerCase();
    return userNames.includes(searchLower) || organizationName.includes(searchLower);
  });

  return (
    <div>
      <div>
        <div className='flex'>
          <Input className='my-4' placeholder='Search By User & Organization Name...' onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
        <div className='overflow-x-auto'>
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
              Notifications
              <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
                Browse a list of notifications to see user and organization registrations.
              </p>
            </caption>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='px-6 py-3'>
                  Notification
                </th>
                <th scope='col' className='px-6 py-3'>
                  Date
                </th>
                <th scope='col' className='px-6 py-3'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
            {filteredData.map((notification: any) => {
    const getOrganizationName = () => {
        const matchingOrg = orgdata.find((user:any) => user.organization._id === notification.organizationId);
        return matchingOrg ? matchingOrg.organization.name : ''; // Retrieve organization name if found
    }

    const organizationName = getOrganizationName();

    return (
        <tr key={notification._id}>
            <td className='px-6 py-4'>
                <div className='flex items-center space-x-2'>
                    <User className='w-6 h-6' />
                    {notification.userNames.length > 0 && (
                        <p>
                            {organizationName && `${organizationName} organization add `}
                            New User
                            <span style={{ color: 'black', fontWeight: '600' }}> {notification.userNames.join(', ')}{' '} </span>{!organizationName ? "Registered" : ""}  
                            {notification.userNames.length > 1 ? 'have' : ''} {' '}
                            {notification.messageType === 'New Organization Registration'
                                ? `with the ${notification.organizationName} Organization`
                                : ''}
                        </p>
                    )}
                </div>
            </td>
            <td className='px-6 py-4'>
                {new Date(notification.createdAt).toLocaleDateString('en-GB')}
            </td>
            <td className='px-6 py-4'>
                <Button onClick={() => handleDelete(notification._id)}>Delete</Button>
            </td>
        </tr>
    );
})}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Notifications;
