import { Routes, Route } from 'react-router-dom';

import {
  AboutUsPage,
  BlogPage,
  LandingPage,
  LoginPage,
  FaqsPage,
  RegisterPage,
  RegisterOrganizationPage,
  PricePlansPage,
  ResetPasswordPage,
  ChangePasswordPage,
  VerifyemailPage, // Corrected spelling here
} from '../pages/landing';
import NoRoutePage from '../components/no_route_page';

export default function IndexRouter() {
  return (
    <Routes>
      <Route path='/' element={<LandingPage />} />
      <Route path='/blogs' element={<BlogPage />} />
      <Route path='/price_plans' element={<PricePlansPage />} />
      <Route path='/about' element={<AboutUsPage />} />
      <Route path='/faqs' element={<FaqsPage />} />
      <Route path='/login' element={<LoginPage />} />
      <Route path='/register' element={<RegisterPage />} />
      <Route path= '/register1' element={<VerifyemailPage />} /> {/* Corrected path here */}
      <Route path='/reset_password' element={<ResetPasswordPage />} />
      <Route path='/change_password/verify' element={<ChangePasswordPage />} />
      <Route
        path='/register_organization'
        element={<RegisterOrganizationPage />}
      />
      <Route path='*' element={<NoRoutePage />} />
    </Routes>
  );
}
