import React, { useEffect, useState } from 'react';
import api from '../../../lib/api';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Plus, ImportIcon, Download } from 'lucide-react';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { useToast } from '../../../components/ui/use-toast';
import { AnyARecord } from 'dns';



interface DropdownStyle {
  position: 'absolute';
  top: string;
  // Add other style properties if needed
}

export default function MannageProductuser() {
    const [users, setUsers] = useState<any[]>([]);
    
    const [orgid, setOrgid] = useState<string>('');
     const [userid, setUserid] = useState<string>('');
      const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
      const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);

      const [data , setData] = useState<any>([])
      const [searchQuery, setSearchQuery] = useState<string>('');

    const [open, setOpen] = useState(false);
    const { toast } = useToast();

    const [dropdownStyle, setDropdownStyle] = useState<DropdownStyle>({
      position: 'absolute',
      top: '85px',
    });
  

    useEffect(() => {
      const fetchUsers = async () => {
        try {
          const res = await api.get(`/user/getshopProduct?true`);
          setData(res);
         
          setFilteredUsers(res);
        } catch (error) {
          console.error(error);
          // Handle error if needed
        }
      }
        const fetchOrgId = async () => {
          try {
            const res = await api.get(`/auth/getUser`);
            setOrgid(res.organization._id);
            setUserid(res._id);
          } catch (error) {
            console.error(error);
            // Handle error if needed
          }
        };
    
        fetchUsers();
        fetchOrgId();
      }, [searchQuery]);
  
   
      const handleFilterButtonClick = () => {
        const newTopPosition = window.innerWidth > 768 ? '85px' : '150px';
        setDropdownStyle({
            position: 'absolute', 
            top: newTopPosition,
          });
        setIsFilterDropdownOpen(!isFilterDropdownOpen);
      };

      const handleSearch = (searchText: string) => {
        const filtered = data.filter((user:any) =>
          user.productName && user.productName.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredUsers(filtered);
      };
      
 
  return (
    <>
  
  <section className="bg-gray-50 dark:bg-gray-900 w-100 flex items-center mb-5">
 <div className=" px-0 mx-auto  w-full">
   <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
     <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
       <div className="w-full md:w-1/2">
         <form className="flex items-center">
           <label htmlFor="simple-search" className="sr-only">Search</label>
           <div className="relative w-full">
             <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
               <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                 <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
               </svg>
             </div>
             <input type="text" id="simple-search"
              placeholder="Search"
               
onChange={(e) => handleSearch(e.target.value)}             
             className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" />
           </div>
         </form>
       </div>
       <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full  md:w-auto md:flex-row  md:items-center md:space-x-3">
      
         <div className="flex items-center w-full space-x-3 md:w-auto">
         
           <div id="actionsDropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
             <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="actionsDropdownButton">
               <li>
                 <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Mass Edit</a>
               </li>
             </ul>
             <div className="py-1">
               <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete all</a>
             </div>
           </div>
           <button id="filterDropdownButton" data-dropdown-toggle="filterDropdown"
                onClick={handleFilterButtonClick}

           className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
             <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="w-4 h-4 mr-2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
               <path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd" />
             </svg>
             Filter
             <svg className="-mr-1 ml-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
               <path clip-rule="evenodd" fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
             </svg>
           </button>
           <div  id="filterDropdown"
     className={`${
       isFilterDropdownOpen ? 'block' : 'hidden'
     } z-10 w-36 p-3 bg-white rounded-lg shadow dark:bg-gray-700`}
     style={dropdownStyle}>
             <h6 className="mb-3 text-sm font-medium text-gray-900 dark:text-white">
               Category
             </h6>
             <ul className="space-y-2 text-sm" aria-labelledby="dropdownDefault">
               <li className="flex items-center">
                 <input id="apple" type="checkbox" value=""
                   className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                 <label htmlFor="apple" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                   Apple (56)
                 </label>
               </li>
               <li className="flex items-center">
                 <input id="fitbit" type="checkbox" value=""
                   className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                 <label htmlFor="fitbit" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                   Fitbit (56)
                 </label>
               </li>
               <li className="flex items-center">
                 <input id="dell" type="checkbox" value=""
                   className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                 <label htmlFor="dell" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                   Dell (56)
                 </label>
               </li>
               <li className="flex items-center">
                 <input id="asus" type="checkbox" value="" checked
                   className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                 <label htmlFor="asus" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                   Asus (97)
                 </label>
               </li>
             </ul>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
</section>
 <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 " style={{justifyContent:'center'}}>
 {filteredUsers.map((item: {
 [x: string]: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; 
 productName: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined; 
}, index: React.Key | null | undefined) => (
 <div key={index} className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
   <div className="px-5 pb-5">

   <div > 
           <div>   
           <h5 className="text-xl pt-2 font-semibold tracking-tight text-gray-900 dark:text-white">{item.productName as string} </h5>
           <p>{item.description} </p> 
         </div>
        
       
         </div>

  
     {item.price !== undefined && (
       <div className="flex items-center justify-between">
         <span className="text-3xl font-bold text-gray-900 dark:text-white">${item.price as string}</span>
         <span> <Button>Add to Cart</Button> </span>
       </div>
     )}
   </div>
 </div>
))}

 </div>

</>

  );
}
