import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from '../../components/ui/card';
import { Label } from '../../components/ui/label';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import { Link } from 'react-router-dom';
import api from '../../lib/api';
import { toast, useToast } from '../../components/ui/use-toast';

export default function ChangePassword() {
  const [inputs, setInputs] = useState({ password: '', password_confirm: '' });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const { toast } = useToast();

  const changePassword = () => {
    api
      .post('/auth/change_password', { ...inputs, token })
      .then(() => {
        toast({ title: 'Password was reset successfully!' });
        setTimeout(() => {
          window.location.href = '/login';
        }, 2000);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return toast({
            title: 'Action unsuccessful',
            description: error.response.data.message,
          });
        }
        toast({
          title: 'Action unsuccessful',
          description:
            error.response.data.errors[0].path === 'password'
              ? 'Password must be 6 at mins'
              : 'Password confirm must be 6 at mins',
        });
      });
  };

  useEffect(() => {
    if (!token) {
      toast({ title: 'Please check if the token is valid!' });
      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    }
  }, [token]);

  return (
    <div className='flex h-screen flex-col items-center'>
      <Card className='mt-48 w-[380px] py-5 max-[900px]:mt-10 max-[400px]:w-[95%]'>
        <CardHeader>
          <CardTitle>Welcome back</CardTitle>
          <CardDescription>Please reset your password!</CardDescription>
        </CardHeader>
        <CardContent>
          <form>
            <div className='mt-1 grid w-full items-center gap-4'>
              <div className='flex flex-col gap-3 space-y-1.5'>
                <Label htmlFor='current_password'>New Password</Label>
                <Input
                  required
                  value={inputs.password}
                  type='password'
                  onChange={(e) =>
                    setInputs((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                  id='current_password'
                  placeholder=''
                />
              </div>
              <div className='flex flex-col gap-3 space-y-1.5'>
                <Label htmlFor='old_password'>Password Confirm</Label>
                <Input
                  required
                  value={inputs.password_confirm}
                  type='password'
                  onChange={(e) =>
                    setInputs((prev) => ({
                      ...prev,
                      password_confirm: e.target.value,
                    }))
                  }
                  id='old_password'
                  placeholder=''
                />
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className='mt-1 flex'>
          <Button
            onClick={changePassword}
            variant='custom'
            className='w-full'
            disabled={!inputs.password || !inputs.password_confirm}
          >
            Change
          </Button>
        </CardFooter>
      </Card>
      <span className='mt-6 text-sm'>
        Do you have account?{' '}
        <Link className='text-neutral-400' to='/login'>
          Sign in
        </Link>{' '}
      </span>
    </div>
  );
}
