import React, { useEffect, useState } from "react";
import api from "../../../lib/api";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Plus, ImportIcon, Download, UserPlus  , Star , Trash2 , Pencil} from "lucide-react";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import { useToast } from "../../../components/ui/use-toast";


interface DropdownStyle {
    position: 'absolute';
    top: string;
    // Add other style properties if needed
  }

  type UserData = {
    _id: string;
    productName: string;
    description?: string;
    price?: number;
    // Add more properties if needed
  };
  

export default function MannageProdcut() {
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);

    const [prodName, setProdname] = useState<string>('');
    const [proddescription, setProddescription] = useState<string>('');
    const [filteredUsers, setFilteredUsers] = useState<any[]>([]);

    const [prodimage, setProdimage] = useState<string>('');
    const [orgId , setOrgid] = useState<string | object>('');
    const [userId, setUserId] = useState<string>('');
    const [prodId, setProdId] = useState<string>('');

    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
    const [dropdownStyle, setDropdownStyle] = useState<DropdownStyle>({
        position: 'absolute',
        top: '85px',
      });

     

     const [prodprice, setProdprice] = useState<number | string>(0);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [data , setData] = useState<any>([])



    const handleFilterButtonClick = () => {
        const newTopPosition = window.innerWidth > 768 ? '85px' : '150px';
        setDropdownStyle({
            position: 'absolute', 
            top: newTopPosition,
          });
        setIsFilterDropdownOpen(!isFilterDropdownOpen);
      };


      useEffect(() => {
        const fetchData = async () => {
          try {
            const orgRes = await api.get(`/auth/getUser`);
            console.log("orgRes", orgRes);
            setOrgid(orgRes.organization._id);
            setUserId(orgRes._id);
      
            // Now that you have orgId, fetch user data
            const userRes = await api.get(`/admin/getinventoryProduct`);
            setData(userRes);
            setFilteredUsers(userRes);
          } catch (error) {
            console.error(error);
          }
        };
      
        fetchData(); // Call the combined fetch function
      
      }, []);
      

     

const AddProduct = () => {
    if (!prodName || !proddescription || !prodprice) {
      return;
    }
  
    const payload = {
      productName: prodName,
      description: proddescription,
      price: prodprice.toString(),
      organizationId: orgId,
      userId: userId,
      category: 'inventory',
    };
  
    api.post('/admin/createinventoryProduct', payload)
      .then(async (response) => {
        console.log('Product added successfully:', response);
  
        try {
          const res = await await api.get(`/admin/getinventoryProduct`);
          setData(res);
          setFilteredUsers(res);

        } catch (error) {
          console.error('Error fetching updated data:', error);
        }
  
        setOpen(false);
        setProdname('');
        setProddescription('');
        setProdprice(0);
        setProdimage('');
        setSelectedImage(null);
      })
      .catch((error) => {
        console.error('Error adding product:', error);
        // Handle error if needed
      });
  };



  const handleDelete = async (inventoryProductId: string |  undefined) => {
    try {
      const response = await api.delete("/admin/deleteinventoryProduct", {
        
            inventoryProductId: inventoryProductId
      });
  
      console.log("Product deleted successfully:", response);
  
      // Fetch the updated data after deletion
      try {
        const res = await api.get(`/admin/getinventoryProduct`)
        setData(res);
        setFilteredUsers(res);

      } catch (error) {
        console.error('Error fetching updated data:', error);
      }
    } catch (error) {
      console.error("Error deleting product:", error);
      // Handle error if needed
    }
  };
  
  const handleEdit = (product: any) => {
    setProdname(product.productName);
    setProddescription(product.description);
    setProdprice(product.price);
     setProdId(product._id)

    setOpen1(true);
};

 

 const handleEditSubmit = async () => {
    const payload = { 
          _id : prodId ,
        productName: prodName,
        description: proddescription,
        price: prodprice.toString(),
        organizationId: orgId,
        userId: userId,
        category: 'inventory',
    } 
    const response = await api.put("/admin/updateinventoryProduct", payload)
        .then(async (response) => {
      console.log('Product added successfully:', response);
    
      try {
        const res = await api.get(`/admin/getinventoryProduct`)
        setData(res);
        setOpen(false);
        setOpen1(false);
        window.location.reload();
      } catch (error) {
        console.error('Error fetching updated data:', error);
      }
    })
      .catch((error) => {
        console.error('Error adding product:', error);
        // Handle error if needed
      });
  
  };
   


  const handleSearch = (searchText: string) => {
    const filtered = data.filter((user:any) =>
      user.productName && user.productName.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredUsers(filtered);
  };




  return (
    
     <>   
  
   <section className="bg-gray-50 dark:bg-gray-900 w-100 flex items-center mb-5">
  <div className=" px-0 mx-auto  w-full">
    <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
      <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
        <div className="w-full md:w-1/2">
          <form className="flex items-center">
            <label htmlFor="simple-search" className="sr-only">Search</label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                </svg>
              </div>
              <input type="text"
              onChange={(e) => handleSearch(e.target.value)}             

              id="simple-search" className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search"  />
            </div>
          </form>
        </div>
        <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full  md:w-auto md:flex-row  md:items-center md:space-x-3">
        <button
            className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
            onClick={() => {
                setOpen(true);

            }}
          >
            <Plus className='w-5' />
            Add Product
          </button>
          <div className="flex items-center w-full space-x-3 md:w-auto">
          
            <div id="actionsDropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
              <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="actionsDropdownButton">
                <li>
                  <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Mass Edit</a>
                </li>
              </ul>
              <div className="py-1">
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete all</a>
              </div>
            </div>
            <button id="filterDropdownButton" data-dropdown-toggle="filterDropdown"
                 onClick={handleFilterButtonClick}

            className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="w-4 h-4 mr-2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd" />
              </svg>
              Filter
              <svg className="-mr-1 ml-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path clip-rule="evenodd" fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
            </button>
            <div  id="filterDropdown"
      className={`${
        isFilterDropdownOpen ? 'block' : 'hidden'
      } z-10 w-36 p-3 bg-white rounded-lg shadow dark:bg-gray-700`}
      style={dropdownStyle}>
              <h6 className="mb-3 text-sm font-medium text-gray-900 dark:text-white">
                Category
              </h6>
              <ul className="space-y-2 text-sm" aria-labelledby="dropdownDefault">
                <li className="flex items-center">
                  <input id="apple" type="checkbox" value=""
                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="apple" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                    Apple (56)
                  </label>
                </li>
                <li className="flex items-center">
                  <input id="fitbit" type="checkbox" value=""
                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="fitbit" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                    Fitbit (56)
                  </label>
                </li>
                <li className="flex items-center">
                  <input id="dell" type="checkbox" value=""
                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="dell" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                    Dell (56)
                  </label>
                </li>
                <li className="flex items-center">
                  <input id="asus" type="checkbox" value="" checked
                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="asus" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                    Asus (97)
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 " style={{justifyContent:'center'}}>
  
  {filteredUsers.map((item: UserData, index: React.Key | null | undefined) => (
  <div key={index} className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <div className="px-5 pb-5">
      {item.productName && (
        <div>
          <h5 className="text-xl pt-2 font-semibold tracking-tight text-gray-900 dark:text-white">{item.productName as string}</h5>
          <p>{item.description}</p>
        </div>
      )}
      {item.price !== undefined && (
        <div className="flex items-center justify-between">
          <span className="text-3xl font-bold text-gray-900 dark:text-white">${item.price as number}</span>
          <div className="flex items-center justify-between">
            <span className="text-black " style={{ cursor: 'pointer' }}   onClick={() =>handleDelete (item._id)}  ><Trash2 /></span>
            <span className="text-black mx-4" style={{ cursor: 'pointer' }} onClick={() => handleEdit(item)}>
              <Pencil />
            </span>
          </div>
        </div>
      )}
    </div>
  </div>
))}


  </div>
  <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>Add Product</DialogTitle>
            <DialogDescription>Please input the product detail</DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
          <div className='flex flex-col gap-3  space-y-1.5'>
              <Label > Product Name </Label>
              <Input
              value={prodName}
               onChange={(e)=>setProdname(e.target.value)}
              />
            </div>
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label >Price</Label>
              <Input
                 value={prodprice}
                 onChange={(e)=>setProdprice(e.target.value)}
              />
            </div>
            <div className='mt-2 flex flex-col gap-3 space-y-1.5'>
              <Label > Description</Label>
              <Input
                 value={proddescription}
                 onChange={(e)=>setProddescription(e.target.value)}
              />
            </div>
           
          </div>
          <DialogFooter>
            <Button
              type='submit'
              onClick={AddProduct}
            >
              Add Product
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={open1} onOpenChange={(val) => setOpen1(val)}>
  <DialogContent className='sm:max-w-[495px]'>
    <DialogHeader>
      <DialogTitle>Edit Product Details</DialogTitle>
      <DialogDescription>Please input the product detail</DialogDescription>
    </DialogHeader>
    <div className='grid gap-4 py-4'>
      <div className='flex flex-col gap-3 space-y-1.5'>
        <Label>Product Name</Label>
        <Input
          value={prodName}
          onChange={(e) => setProdname(e.target.value)}
        />
      </div>
      <div className='flex flex-col gap-3 space-y-1.5'>
        <Label>Price</Label>
        <Input
          value={prodprice}
          onChange={(e) => setProdprice(e.target.value)}
        />
      </div>
      <div className='mt-2 flex flex-col gap-3 space-y-1.5'>
        <Label>Description</Label>
        <Input
          value={proddescription}
          onChange={(e) => setProddescription(e.target.value)}
        />
      </div>
      {/* Additional fields if needed */}
    </div>
    <DialogFooter>
      <Button
        type='submit'
        onClick={handleEditSubmit} // Update this to your edit API call function
      >
        Save Changes
      </Button>
    </DialogFooter>
  </DialogContent>
</Dialog>


</>

    
  );
}
