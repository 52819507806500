import { Routes, Route } from 'react-router-dom';

import Dashboard from '../pages/main/user/dashboard';
import ViewFaqs from '../pages/main/user/view_faqs';
import EditProfile from '../pages/main/admin/edit_profile';
import ExporeCareers from '../pages/main/user/expore_careers';
import ViewEvents from '../pages/main/user/view_events';
import Chatting from '../pages/main/user/chatting';
import ViewGalleryIndex from '../pages/main/user/view_gallery';
import ViewGalleryEdit from '../pages/main/user/view_gallery/edit';
import MannagePass from '../pages/main/user/MannagePass';
import ManageReligionGroups from '../pages/main/user/manage_religion_groups';
import 'rsuite/dist/rsuite.min.css';
import Managecomnity from '../pages/main/user/manage_comunity'
import MainLayout from '../layouts/main';
import NoRoutePage from '../components/no_route_page';
import ViewTea from '../pages/main/user/viewTea'
import Handicapped from '../pages/main/user/Handicapped_user'
import { useSelector } from 'react-redux';
import { type RootState } from '../redux/store';
import ManageVolunteerActivities from '../pages/main/user/manage_volunteer_activities';
import ManagePrayer from '../pages/main/user/manage_prayer';
import ManageFinance from '../pages/main/user/manage_finance';
import ViewGolf from '../pages/main/user/viewGolf';
import MannageProductuser from '../pages/main/user/mannageProd_user'
import  MannageMaintenance from '../pages/main/user/mannage_maintenance'
import ViewNomination from  '../pages/main/user/viewNomination';
import MannagePayment from '../pages/main/user/mannagePayment'
import MannageBooking from '../pages/main/user/mannage_booking';
export default function UserRouter() {
  const organization = useSelector(
    (root: RootState) => root.auth.user.organization
  );

  return (
    <MainLayout>
      <Routes>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/dashboard/edit_profile' element={<EditProfile />} />
        <Route path='/dashboard/read_faqs' element={<ViewFaqs />} />
        <Route path='/dashboard/expore_careers' element={<ExporeCareers />} />
        <Route path='/dashboard/view_events' element={<ViewEvents />} />
        <Route path='/dashboard/message' element={<Chatting />} />
        {organization.category === 'social' && (
          <>
            <Route
              path='/dashboard/view_gallery'
              element={<ViewGalleryIndex />}
            />
            <Route
              path='/dashboard/view_gallery/:id'
              element={<ViewGalleryEdit />}
            />
          </>
        )}
        {organization.category === 'religion' && (
          <>
            <Route
              path='/dashboard/manage_religion_groups'
              element={<ManageReligionGroups />}
            />
            <Route
              path='/dashboard/manage_volunteer_activities'
              element={<ManageVolunteerActivities />}
            />
            <Route
              path='/dashboard/manage_finance'
              element={<ManageFinance />}
            />
            <Route path='/dashboard/manage_prayer' element={<ManagePrayer />} />
          </>
        )}
        {organization.category === 'political' && (
          <>
            <Route
              path='/dashboard/manage_religion_groups'
              element={<ManageReligionGroups />}
            />
            <Route
              path='/dashboard/manage_volunteer_activities'
              element={<ManageVolunteerActivities />}
            />
              <Route
              path='/dashboard/viewnomination'
              element={<ViewNomination />}
            />
          </>

        )}


{organization.category === 'communal' && (
          <>
            <Route
              path='/dashboard/mannage_maintenance'
              element={<MannageMaintenance />}
            />
            <Route
              path='/dashboard/manage_comunity'
              element={<Managecomnity/>}
            />
            <Route
              path='/dashboard/manage_payment'
              element={<MannagePayment/>}
            />
             <Route
              path='/dashboard/manage_booking'
              element={<MannageBooking/>}
            />
           
          </>
        )}
       
          {organization.category === 'sports' && (
          <>
           <Route
              path='/dashboard/manageteabooking'
              element={<ViewTea />}
            />
            <Route
              path='/dashboard/handicapped'
              element={<Handicapped />}
            />
              <Route
              path='/dashboard/ManagePass'
              element={<MannagePass />}
            />
            <Route
              path='/dashboard/viewgolf'
              element={<ViewGolf />}
            />
            <Route
              path='/dashboard/products'
              element={<MannageProductuser />}
            />
          
          </>
        )}
        <Route path='*' element={<NoRoutePage />} />
      </Routes>
    </MainLayout>
  );
}
