import React, { useEffect } from 'react';

import { Plus } from 'lucide-react';
import api from '../../../lib/api';
import { DatePicker } from 'rsuite';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import Select from 'react-select';

import moment from 'moment';

export default function ManageVolunteerActivities() {
  const initialInput = {
    id: '',
    name: '',
    description: '',
    time: new Date(),
    location: '',
  };

  const [open, setOpen] = React.useState(false);

  const [activities, setActivities] = React.useState<any[]>([]);

  const [input, setInput] = React.useState(initialInput);

  const [open1, setOpen1] = React.useState(false);

  const [assigners, setAssigners] = React.useState<any[]>([]);

  const [users, setUsers] = React.useState<any[]>([]);

  const onShareSave = () => {
    api
      .post('/admin/activity/assign', { id: input.id, assigners })
      .then((data) => {
        setActivities(data);
        setOpen1(false);
      });
  };

  const handleAdd = () => {
    if (input.id !== '') {
      return api.post('/admin/activity/edit', { ...input }).then((data) => {
        setOpen(false);
        setActivities(data);
      });
    }
    api.post('/admin/activity/add', { ...input }).then((data) => {
      setActivities(data);
      setOpen(false);
    });
  };

  useEffect(() => {
    api.get('/admin/activity').then((data) => {
      setActivities(data);
    });
    api.get('/admin/getUsers').then((data) => {
      setUsers(data);
    });
  }, []);

  return (
    <div>
      <div className='flex'>
        <button
          className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
          onClick={() => {
            setInput(initialInput);
            setOpen(true);
          }}
        >
          <Plus className='w-5' />
          Create Activity
        </button>
      </div>

      <div className='overflow-x-auto'>
        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
          <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
            Our activities
            <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
              Browse a list of activities.
            </p>
          </caption>
          <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th className='px-6 py-3'>Name</th>
              <th className='px-6 py-3'>Description</th>
              <th className='px-6 py-3'>Date and Time</th>
              <th className='px-6 py-3'>Location</th>
              <th className='px-6 py-3'>Status</th>
              <th className='px-6 py-3'>Assigners</th>
              <th className='px-6 py-3'>Action</th>
            </tr>
          </thead>
          <tbody>
            {activities.map((activity: any, index: number) => {
              return (
                <tr
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  key={index}
                >
                  <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                    {activity.name}
                  </td>
                  <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                    {activity.description}
                  </td>
                  <td className='px-6 py-4'>
                    {moment(activity.time).format('YYYY-MM-DD HH:mm')}
                  </td>
                  <td className='px-6 py-4'>{activity.location}</td>
                  <td className='px-6 py-4'>{activity.status}</td>
                  <td className='px-6 py-4'>
                    <button
                      onClick={() => {
                        setInput({
                          id: activity._id,
                          description: activity.description,
                          name: activity.name,
                          time: new Date(activity.time),
                          location: activity.location,
                        });
                        setOpen1(true);
                        setAssigners(
                          activity.assingers.map((user: any) => ({
                            label: user.username,
                            value: user._id,
                          }))
                        );
                      }}
                      className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300'
                    >
                      Assign
                    </button>
                  </td>
                  <td className='px-6 py-4 text-left'>
                    <button
                      onClick={() => {
                        setInput({
                          id: activity._id,
                          description: activity.description,
                          name: activity.name,
                          time: new Date(activity.time),
                          location: activity.location,
                        });
                        setOpen(true);
                      }}
                      className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300'
                    >
                      Edit
                    </button>
                    <button
                      className='bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300'
                      onClick={() => {
                        api
                          .post('/admin/activity/delete', { id: activity._id })
                          .then((data) => {
                            setActivities(data);
                          });
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>{input.id ? 'Edit' : 'Add'} Activity</DialogTitle>
            <DialogDescription>
              Please input the activity name and description.
            </DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='activity_name'>Activity Name</Label>
              <Input
                value={input.name}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, name: e.target.value }))
                }
                id='activity_name'
                placeholder='Please input activity name.'
              />
            </div>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='activity_description'>Activity Description</Label>
              <Input
                value={input.description}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, description: e.target.value }))
                }
                id='activity_description'
                placeholder='Please enter the description of the activity.'
              />
            </div>
            <div className=''>
              <Label>Time</Label>
              <DatePicker
                format='yyyy-MM-dd hh:mm aa'
                className='w-full mt-4'
                value={input.time}
                onChange={(date) => {
                  setInput({ ...input, time: date! });
                }}
                showMeridian
              />
            </div>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='activity_location'>Activity Location</Label>
              <Input
                value={input.location}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, location: e.target.value }))
                }
                id='activity_location'
                placeholder='Please enter the location of the activity.'
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              onClick={handleAdd}
              disabled={!input.name || !input.description}
              type='submit'
            >
              {input.id ? 'Update' : 'Add'} Activity
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={open1} onOpenChange={(val) => setOpen1(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>Share Gallery</DialogTitle>
            <DialogDescription>
              Please select the users to share the gallery.
            </DialogDescription>
          </DialogHeader>
          <div className='grid gap-4'>
            <Select
              value={assigners}
              className='w-full'
              placeholder='Select the user to share this gallery.'
              options={users.map((user) => ({
                label: user.username,
                value: user._id,
              }))}
              isMulti
              onChange={(data) => {
                setAssigners(data as any);
              }}
            />
          </div>
          <DialogFooter>
            <Button onClick={onShareSave}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
