import React, { useEffect, useState } from 'react';

import api from '../../../lib/api';
import { Plus } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { Select } from '../../../components/ui/select';
import moment from 'moment';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';

export default function ManagePrayer() {
  const user = useSelector((root: RootState) => root.auth.user);

  const [currentStatus, setCurrentStatus] = useState('prev');

  const initialInput = {
    id: '',
    request: '',
    content: '',
  };

  const [input, setInput] = React.useState(initialInput);

  const [open, setOpen] = React.useState(false);

  const [records, setRecords] = React.useState<any[]>([]);

  const handleAdd = () => {
    api.post('/user/prayer/add', { ...input }).then((data) => {
      setRecords(data);
      setOpen(false);
    });
  };

  useEffect(() => {
    api.get('/user/prayer').then((data: any) => {
      setRecords(data);
    });
  }, []);

  return (
    <div>
      <div className='overflow-x-auto mt-3'>
        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
          <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
            Prayer Requests
            <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
              Browse a list of prayer requests.
            </p>
          </caption>
          <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th className='px-6 py-3'>Request</th>
              <th className='px-6 py-3'>Content</th>
              <th className='px-6 py-3'>Date</th>
              <th className='px-6 py-3'>Submitter</th>
              <th className='px-6 py-3'>Action</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record: any, index: number) => {
              return (
                <tr
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  key={index}
                >
                  <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                    {record.request}
                  </td>
                  <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                    {record.content}
                  </td>
                  <td className='px-6 py-4'>
                    {moment(record.time).format('YYYY-MM-DD')}
                  </td>
                  <td className='px-6 py-4'>{record.submitter.username}</td>
                  <td className='px-6 py-4'>
                    <button
                      className='bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300'
                      onClick={() => {
                        api
                          .post('/user/prayer/share', { id: record._id })
                          .then((data) => {
                            setRecords(data);
                          });
                      }}
                    >
                      {!record.shared ? 'Share' : 'Unshare'}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>
              {input.id ? 'Edit' : 'Add'} Prayer Request
            </DialogTitle>
            <DialogDescription>Please input the request.</DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='activity_name'>Request</Label>
              <Input
                value={input.request}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, request: e.target.value }))
                }
                id='activity_name'
                placeholder='Please input request.'
              />
            </div>
            <div className='flex flex-col gap-3 space-y-1.5'>
              <Label htmlFor='activity_description'>Content</Label>
              <Input
                value={input.content}
                onChange={(e) =>
                  setInput((prev) => ({ ...prev, content: e.target.value }))
                }
                id='activity_description'
                placeholder='Please enter the content of the request.'
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              onClick={handleAdd}
              disabled={!input.request || !input.content}
              type='submit'
            >
              {input.id ? 'Update' : 'Add'} Record
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
