import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';

import { Settings2, VibrateOff, PencilRuler, HardDriveDownload } from 'lucide-react';

export default function ManageSecurity() {
  const [tab, setTab] = React.useState('tab1');

  return (
    <div>
      <div className='flex justify-end w-full'>
        <button
          className='flex disabled:cursor-not-allowed cursor-pointer text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
        >
          <HardDriveDownload className='mr-2 w-5'/>
          Generate Reports with PDF
        </button>
      </div>
      <Tabs.Root className='TabsRoot' defaultValue='tab1'>
        <Tabs.List
          className='inline-flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400'
          aria-label='Manage your account'
        >
          <Tabs.Trigger
            className={
              tab === 'tab1'
                ? 'inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
                : 'inline-flex p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
            }
            value='tab1'
            onClick={() => {
              setTab('tab1');
            }}
          >
            <Settings2 className='mr-2 w-4' />
            Configure global settings and polices
          </Tabs.Trigger>
          <Tabs.Trigger
            className={
              tab === 'tab2'
                ? 'inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
                : 'inline-flex p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
            }
            value='tab2'
            onClick={() => {
              setTab('tab2');
            }}
          >
            <VibrateOff className='mr-2 w-4' />
            Receive compliance violation notification
          </Tabs.Trigger>
          <Tabs.Trigger
            className={
              tab === 'tab3'
                ? 'inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
                : 'inline-flex p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
            }
            value='tab3'
            onClick={() => {
              setTab('tab3');
            }}
          >
            <PencilRuler className='mr-2 w-4' />
            Conduct periodic access reviews
          </Tabs.Trigger>
        </Tabs.List>
      </Tabs.Root>
    </div>
  );
}
