import './App.css';

import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import IndexRouter from './routes';
import AdminRouter from './routes/admin';
import SuperRouter from './routes/super';

import UserRouter from './routes/user';
import ThemeProvider from './components/theme-provider';
import { Toaster } from './components/ui/toaster';
import { useEffect, useState } from 'react';
import api from './lib/api';
import { UserType } from './types/user';
import { useDispatch } from 'react-redux';
import { login } from './redux/store/auth';
import Loading from './components/loading';

const queryClient = new QueryClient();

function App() {
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState<UserType | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    api
      .get('/auth/getUser')
      .then((data) => {
        setLoading(true);
        setUser(data);
        dispatch(login(data));
      })
      .catch(() => {
        setLoading(true);
      });
  }, []);

  if (!loading) return <Loading />;

  return (
    <div className='font-[Jost]'>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <Toaster />
          <Router>
            {user === null && <IndexRouter />}
            {user?.role === 'admin' && <AdminRouter />}
            {user?.role === 'user' && <UserRouter />}
            {user?.role === 'super_admin' && <SuperRouter />}
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
