import { GroupIcon, Users2, User2, ClockIcon } from 'lucide-react';

import AboutImage from '../../assets/about.jpg';

export default function AboutUs() {
  return (
    <section className='py-10 lg:py-20  font-poppins'>
      <div className='max-w-[1200px] py-4 mx-auto lg:py-6 md:px-6'>
        <div className='flex flex-wrap '>
          <div className='w-full px-4 mb-10 lg:w-1/2 lg:mb-0 '>
            <div className=''>
              <div className='px-4 pl-4 mb-6 border-l-4 border-blue-500'>
                <span className='text-sm text-gray-600 uppercase dark:text-gray-400'>
                  Who we are?
                </span>
                <h1 className='mt-2 text-3xl font-black text-gray-700 md:text-5xl dark:text-gray-300'>
                  About Us
                </h1>
              </div>
              <p className='px-4 mb-10 text-base leading-7 text-gray-500 dark:text-gray-400'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam Lorem ipsum dolor sit amet.
              </p>
              <div className='flex flex-wrap items-center'>
                <div className='w-full px-4 mb-6 sm:w-1/2 md:w-1/2 lg:mb-6'>
                  <div className='p-6 bg-white dark:bg-gray-900'>
                    <span className='text-blue-500 dark:text-blue-400'>
                      <Users2 />
                    </span>
                    <p className='mt-4 mb-2 text-3xl font-bold text-gray-700 dark:text-gray-400'>
                      2097
                    </p>
                    <h2 className='text-sm text-gray-700 dark:text-gray-400'>
                      Projects and Plans
                    </h2>
                  </div>
                </div>
                <div className='w-full px-4 mb-6 sm:w-1/2 md:w-1/2 lg:mb-6'>
                  <div className='p-6 bg-white dark:bg-gray-900'>
                    <span className='text-blue-500 dark:text-blue-400'>
                      <GroupIcon />
                    </span>
                    <p className='mt-4 mb-2 text-3xl font-bold text-gray-700 dark:text-gray-400'>
                      3,590
                    </p>
                    <h2 className='text-sm text-gray-700 dark:text-gray-400'>
                      Helped people
                    </h2>
                  </div>
                </div>
                <div className='w-full px-4 mb-6 sm:w-1/2 md:w-1/2 lg:mb-6'>
                  <div className='p-6 bg-white dark:bg-gray-900'>
                    <span className='text-blue-500 dark:text-blue-400'>
                      <User2 />
                    </span>
                    <p className='mt-4 mb-2 text-3xl font-bold text-gray-700 dark:text-gray-400'>
                      74
                    </p>
                    <h2 className='text-sm text-gray-700 dark:text-gray-400'>
                      Volunteer
                    </h2>
                  </div>
                </div>
                <div className='w-full px-4 mb-6 sm:w-1/2 md:w-1/2 lg:mb-6'>
                  <div className='p-6 bg-white dark:bg-gray-900'>
                    <span className='text-blue-500 dark:text-blue-400'>
                      <ClockIcon />
                    </span>
                    <p className='mt-4 mb-2 text-3xl font-bold text-gray-700 dark:text-gray-400'>
                      100
                    </p>
                    <h2 className='text-sm text-gray-700 dark:text-gray-400'>
                      Timing
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full px-4 mb-10 lg:w-1/2 lg:mb-0'>
            <img
              src={AboutImage}
              alt=''
              className='relative z-40 object-cover w-full h-full rounded'
            />
          </div>
        </div>
      </div>
    </section>
  );
}
