import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { UserType } from "../../types/user";

export interface AuthState {
  user: UserType;
}

const initialState: AuthState = {
  user: {
    username: "",
    avatar: null,
    _id: "",
    email: "",
    createdAt: "",
    organization: {
      _id: '',
      name: '',
      brand: '',
      status: 'unactive',
      apiKey: []
    },
    password: "",
    role: "user",
    updatedAt: "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserType>) => {
      state.user = { ...action.payload };
    },
    updateOrg: (state, action: PayloadAction<any>) => {
      state.user.organization = { ...action.payload };
    }
  },
});

// Action creators are generated for each case reducer function
export const { login, updateOrg } = authSlice.actions;

export default authSlice.reducer;
