import React from 'react'
import * as Tabs from '@radix-ui/react-tabs';

import { LucideHistory, ListFilterIcon, DatabaseBackup, KeyIcon } from 'lucide-react';

export default function ManageSystem() {
  const [tab, setTab] = React.useState('tab1');

  return (
    <Tabs.Root className='TabsRoot' defaultValue='tab1'>
      <Tabs.List
        className='inline-flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400'
        aria-label='Manage your account'
      >
        <Tabs.Trigger
          className={
            tab === 'tab1'
              ? 'inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
              : 'inline-flex p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
          }
          value='tab1'
          onClick={() => {
            setTab('tab1');
          }}
        >
          <LucideHistory className='mr-2 w-4' />
          View Audit Logs
        </Tabs.Trigger>
        <Tabs.Trigger
          className={
            tab === 'tab2'
              ? 'inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
              : 'inline-flex p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
          }
          value='tab2'
          onClick={() => {
            setTab('tab2');
          }}
        >
          <ListFilterIcon className='mr-2 w-4' />
          Receive Reports
        </Tabs.Trigger>
        <Tabs.Trigger
          className={
            tab === 'tab3'
              ? 'inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
              : 'inline-flex p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
          }
          value='tab3'
          onClick={() => {
            setTab('tab3');
          }}
        >
          <DatabaseBackup className='mr-2 w-4' />
          Schedule Migration Settings
        </Tabs.Trigger>
        <Tabs.Trigger
          className={
            tab === 'tab4'
              ? 'inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
              : 'inline-flex p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
          }
          value='tab4'
          onClick={() => {
            setTab('tab4');
          }}
        >
          <KeyIcon className='mr-2 w-4' />
          Set password Policies
        </Tabs.Trigger>
      </Tabs.List>
    </Tabs.Root>
  )
}
