import PubilcLayout from '../../layouts/public';

import Blog from './blogs';
import Faqs from './faqs';
import Landing from './landing';
import Login from './login';
import AboutUs from './about_us';
import Register from './register';
import PricePlans from './price_plans';
import RegisterOrganization from './register_organization';
import ResetPassword from './reset_password';
import ChangePassword from './change_password';
import Verify_email from './Verify_email';

function withPublicLayout(WrappedComponent: React.FC) {
  const ComponentWithLayout = (props: any) => {
    return (
      <PubilcLayout>
        <WrappedComponent {...props} />
      </PubilcLayout>
    );
  };

  return ComponentWithLayout;
}

export const BlogPage = withPublicLayout(Blog);
export const FaqsPage = withPublicLayout(Faqs);
export const LandingPage = withPublicLayout(Landing);
export const AboutUsPage = withPublicLayout(AboutUs);
export const LoginPage = Login;
export const RegisterPage = Register;
export const ResetPasswordPage = ResetPassword;
export const ChangePasswordPage = ChangePassword;
export const RegisterOrganizationPage = RegisterOrganization;
export const PricePlansPage = withPublicLayout(PricePlans);
export const VerifyemailPage = withPublicLayout(Verify_email);
