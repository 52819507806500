import { Routes, Route } from 'react-router-dom';

import Dashboard from '../pages/main/admin/dashboard';
import ManageUser from '../pages/main/admin/manage_user';
import EditProfile from '../pages/main/admin/edit_profile';
import ManageOrganization from '../pages/main/admin/manage_org';
import ManageQueries from '../pages/main/admin/manage_queries';
import ManageApiKeys from '../pages/main/admin/manage_apikeys';
import ChatSupport from '../pages/main/admin/chat_support';
import ManageEvent from '../pages/main/admin/manage_event';
import NoRoutePage from '../components/no_route_page';
import ManageGalleryIndex from '../pages/main/admin/manage_gallery';
import ManageGalleryEdit from '../pages/main/admin/manage_gallery/edit';
import ManageReligionGroups from '../pages/main/admin/manage_religion_groups';
import ManageTeaboking from '../pages/main/admin/manage_teaboking';
import ViewHandicapped from '../pages/main/admin/View_handicapped';
import MannageGuest from '../pages/main/admin/mannage_guest';
import MannegeGolf from '../pages/main/admin/mannage_golflession'
import MainLayout from '../layouts/main';
import MannageProdcut from '../pages/main/admin/mannage_product'
import { useSelector } from 'react-redux';
import { type RootState } from '../redux/store';
import ManageVolunteerActivities from '../pages/main/admin/manage_volunteer_activities';
import ManageFinance from '../pages/main/admin/manage_finance';
import ManagePrayer from '../pages/main/admin/manage_prayer';
import ManageBookings from '../pages/main/admin/manage_bookings';
import MannageShop from '../pages/main/admin/ManageShop'
import MannageMaintenance from '../pages/main/admin/mannage_maintenance';
import MannegeComunity from '../pages/main/admin/manage_comunity';
import MannageNomination from '../pages/main/admin/manage_nomination';
import MannagePayment from '../pages/main/admin/manage_payment'
import MannageReport from '../pages/main/admin/manage_Report';
export default function AdminRouter() {
  const organization = useSelector(
    (root: RootState) => root.auth.user.organization
  );

  return (
    <MainLayout>
      <Routes>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/dashboard/users' element={<ManageUser />} />
        <Route path='/dashboard/MannageReport' element={<MannageReport/>} />
        <Route path='/dashboard/edit_profile' element={<EditProfile />} />
        <Route path='/dashboard/manage_org' element={<ManageOrganization />} />
        <Route path='/dashboard/manage_queries' element={<ManageQueries />} />
        <Route path='/dashboard/manage_apikeys' element={<ManageApiKeys />} />
        <Route path='/dashboard/chat_support' element={<ChatSupport />} />
        <Route path='/dashboard/manage_event' element={<ManageEvent />} />
        {organization.category === 'social' && (
          <>
            <Route
              path='/dashboard/manage_gallery'
              element={<ManageGalleryIndex />}
            />
            <Route
              path='/dashboard/manage_gallery/:id'
              element={<ManageGalleryEdit />}
            />
          </>
        )}
        {organization.category === 'religion' && (
          <>
            <Route
              path='/dashboard/manage_religion_groups'
              element={<ManageReligionGroups />}
            />
            <Route
              path='/dashboard/manage_volunteer_activities'
              element={<ManageVolunteerActivities />}
            />
            <Route
              path='/dashboard/manage_finance'
              element={<ManageFinance />}
            />
            <Route path='/dashboard/manage_prayer' element={<ManagePrayer />} />
          </>
        )}
        {organization.category === 'political' && (
          <>
            <Route
              path='/dashboard/manage_religion_groups'
              element={<ManageReligionGroups />}
            />
            <Route
              path='/dashboard/manage_volunteer_activities'
              element={<ManageVolunteerActivities />}
            />
             <Route
              path='/dashboard/manage_nomination'
              element={<MannageNomination />}
            />
          </>
        )}
        {organization.category === 'communal' && (
          <>
            <Route
              path='/dashboard/manage_bookings'
              element={<ManageBookings />}
            />
             <Route
              path='/dashboard/manage_maintenance'
              element={<MannageMaintenance />}
            />
             <Route
              path='/dashboard/manage_comunity'
              element={<MannegeComunity />}
            />
             <Route
              path='/dashboard/manage_payment'
              element={<MannagePayment />}
            />
          </>
        )}
        
          {organization.category === 'sports' && (
          <>
           <Route
              path='/dashboard/manageteabooking'
              element={<ManageTeaboking />}
            />
            <Route
              path='/dashboard/handicapped'
              element={<ViewHandicapped />}
            />
             <Route
              path='/dashboard/mannage_guest'
              element={<MannageGuest />}
            />
            <Route
              path='/dashboard/mannage_golf'
              element={<MannegeGolf />}
            />
             <Route
              path='/dashboard/mannage_product'
              element={<MannageProdcut />}
            />
             <Route
              path='/dashboard/mannage_shop'
              element={<MannageShop />}
            />
          </>
        )}
        <Route path='*' element={<NoRoutePage />} />
      </Routes>
    </MainLayout>
  );
}
