import { Link } from "react-router-dom";

import {
  Cloud,
  LucideGroup,
  ShieldQuestionIcon,
  KeyIcon,
  TextQuote,
  CalendarCheck,
  BookImageIcon,
  PersonStanding,
  HammerIcon,
  BadgeDollarSign,
  HandMetal,
  BookMarkedIcon,
  Accessibility,
  Coffee,
  UserPlus,
  LandPlot,
  ShoppingCart,
  Warehouse,
  Construction,
  File,
  ScanBarcode,
} from "lucide-react";

import { SheetTrigger } from "../ui/sheet";
import { Org_Category } from "../../types/user";

export default function AdminMenu({ category }: { category: Org_Category }) {
  return (
    <>
      <div style={{ overflowY: "scroll" }}>
        <SheetTrigger asChild>
          <Link
            to="/dashboard/users"
            className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
          >
            <LucideGroup className="mr-2 h-5 w-5" />
            <span>Manage Users</span>
          </Link>
        </SheetTrigger>
        <SheetTrigger asChild>
          <Link
            to="/dashboard/MannageReport"
            className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
          >
            <LucideGroup className="mr-2 h-5 w-5" />
            <span>Manage Report</span>
          </Link>
        </SheetTrigger>
        <SheetTrigger asChild>
          <Link
            to="/dashboard/manage_org"
            className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
          >
            <Cloud className="mr-2 h-5 w-5" />
            <span>Manage Organization</span>
          </Link>
        </SheetTrigger>
        <SheetTrigger asChild>
          <Link
            to="/dashboard/manage_queries"
            className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
          >
            <ShieldQuestionIcon className="mr-2 h-5 w-5" />
            <span>Manage Queries</span>
          </Link>
        </SheetTrigger>
        <SheetTrigger asChild>
          <Link
            to="/dashboard/manage_apikeys"
            className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
          >
            <KeyIcon className="mr-2 h-5 w-5" />
            <span>Manage API Keys</span>
          </Link>
        </SheetTrigger>
        <SheetTrigger asChild>
          <Link
            to="/dashboard/manage_event"
            className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
          >
            <CalendarCheck className="mr-2 h-5 w-5" />
            <span>Manage Events</span>
          </Link>
        </SheetTrigger>
        <SheetTrigger asChild>
          <Link
            to="/dashboard/chat_support"
            className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
          >
            <TextQuote className="mr-2 h-5 w-5" />
            <span>Chat with Support</span>
          </Link>
        </SheetTrigger>
        {category === "social" && (
          <>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/manage_gallery"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <BookImageIcon className="mr-2 h-5 w-5" />
                <span>Manage Photo Gallery</span>
              </Link>
            </SheetTrigger>
          </>
        )}
        {category === "religion" && (
          <>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/manage_religion_groups"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <PersonStanding className="mr-2 h-5 w-5" />
                <span>Manage Groups</span>
              </Link>
            </SheetTrigger>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/manage_finance"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <BadgeDollarSign className="mr-2 h-5 w-5" />
                <span>Manage Finance Contributions</span>
              </Link>
            </SheetTrigger>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/manage_volunteer_activities"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <HammerIcon className="mr-2 h-5 w-5" />
                <span>Manage Volunteer Activities</span>
              </Link>
            </SheetTrigger>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/manage_prayer"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <HandMetal className="mr-2 h-5 w-5" />
                <span>Manage Prayer Request</span>
              </Link>
            </SheetTrigger>
          </>
        )}
        {category === "political" && (
          <>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/manage_religion_groups"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <PersonStanding className="mr-2 h-5 w-5" />
                <span>Manage Groups</span>
              </Link>
            </SheetTrigger>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/manage_volunteer_activities"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <HammerIcon className="mr-2 h-5 w-5" />
                <span>Manage Volunteer Activities</span>
              </Link>
            </SheetTrigger>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/manage_nomination"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <HammerIcon className="mr-2 h-5 w-5" />
                <span>Manage Nomination</span>
              </Link>
            </SheetTrigger>
          </>
        )}
        {category === "communal" && (
          <>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/manage_bookings"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <BookMarkedIcon className="mr-2 h-5 w-5" />
                <span>Manage Bookings</span>
              </Link>
            </SheetTrigger>
            <SheetTrigger asChild>
              <Link
                to="dashboard/manage_maintenance"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <Construction className="mr-2 h-5 w-5" />
                <span>Maintenance</span>
              </Link>
            </SheetTrigger>

            <SheetTrigger asChild>
              <Link
                to="/dashboard/manage_comunity"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <File className="mr-2 h-5 w-5" />
                <span>Community Documents</span>
              </Link>
            </SheetTrigger>

            <SheetTrigger asChild>
              <Link
                to="/dashboard/manage_payment"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <ScanBarcode className="mr-2 h-5 w-5" />
                <span>Payment</span>
              </Link>
            </SheetTrigger>
          </>
        )}
        {category === "sports" && (
          <>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/manageteabooking"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <Coffee className="mr-2 h-5 w-5" />
                <span>Manage Tea Bookings</span>
              </Link>
            </SheetTrigger>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/handicapped"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <Accessibility className="mr-2 h-5 w-5" />
                <span>Handicapped Users</span>
              </Link>
            </SheetTrigger>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/mannage_guest"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <UserPlus className="mr-2 h-5 w-5" />
                <span>Guest Pass</span>
              </Link>
            </SheetTrigger>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/mannage_golf"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <LandPlot className="mr-2 h-5 w-5" />
                <span>Add golf lesson</span>
              </Link>
            </SheetTrigger>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/mannage_product"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <Warehouse className="mr-2 h-5 w-5" />
                <span> Inventory products</span>
              </Link>
            </SheetTrigger>
            <SheetTrigger asChild>
              <Link
                to="/dashboard/mannage_shop"
                className="text-gray-900 flex flex-row items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-800 hover:text-white dark:hover:text-inherit"
              >
                <ShoppingCart className="mr-2 h-5 w-5" />
                <span> Club pro shop </span>
              </Link>
            </SheetTrigger>
          </>
        )}
      </div>
    </>
  );
}
