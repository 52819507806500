'use client';

export default function Faqs() {
  return (
    <div className='min-h-screen md:px-0 px-10'>
      <div className='max-w-[1440px] mx-auto mt-6'>
        <div className="w-full">
          <h5 className='font-semibold text-[2rem] text-center mt-20'>
            Frequently asked questions
          </h5>
          <p className='text-center mt-10 text-[1.3rem] max-w-[800px] mx-auto font-bold'>
            Have a different question and can’t find the answer you’re looking
            for? Reach out to our support team by{' '}
            <span className='text-blue-700 cursor-pointer'>
              sending us an email
            </span>{' '}
            and we’ll get back to you as soon as we can.
          </p>
        </div>
        <div className='mt-20 grid lg:grid-cols-3 md:grid-cols-2 gap-x-10 gap-y-20'>
          <div className=''>
            <div className='font-[600] text-lg'>
              What's the best thing about Switzerland?
            </div>
            <div className='mt-4'>
              I don't know, but the flag is a big plus. Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Quas cupiditate laboriosam
              fugiat.
            </div>
          </div>
          <div className=''>
            <div className='font-[600] text-lg'>
              What's the best thing about Switzerland?
            </div>
            <div className='mt-4'>
              I don't know, but the flag is a big plus. Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Quas cupiditate laboriosam
              fugiat.
            </div>
          </div>
          <div className=''>
            <div className='font-[600] text-lg'>
              What's the best thing about Switzerland?
            </div>
            <div className='mt-4'>
              I don't know, but the flag is a big plus. Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Quas cupiditate laboriosam
              fugiat.
            </div>
          </div>
          <div className=''>
            <div className='font-[600] text-lg'>
              What's the best thing about Switzerland?
            </div>
            <div className='mt-4'>
              I don't know, but the flag is a big plus. Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Quas cupiditate laboriosam
              fugiat.
            </div>
          </div>
          <div className=''>
            <div className='font-[600] text-lg'>
              What's the best thing about Switzerland?
            </div>
            <div className='mt-4'>
              I don't know, but the flag is a big plus. Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Quas cupiditate laboriosam
              fugiat.
            </div>
          </div>
          <div className=''>
            <div className='font-[600] text-lg'>
              What's the best thing about Switzerland?
            </div>
            <div className='mt-4'>
              I don't know, but the flag is a big plus. Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Quas cupiditate laboriosam
              fugiat.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
