import React from 'react';

import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';

import { useSelector } from 'react-redux';
import { useToast } from '../../../components/ui/use-toast';
import { type UserType } from '../../../types/user';
import { RootState } from '../../../redux/store';
import api from '../../../lib/api';

import { useDispatch } from 'react-redux';

import { updateOrg } from '../../../redux/store/auth';

import {
  Avatar,
  AvatarImage,
  AvatarFallback,
} from '../../../components/ui/avatar';

export default function ManageOrganization() {
  const [inputs, setInputs] = React.useState({
    brand: '',
    name: '',
  });

  const dispatch = useDispatch();

  const { toast } = useToast();

  const [avatar, setAvatar] = React.useState<any>(null);

  const user = useSelector((root: RootState) => root.auth.user);

  const updateOrgName = () => {
    api
      .post('/admin/updateOrgName', { name: inputs.name })
      .then((data) => {
        dispatch(updateOrg(data ));
        toast({ title: 'Successfully updated!' });
      })
      .catch((error) => {
        toast({ title: 'Update is Failed' });
      });
  };

  const uploadOrgBrand = async () => {
    if (!avatar) {
      return toast({ title: 'Please select the file!' });
    }

    const avatarFile = await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(avatar);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    api
      .post('/admin/uploadOrgBrand', {data: avatarFile})
      .then((data) => {
        dispatch(updateOrg(data as unknown as UserType));
        toast({ title: 'Successfully updated!' });
      })
      .catch(() => {
        toast({ title: 'Uploading is Fiailed!' });
      });
  };

  React.useEffect(() => {
    if (user) {
      setInputs({
        brand: user.organization.brand!,
        name: user.organization.name,
      });
    }
  }, [user]);

  return (
    <div className='max-w-[800px] mx-auto flex justify-center items-center my-4 flex-col md:flex-row'>
      <div className='md:w-1/2 w-full'>
        <p className='text-blue-600 font-bold text-xl'>Edit Brand</p>
        <Avatar className='w-52 h-52 mx-auto'>
          <AvatarImage
            className='AvatarImage'
            src={user.organization.brand!}
            alt='Pedro Duarte'
          />
          <AvatarFallback
            className='bg-blue-900 text-8xl text-white'
            delayMs={600}
          >
            {user.organization.name.toLocaleUpperCase()[0] +
              user.organization.name.toLocaleUpperCase()[1]}
          </AvatarFallback>
        </Avatar>
        <div className='flex mt-3'>
          <Input
            type='file'
            accept='image/png'
            onChange={(event) => {
              setAvatar(event.target.files![0]);
            }}
          />
          <div className='flex justify-center ml-2'>
            <button
              onClick={uploadOrgBrand}
              className='disabled:cursor-not-allowed cursor-pointer text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className='md:w-1/2 w-full mt-5'>
        <p className='text-blue-600 font-bold text-xl'>
          Edit Organization Name
        </p>
        <div className='mt-1 grid w-full items-center gap-4'>
          <div className='flex flex-col gap-3 space-y-1.5 mt-3'>
            <Label htmlFor='name'>Name</Label>
            <Input
              required
              value={inputs.name}
              onChange={(e) =>
                setInputs((prev) => ({ ...prev, name: e.target.value }))
              }
              id='name'
              placeholder='Please enter the new organization Name.'
            />
          </div>
        </div>

        <div
          style={{ display: 'flex', marginTop: 20, justifyContent: 'flex-end' }}
        >
          <button
            onClick={updateOrgName}
            className='disabled:cursor-not-allowed cursor-pointer text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
          >
            Update Name
          </button>
        </div>
      </div>
    </div>
  );
}
