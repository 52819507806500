import React, { useEffect, useState } from 'react';
import api from '../../../lib/api';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Plus, ImportIcon, Download } from 'lucide-react';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { useToast } from '../../../components/ui/use-toast';

export default function MannageNomination() {
  const [users, setUsers] = useState<any[]>([]);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [orgid, setOrgid] = useState<string>('');
  const [userid, setUserid] = useState<string>('');
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch organization ID and user ID
        const orgRes = await api.get(`/auth/getUser`);
        setOrgid(orgRes.organization._id);
        setUserid(orgRes._id);
  
        // Fetch guest passes with organization ID
        const res = await api.get(`/admin/getnomination`);
        setUsers(res);
        setFilteredUsers(res);
      } catch (error) {
        console.error(error);
        // Handle error if needed
      }
    };
  
    const fetchOrgId = async () => {
      try {
        const res = await api.get(`/auth/getUser`);
        setOrgid(res.organization._id);
        setUserid(res._id);
      } catch (error) {
        console.error(error);
        // Handle error if needed
      }
    };
  
    fetchData();
    fetchOrgId();
  }, []);
  
  const handleAdd = () => {
    api
      .post('/admin/nomination', {
        name: name,
        description: description,
        organizationId: orgid,
        userId: userid,
      })
      .then((response) => {
        setOpen(false);
        window.location.reload();
        console.log(response);
      })
      .catch((error) => {
        // Handle errors
      });
  };
  

  const handleSearch = (searchText: string) => {
    const filtered = users.filter((user) =>
      user.name && user.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredUsers(filtered);
  };
  
 
  return (
    <>
      <div>
      <h5> Add Nomination</h5>
      <button
            className='flex justify-center items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
            onClick={() => {
              setOpen(true);
            }}
          >
            <Plus className='w-5' />
            Create Nomination
          </button>

        <div className='flex'>
        <Input className='my-4'  placeholder='Search by username...'
        onChange={(e) => handleSearch(e.target.value)}/>
       
        

         
        </div>
    
        <div className='overflow-x-auto'>
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
              Our members
              <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
                Browse a list of Flowbite members designed to help you work and
                play, stay organized, get answers, keep in touch, grow your
                business, and more.
              </p>
            </caption>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='px-6 py-3'>
                  Name
                </th>
                <th scope='col' className='px-6 py-3'>
                  Description
                </th>
               
               
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user: any, index: number) => {
                return (
                  <tr
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                    key={index}
                  >
                    <th
                      scope='row'
                      className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                    >
                      {user.name}
                    </th>
                    <td className='px-6 py-4'>{user.description
}</td>
                   

                    <td className='px-6 py-4 text-left'>
                     
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Dialog open={open} onOpenChange={(val) => setOpen(val)}>
        <DialogContent className='sm:max-w-[495px]'>
          <DialogHeader>
            <DialogTitle>Create Pass</DialogTitle>
            <DialogDescription>Please input the user detail</DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
           
            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='username'>Username</Label>
              <Input
                value={name}
                onChange={(e)=>setName(e.target.value)
                }
                id='username'
                placeholder='johndoe'
              />
            </div>

            <div className='flex flex-col gap-3  space-y-1.5'>
              <Label htmlFor='email'> Description</Label>
              <Input
                value={description}
                onChange={(e)=>setDescription(e.target.value)
                }
                id='email'
                placeholder='Description'
              />
            </div>
           
          </div>
          <DialogFooter>
            <Button
              onClick={handleAdd}
              type='submit'
            >
              Add User
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
