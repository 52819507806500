import React, { useEffect } from 'react';

import api from '../../../lib/api';
import moment from 'moment';

export default function ManageFinance() {
  const [records, setRecords] = React.useState<any[]>([]);

  useEffect(() => {
    api.get('/admin/finance').then((data) => {
      setRecords(data);
    });
  }, []);

  return (
    <div>
      <div className='overflow-x-auto'>
        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
          <caption className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
            Our Contribution Event
            <p className='mt-1 text-sm font-normal text-gray-500 dark:text-gray-400'>
              Browse a list of contribution event.
            </p>
          </caption>
          <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th className='px-6 py-3'>Name</th>
              <th className='px-6 py-3'>Purpose</th>
              <th className='px-6 py-3'>Date</th>
              <th className='px-6 py-3'>Amount</th>
              <th className='px-6 py-3'>Maker</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record: any, index: number) => {
              return (
                <tr
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  key={index}
                >
                  <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                    {record.name}
                  </td>
                  <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                    {record.purpose}
                  </td>
                  <td className='px-6 py-4'>
                    {moment(record.time).format('YYYY-MM-DD')}
                  </td>
                  <td className='px-6 py-4'>{record.amounts}</td>
                  <td className='px-6 py-4'>{record.maker.username}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
