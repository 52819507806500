import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import {
  LayoutDashboardIcon,
  UserCircle,
  User,
  Cloud,
  ShieldQuestionIcon,
  KeyIcon,
  TextQuote,
  GraduationCap,
  AlarmCheck,
  Shield,
  DollarSign,
  CalendarCheck2,
  MessageCircleMoreIcon,
  BookImageIcon,
  PersonStanding,
  Hammer,
  HandMetal,
  BookMarkedIcon,
} from 'lucide-react';
import Menu from '../components/Menu';

const StatusBar = () => {
  const location = useLocation();

  const pageList = [
    {
      path: '/dashboard',
      icon: <LayoutDashboardIcon />,
      text: 'Dashboard',
    },
    {
      path: '/dashboard/users',
      icon: <UserCircle />,
      text: 'Manage Users',
    },
    {
      path: '/dashboard/edit_profile',
      icon: <User />,
      text: 'Edit Users',
    },
    {
      path: '/dashboard/manage_org',
      icon: <Cloud />,
      text: 'Manage Organization',
    },
    {
      path: '/dashboard/manage_queries',
      icon: <ShieldQuestionIcon />,
      text: 'Manage Queries',
    },
    {
      path: '/dashboard/manage_apikeys',
      icon: <KeyIcon />,
      text: 'Manage API Keys',
    },
    {
      path: '/dashboard/chat_support',
      icon: <TextQuote />,
      text: 'Chat with Support',
    },
    {
      path: '/dashboard/read_faqs',
      icon: <ShieldQuestionIcon />,
      text: 'Read Faqs',
    },
    {
      path: '/dashboard/expore_careers',
      icon: <GraduationCap />,
      text: 'Explore Careers',
    },
    {
      path: '/dashboard/view_events',
      icon: <AlarmCheck />,
      text: 'View Events',
    },
    {
      path: '/dashboard/manage_security',
      icon: <KeyIcon />,
      text: 'Manage Security',
    },
    {
      path: '/dashboard/manage_system',
      icon: <Shield />,
      text: 'Manage System',
    },
    {
      path: '/dashboard/manage_price_plans',
      icon: <DollarSign />,
      text: 'Manage Price Plans',
    },
    {
      path: '/dashboard/manage_event',
      icon: <CalendarCheck2 />,
      text: 'Manage Events',
    },
    {
      path: '/dashboard/message',
      icon: <MessageCircleMoreIcon />,
      text: 'Chat with Members',
    },
    {
      path: '/dashboard/manage_gallery',
      icon: <BookImageIcon />,
      text: 'Manage Photo Gallery',
    },
    {
      path: '/dashboard/view_gallery',
      icon: <BookImageIcon />,
      text: 'View Photo Gallery',
    },
    {
      path: '/dashboard/manage_religion_groups',
      icon: <PersonStanding />,
      text: 'Manage Groups',
    },
    {
      path: '/dashboard/manage_volunteer_activities',
      icon: <Hammer />,
      text: 'Manage Volunteer Activities',
    },
    {
      path: '/dashboard/manage_prayer',
      icon: <HandMetal />,
      text: 'Manage Prayer Request',
    },
    {
      path: '/dashboard/manage_bookings',
      icon: <BookMarkedIcon />,
      text: 'Manage Bookings',
    },
  ];

  return (
    <>
      {pageList
        .filter((item) => item.path === location.pathname)
        .map((item, index) => (
          <div
            className='inline-block p-3 text-[#8053FF] bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
            key={index}
          >
            <div className='flex items-center justify-start'>
              <span className='mr-3'>{item.icon}</span>
              <span className='text-lg font-bold'>{item.text}</span>
            </div>
          </div>
        ))}
    </>
  );
};

export default function MainLayout({ children }: { children: ReactNode }) {
  return (
    <main className='w-full'>
      <Menu />
      <div className='px-4 max-w-[1550px] mx-auto mt-4'>
        <StatusBar />
        <div className='bg-gray-100 shadow-2xl py-4 px-4 rounded-b-md rounded-r-md mb-20'>
          {children}
        </div>
      </div>
      <div className='bottom-0 w-full text-center py-4'>
        © copyright APZ-Memership
      </div>
    </main>
  );
}
